import styled from '@emotion/styled';
import { Grid as MUIGrid, Paper, SxProps, Typography } from '@mui/material';
import { DataGridPremiumProps as DataGridProps, GridColDef, GridToolbarQuickFilter } from '@mui/x-data-grid-premium';
import React from 'react';

//#region default grid and column props
export const minWidthColumnProps: Partial<GridColDef> = {
    flex: 1,
    minWidth: 200,
};

export const limitedColumnProps: Partial<GridColDef> = {
    disableColumnMenu: true,
    disableExport: true,
    groupable: false,
    hideable: false,
};

export const defaultGridProps: Partial<DataGridProps> = {
    className: 'progress-table',
    autoHeight: false,
    disableColumnFilter: true,
    disableColumnPinning: true,
    disableColumnReorder: true,
    disableColumnSelector: true,
    disableDensitySelector: true,
    disableAggregation: true,
    hideFooter: true,
    columnHeaderHeight: 42,
    rowHeight: 32,
    slotProps: {
        loadingOverlay: {
            variant: 'linear-progress',
            noRowsVariant: 'linear-progress',
        },
    },
};
//#endregion

export const CustomGridToolbar = () => (
    <div className="customToolbar">
        <GridToolbarQuickFilter
            autoComplete="off"
            variant="outlined"
            size="small"
            style={{ padding: '10px 0' }}
        />
    </div>
);

//#region Mobile grid components
export const MobileGrid: React.FC<{ gridKey: string | number; spacing?: number; sx?: SxProps }> = (props) => (
    <Paper
        key={props.gridKey}
        className="mobile-row"
    >
        <MUIGrid
            container
            spacing={props.spacing ?? 2}
            sx={props.sx}
        >
            {props.children}
        </MUIGrid>
    </Paper>
);

export const MobileItemRowTypography = styled(Typography)({ overflow: 'hidden', textOverflow: 'ellipsis' });

export const MobileItemRow: React.FC<{ title: string; isObject?: boolean }> = (props) => {
    let children = props.children;

    if (!props.isObject) {
        children = <MobileItemRowTypography>{props.children}</MobileItemRowTypography>;
    }

    return (
        <>
            <MUIGrid
                item
                xs={6}
            >
                <Typography>{props.title}</Typography>
            </MUIGrid>
            <MUIGrid
                item
                xs={6}
            >
                {children}
            </MUIGrid>
        </>
    );
};
//#endregion
