import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import axios from 'axios';
import React, { MutableRefObject, useRef, useState } from 'react';
import { VersionInfo } from '../definitions/autogenerated/types';
import { useTypedTranslation } from '../definitions/languages';

export const VersionChecker: React.FC = () => {
    const { t } = useTypedTranslation();

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };
    const reloadPage = () => {
        window.location.reload();
    };
    const version: MutableRefObject<string | null> = useRef(null);

    React.useEffect(() => {
        const timer: NodeJS.Timeout = setInterval(() => {
            axios.get<VersionInfo>('./api/version/check').then((response) => {
                //When the first check is made, there is no previously saved version
                if (version.current === null) {
                    version.current = response.data.CurrentHash;
                    return;
                }
                //When a change is detected, the warning Dialog is opened
                if (version.current !== response.data.CurrentHash) {
                    setOpen(true);
                }
            });
        }, 30000);

        return () => {
            if (timer) {
                clearInterval(timer);
            }
        };
    }, []);

    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{t('versionChecker', 'titleText')}</DialogTitle>
            <DialogContent
                id="alert-dialog-content"
                dividers
            >
                <Alert severity="warning">{t('versionChecker', 'warningText')}</Alert>
                <Typography sx={{ marginTop: '10px' }}>{t('versionChecker', 'updateQuestionText')}</Typography>
            </DialogContent>
            <DialogActions className="dialogActions">
                <Button
                    onClick={handleClose}
                    variant="outlined"
                    style={{ borderColor: 'rgba(0, 138, 203, 0.5)', color: 'rgb(0, 138, 203)', backgroundColor: 'white' }}
                >
                    {t('versionChecker', 'denyText')}
                </Button>
                <Button
                    onClick={reloadPage}
                    variant="contained"
                    style={{ color: 'white', backgroundColor: 'rgb(0, 138, 203)' }}
                >
                    {t('dialogs', 'acceptText')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
