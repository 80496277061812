import { CircularProgress } from '@mui/material';

const ScreenLoading = () => {
    return (
        <div
            style={{
                background: '#00000025',
                position: 'absolute',
                inset: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <CircularProgress size={75} />
        </div>
    );
};

export default ScreenLoading;
