import { Action, combineReducers, configureStore, isPlain } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import thunk, { ThunkDispatch as ReduxThunkDispatch } from 'redux-thunk';
import { appplicationInsightsAPI } from './data/applicationInsights/actions';
import { configSheetSlice, remoteFilesAPI } from './data/configSheet/configSheetSlice';
import { deploymentSlice } from './data/deployments/deploymentSlice';
import { progressAppsSlice } from './data/progressApps/progressAppsSlice';
import { releaseSlice } from './data/release/releasesSlice';
import { githubSlice } from './data/github/githubSlice';
import { deploymentUISlice } from './ui/deploymentsUISlice';
import { pipelinesSlice } from './data/pipelines/pipelinesSlice';
import { customersSlice } from './data/customers/customersSlice';

export const rootReducer = combineReducers({
    data: combineReducers({
        deployments: deploymentSlice.reducer,
        progressApps: progressAppsSlice.reducer,
        configSheet: configSheetSlice.reducer,
        releases: releaseSlice.reducer,
        github: githubSlice.reducer,
        pipelines: pipelinesSlice.reducer,
        customers: customersSlice.reducer,
    }),
    ui: combineReducers({
        deployments: deploymentUISlice.reducer,
    }),
    [appplicationInsightsAPI.reducerPath]: appplicationInsightsAPI.reducer,
    [remoteFilesAPI.reducerPath]: remoteFilesAPI.reducer,
});

const isSerializable = (value: unknown) =>
    value instanceof Date || value instanceof FileSystemFileHandle ? true : isPlain(value);

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: { isSerializable } }).concat(
            appplicationInsightsAPI.middleware,
            remoteFilesAPI.middleware,
            thunk
        ),
});

export type RootState = ReturnType<typeof store.getState>;

export type ReduxDispatch = ReduxThunkDispatch<RootState, unknown, Action>;

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export function useReduxDispatch(): ReduxDispatch {
    return useDispatch<ReduxDispatch>();
}
