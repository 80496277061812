import { styled } from '@mui/material';

export const TopBarOffset = styled('div')(({ theme }) => theme.mixins.toolbar);

export const RootDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    background: theme.palette.grey[50],
    height: '100%',
    padding: '0px',
}));
