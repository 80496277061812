import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import FlipToBackIcon from '@mui/icons-material/FlipToBack';
import FlipToFrontIcon from '@mui/icons-material/FlipToFront';
import { ClickAwayListener } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { fabric } from 'fabric';
import React, { MutableRefObject, useEffect, useCallback, useState } from 'react';
import { useFabricContext } from '../FabricContext';
import { copy, paste } from '../fabricUtils';
import { ProgressCanvas } from '../ProgressCanvas';
import { Logger } from '@progress/base-ui';

export const FabricCanvas = () => {
    const { canvas, initCanvas } = useFabricContext();

    const [contextMenu, setContextMenu] = useState<{ mouseX: number; mouseY: number } | null>(null);

    const handleContextMenuClose = useCallback(() => {
        setContextMenu(null);
    }, []);

    const deleteSelectedObject = useCallback(() => {
        if (!canvas) return;

        const objects: fabric.Object[] = [];
        if (canvas.getActiveObjects().length) {
            if (canvas.getActiveObjects().length > 0) {
                objects.push(...canvas.getActiveObjects());
                if (objects.length > 0) {
                    objects.forEach(function (o: fabric.Object) {
                        canvas.remove(o);
                        canvas.discardActiveObject();
                    });
                } else {
                    const aktivObject = canvas.getActiveObject();
                    if (aktivObject) {
                        canvas.remove(aktivObject);
                    }
                }
            }
        } else {
            Logger.debug('nothing to delete');
        }

        handleContextMenuClose();
    }, [canvas, handleContextMenuClose]);

    const sendSelectedObjectBack = useCallback(() => {
        if (!canvas) return;

        const objects: fabric.Object[] = [];
        if (canvas.getActiveObjects().length)
            for (let i = 0; i < canvas.getActiveObjects().length; i++) {
                objects.push(canvas.getActiveObjects()[i]);
            }
        for (let i = 0; i < objects.length; i++) {
            canvas.sendBackwards(objects[i]);
        }
        handleContextMenuClose();
    }, [canvas, handleContextMenuClose]);

    const bringSelectedObjectFront = useCallback(() => {
        if (!canvas) return;

        const objects: fabric.Object[] = [];
        if (canvas.getActiveObjects().length)
            for (let i = 0; i < canvas.getActiveObjects().length; i++) {
                objects.push(canvas.getActiveObjects()[i]);
            }
        for (let i = 0; i < objects.length; i++) {
            canvas.bringForward(objects[i]);
        }
        handleContextMenuClose();
    }, [canvas, handleContextMenuClose]);

    const clone = useCallback(() => {
        if (!canvas) return;

        copy(canvas);
        paste(canvas);
        handleContextMenuClose();
    }, [canvas, handleContextMenuClose]);

    const moveSelectedObject = useCallback((canvas: ProgressCanvas, direction: string) => {
        if (!canvas) return;

        if (canvas.getActiveObjects().length > 0) {
            canvas.getActiveObjects().forEach((o) => {
                if (direction === 'left') {
                    if (o.left !== undefined) o.left -= 1;
                } else if (direction === 'right') {
                    if (o.left !== undefined) o.left += 1;
                } else if (direction === 'up') {
                    if (o.top !== undefined) o.top -= 1;
                } else if (direction === 'down') {
                    if (o.top !== undefined) o.top += 1;
                }
            });
            canvas.requestRenderAll();
        }
    }, []);

    const handleKeyDown = useCallback(
        (e: React.KeyboardEvent) => {
            if (!canvas) return;

            if ((e.ctrlKey || e.altKey) && e.key === 'c') {
                if (e.repeat) {
                    return;
                }
                e.stopPropagation();
                copy(canvas);
            } else if ((e.ctrlKey || e.altKey) && e.key === 'v') {
                if (e.repeat) {
                    return;
                }
                e.stopPropagation();
                paste(canvas);
            } else if (e.key === 'Delete') {
                deleteSelectedObject();
            } else if ((e.ctrlKey || e.altKey) && e.key === 'a') {
                e.stopPropagation();
                e.preventDefault();
                canvas.discardActiveObject();
                const sel = new fabric.ActiveSelection(canvas.getObjects(), { canvas: canvas });
                canvas.setActiveObject(sel);
                canvas.requestRenderAll();
            } else if (e.key === 'ArrowLeft') {
                e.preventDefault();
                moveSelectedObject(canvas, 'left');
            } else if (e.key === 'ArrowRight') {
                e.preventDefault();
                moveSelectedObject(canvas, 'right');
            } else if (e.key === 'ArrowUp') {
                e.preventDefault();
                moveSelectedObject(canvas, 'up');
            } else if (e.key === 'ArrowDown') {
                e.preventDefault();
                moveSelectedObject(canvas, 'down');
            } else if (e.key === 'y' && (e.ctrlKey || e.altKey)) {
                e.preventDefault();
                canvas.redo();
            } else if (e.key === 'z' && (e.ctrlKey || e.altKey)) {
                e.preventDefault();
                canvas.undo();
            } else if (e.key === '+' && (e.ctrlKey || e.altKey)) {
                e.preventDefault();
                canvas.zoomIn();
            } else if (e.key === '-' && (e.ctrlKey || e.altKey)) {
                e.preventDefault();
                canvas.zoomOut();
            } else if (e.key === 'f' && (e.ctrlKey || e.altKey)) {
                e.preventDefault();
                canvas.getActiveObject()?.bringToFront();
                canvas.renderAll();
            } else if (e.key === 'f') {
                e.preventDefault();
                canvas.getActiveObject()?.bringForward(true);
                canvas.renderAll();
            } else if (e.key === 'b' && (e.ctrlKey || e.altKey)) {
                e.preventDefault();
                canvas.getActiveObject()?.sendToBack();
                canvas.renderAll();
            } else if (e.key === 'b') {
                e.preventDefault();
                canvas.getActiveObject()?.sendBackwards(true);
                canvas.renderAll();
            }
        },
        [canvas, deleteSelectedObject, moveSelectedObject]
    );

    const wrapperRef: MutableRefObject<HTMLDivElement> = React.useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
    const canvasRef: MutableRefObject<HTMLCanvasElement> =
        React.useRef<HTMLCanvasElement>() as MutableRefObject<HTMLCanvasElement>;

    useEffect(() => {
        const htmlEl = wrapperRef.current;

        const handleScroll = (e: WheelEvent) => {
            if (e.ctrlKey) {
                e.preventDefault();
                e.stopPropagation();
                if (e.deltaY < 0) {
                    canvas?.zoomIn();
                } else {
                    canvas?.zoomOut();
                }
            }
        };

        htmlEl?.addEventListener('wheel', handleScroll, { passive: false });

        return () => {
            htmlEl?.removeEventListener('wheel', handleScroll);
        };
    }, [wrapperRef, canvas]);

    useEffect(() => {
        const canvas = initCanvas(canvasRef.current);

        return () => {
            canvas.dispose();
        };
    }, [canvasRef, initCanvas]);

    useEffect(() => {
        const handler = (e: fabric.IEvent<Event>) => {
            if (e.button === 1) {
                setContextMenu(null);
            }

            // right click
            if (e.button === 3) {
                e.e.preventDefault();
                e.e.stopPropagation();

                if (e.target) {
                    if (e.target instanceof fabric.ActiveSelection) {
                        Logger.debug('[d] group is selected');
                    } else if (!canvas?.getActiveObject() || canvas?.getActiveObject() !== e.target) {
                        canvas?.setActiveObject(e.target).renderAll();
                    }

                    setContextMenu({ mouseX: (e.e as MouseEvent).clientX + 5, mouseY: (e.e as MouseEvent).clientY + 5 });
                } else {
                    setContextMenu(null);
                }
            }
        };

        canvas?.on('mouse:down', handler);

        return () => {
            canvas?.off('mouse:down', handler);
        };
    }, [canvas]);

    return (
        <div
            ref={wrapperRef}
            id="canvasWrap"
            style={{ flexDirection: 'column', display: 'flex', height: '100%', outline: 'none' }}
            tabIndex={1}
            onKeyDown={handleKeyDown}
        >
            <canvas
                id="canvas"
                ref={canvasRef}
            />

            <ClickAwayListener onClickAway={handleContextMenuClose}>
                <Menu
                    open={contextMenu !== null}
                    onClose={handleContextMenuClose}
                    hideBackdrop
                    disableScrollLock
                    onContextMenu={(e) => {
                        e.preventDefault();
                    }}
                    componentsProps={{
                        root: {
                            style: {
                                // disable backdrop
                                position: 'unset',
                            },
                        },
                    }}
                    anchorReference="anchorPosition"
                    anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
                >
                    <MenuItem onClick={clone}>
                        <ListItemIcon>
                            <ContentCopyIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>clone</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={deleteSelectedObject}>
                        <ListItemIcon>
                            <DeleteIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>delete</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={bringSelectedObjectFront}>
                        <ListItemIcon>
                            <FlipToFrontIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>send front</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={sendSelectedObjectBack}>
                        <ListItemIcon>
                            <FlipToBackIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>send back</ListItemText>
                    </MenuItem>
                </Menu>
            </ClickAwayListener>
        </div>
    );
};
