import { DependencyList, useEffect, EffectCallback, useRef } from 'react';

/**
 * Hook to be fired on update of a dependency but not on first render.
 *
 * @param {EffectCallback} callback - The callback function to be executed on update.
 * @param {DependencyList} dependencies - The list of dependencies to track for updates.
 * @return {void}
 */
export function useUpdateEffect(callback: EffectCallback, dependencies: DependencyList) {
    /** hook to be fired on update of a dependency but not on first render */
    const firstRenderRef = useRef(true);

    useEffect(() => {
        if (firstRenderRef.current) {
            firstRenderRef.current = false;
            return;
        }
        return callback();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);
}
