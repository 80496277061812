import { BaseComponent } from './baseComponent';
import { fabric } from 'fabric';
import * as d3 from 'd3';
import { EditableProperty } from './objects';

export class Rectangle extends BaseComponent {
    controlType = 'Rectangle';
    isDirty = false;

    constructor(options: fabric.IRectOptions) {
        super({
            //default
            ...{
                fill: '#ff8C00',
                stroke: '#000000',
                strokeWidth: 0.75,
                objectCaching: false,
                cornerColor: 'black',
                borderColor: 'black',
            },
            ...options,
        });
    }

    update<K extends keyof this>(propertyName: K, value: this[K] | ((value: this[K]) => this[K])): fabric.Object {
        this.set(propertyName, value);
        this.set('dirty', true);
        this.isDirty = true;
        this.canvas?.fire('object:modified');
        return this;
    }

    getProperties(): EditableProperty[] {
        return [
            ...super.getProperties(),
            { propertyName: 'strokeWidth', displayName: 'Stroke width', type: 'number' },
            { propertyName: 'stroke', displayName: 'Stroke color', type: 'color' },
            { propertyName: 'fill', displayName: 'Fill color', type: 'color' },
        ];
    }

    serialize() {
        return {
            ...super.serialize(),
            fill: this.fill,
            strokeWidth: this.strokeWidth,
            stroke: this.stroke,
        };
    }

    renderToSVG(svgElement: SVGSVGElement) {
        d3.select(svgElement)
            .append('rect')
            .attr('x', this.left ?? 0)
            .attr('y', this.top ?? 0)
            .attr('width', this.width ?? 0)
            .attr('height', this.height ?? 0)
            .style('fill', (this.fill ?? '') as string)
            .style('stroke', this.stroke ?? '#000')
            .style('stroke-width', this.strokeWidth ?? 1);

        return svgElement;
    }
}
