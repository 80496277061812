import { Num } from './num';
import { Vector3 } from './vector3';

export interface Matrix3 {
    m11: number;
    m12: number;
    m13: number;
    m21: number;
    m22: number;
    m23: number;
    m31: number;
    m32: number;
    m33: number;
}

/**
 * Matrix3 functions.
 * Taken from R5dLib.Calc, if any functions you need are missing, check the R5dLib.Calc code for reference.
 */
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Matrix3 {
    export const fromValues = (
        m11: number,
        m12: number,
        m13: number,
        m21: number,
        m22: number,
        m23: number,
        m31: number,
        m32: number,
        m33: number
    ): Readonly<Matrix3> => {
        return Object.freeze({
            m11,
            m12,
            m13,
            m21,
            m22,
            m23,
            m31,
            m32,
            m33,
        });
    };

    export const identity = Matrix3.fromValues(1, 0, 0, 0, 1, 0, 0, 0, 1);

    export const scale = (a: Matrix3, c: number) => {
        return Matrix3.fromValues(
            c * a.m11,
            c * a.m12,
            c * a.m13,
            c * a.m21,
            c * a.m22,
            c * a.m23,
            c * a.m31,
            c * a.m32,
            c * a.m33
        );
    };

    /**
     * Returns the rotation matrix for a coordinate system rotation around the given axis.
     * (For object rotations the negated angle should be used)
     */
    export const fromAxisAngle = (axis: Vector3, angleRAD: number) => {
        const cos = Math.cos(angleRAD);
        const mcos = 1 - cos;
        const len = Vector3.length(axis);

        if (Num.alike(len, 0)) {
            return Matrix3.scale(Matrix3.identity, Math.sign(cos));
        }

        const sin = Math.sin(angleRAD);
        const normalizedAxis = Vector3.scale(axis, 1.0 / len);

        const ax = normalizedAxis.x;
        const ay = normalizedAxis.y;
        const az = normalizedAxis.z;

        const max = mcos * ax;
        const may = mcos * ay;
        const maz = mcos * az;

        const maxx = max * ax;
        const maxy = max * ay;
        const maxz = max * az;
        const mayy = may * ay;
        const mayz = may * az;
        const mazz = maz * az;

        const sax = sin * ax;
        const say = sin * ay;
        const saz = sin * az;

        return Matrix3.fromValues(
            maxx + cos,
            maxy + saz,
            maxz - say,
            maxy - saz,
            mayy + cos,
            mayz + sax,
            maxz + say,
            mayz - sax,
            mazz + cos
        );
    };

    /**
     * Die Rotationsmatrix wird in 3 Rotationen um die absoluten Achsen X, Y und Z zerlegt:
     * R = Rz * Ry * Rx.
     * D.h. an Stelle der Drehung R wird zunächst um die X-Achse gedreht,
     * dann um die nach wie vor unveränderte Achse Y und dann um die nach wie vor unveränderte Achse Z.
     * Zurückgeliefert werden die 3 Drehwinkel um die genannten Achsen.
     * Diese "extrinsic" Rotation um die absoluten Achsen ist äquivalent zur invertierten "intrinsic"
     * Rotation um die weitergedrehten Achsen (also Drehung um Z, dann Drehung um die neue Y-Achse, dann Drehung um die neue X-Achse).
     * Es wird die Objektdrehung betrachtet ("active rotation").
     * Wenn man eine invertierte Reihenfolge hat, also R = Rx * Ry * Rz, dann muss man für 'rotationMatrix' nicht R übergeben,
     * sondern die Transponierte von R; zudem sind dann die gelieferten Winkel invertiert zu interpretieren.
     * </summary>
     * <param name="rotationMatrix"></param>
     * <param name="angleRAD_X">Drehwinkel um X aus dem Bereich ]-Pi, +Pi]</param>
     * <param name="angleRAD_Y">Drehwinkel um Y aus dem Bereich [-Pi/2, +Pi/2]</param>
     * <param name="angleRAD_Z">Drehwinkel um Z aus dem Bereich ]-Pi, +Pi]</param>
     * <remarks>
     * Wenn der Drehwinkel um Y (näherungsweise) +-90° beträgt, hat man ein "Gimbal Lock". Die Rotation um X und Z drehen dann
     * um die selbe Achse des Objektes und es ist dann nur die Summe oder Differenz von angleRAD_Z und angleRAD_X bestimmt, nicht aber die Einzelwinkel.
     * Die Funktion liefert dann ein zufälliges, aber korrektes Ergebnis.<br></br>
     * (Bei einem Y-Drehwinkel von -90 ist nur die Summe angleRAD_X + angleRAD_Z bestimmt.
     * Bei einemn Y-Drehwinkel von +90 ist nur die Different angleRAD_X - angleRAD_Z bestimmt.)<br></br>
     * Wenn also die Aufteilung zwischen angleRAD_X und angleRAD_Z relevant ist, muss man explizit auf angleRAD_Y == +-PI/2 abfragen
     * und entsprechende Anpassungen von angleRAD_X und angleRAD_Z vornehmen.
     */
    export const getTaitBryanAngles = (rotationMatrix: Matrix3) => {
        // Wir bestimmen zunächst angleRAD_Z aus m21 und m11.
        // Bei cos(angleRAD_Y) näherungsweise gleich 0 sind aber m21 und m11 näherungsweise 0 und das Ergebnist ist unbestimmt.
        // Wir müssen dann mit diesem (zufälligen aber korrekten) angleRAD_Z Wert weiterrechnen.
        const angleRAD_Z = Math.atan2(rotationMatrix.m21, rotationMatrix.m11);

        // Theoretisch könnte man angleRAD_Y über -Num.Asin(rotationMatrix.m31) berechnen.
        // Wenn aber m31 näherungsweise ±1 ist, ist die Asin-Berechnung schlecht konditioniert (==> große Rundungsfehler).
        // Wir müssen daher angleRAD_Y über den sin und den cos ermitteln.
        // Letzterer ist aber nicht direkt verfügbar, sondern nur indirekt über m11 = cos(angleRAD_Y) * cos(angleRAD_Z) und m21 = cos(angleRAD_Y) * sin(angleRAD_Z).
        // Über die Beziehun m11² + m21² = cos²(angleRAD_Y) * (cos²(angleRAD_Z) + sin²(angleRAD_Z)) = cos²(angleRAD_Y) knn man zunächst cos²(angleRAD_Y) ermitteln.
        // Dann muss man über angleRAD_Z das vorzeichen von cos(angleRAD_Y) ermittel.
        const cos2_angleRAD_Y = rotationMatrix.m11 * rotationMatrix.m11 + rotationMatrix.m21 * rotationMatrix.m21;
        let sign_cos_angleRAD_Y: number;
        const angleRAD_Z_Abs = Math.abs(angleRAD_Z);
        if (angleRAD_Z_Abs > 0.75 * Math.PI) {
            sign_cos_angleRAD_Y = -Math.sign(rotationMatrix.m11); // cos(angleRAD_Z) is non-null and negative; m11 is 0 if and only if cos(angleRAD_Y) is 0.
        } else if (angleRAD_Z_Abs > 0.25 * Math.PI) {
            sign_cos_angleRAD_Y = Math.sign(angleRAD_Z) * Math.sign(rotationMatrix.m21); // sin(angleRAD_Z) is non-null; m21 is 0 if and only if cos(angleRAD_Y) is 0.
        } else {
            sign_cos_angleRAD_Y = Math.sign(rotationMatrix.m11); // cos(angleRAD_Z) is non-null and positive; m11 is 0 if and only if cos(angleRAD_Y) is 0.
        }

        const cos_angleRAD_Y = sign_cos_angleRAD_Y * Math.sqrt(cos2_angleRAD_Y);
        const sin_angleRAD_Y = -rotationMatrix.m31;
        const angleRAD_Y = Math.atan2(sin_angleRAD_Y, cos_angleRAD_Y);

        // Theoretisch wäre nun angleRAD_X = Math.Atan2(rotationMatrix.m32, rotationMatrix.m33)
        // Doch im Falle des Gimbal Lock sind m21, m11, m32, m33 näherungsweise null.
        // Es ist dann schon angleRAD_Z durch Rundungszufall bestimmt; angleRAD_X sollte dann
        // unter Berücksichtigung des zufälligen angleRAD_Z-Wertes ermittelt werden.
        const Rz_Inverted = Matrix3.D_RotZ(angleRAD_Z);
        const Ry_Inverted = Matrix3.D_BendY(-angleRAD_Y);
        const Rx = Matrix3.multiply(Ry_Inverted, Rz_Inverted, rotationMatrix);
        const angleRAD_X = Math.atan2(Rx.m32, Rx.m33);

        return { angleRAD_X, angleRAD_Y, angleRAD_Z };
    };

    /**
     * Returns the rotation matrix for a coordinate system rotation around the Z axis.
     * (For object rotations the negated angle should be used)
     */
    export const D_RotZ = (angleRad: number) => {
        const cos = Math.cos(angleRad);
        const sin = Math.sin(angleRad);
        return Matrix3.fromValues(cos, sin, 0, -sin, cos, 0, 0, 0, 1);
    };

    /**
     * Returns the rotation matrix for a coordinate system rotation around the NEGATIVE Y axis.
     * (For object rotations the negated angle should be used)
     */
    export const D_BendY = (angleRad: number) => {
        const cos = Math.cos(angleRad);
        const sin = Math.sin(angleRad);
        return Matrix3.fromValues(cos, 0, sin, 0, 1, 0, -sin, 0, cos);
    };

    const _multiplyMatrixInternal = (a: Matrix3, b: Matrix3) => {
        return Matrix3.fromValues(
            a.m11 * b.m11 + a.m12 * b.m21 + a.m13 * b.m31,
            a.m11 * b.m12 + a.m12 * b.m22 + a.m13 * b.m32,
            a.m11 * b.m13 + a.m12 * b.m23 + a.m13 * b.m33,
            a.m21 * b.m11 + a.m22 * b.m21 + a.m23 * b.m31,
            a.m21 * b.m12 + a.m22 * b.m22 + a.m23 * b.m32,
            a.m21 * b.m13 + a.m22 * b.m23 + a.m23 * b.m33,
            a.m31 * b.m11 + a.m32 * b.m21 + a.m33 * b.m31,
            a.m31 * b.m12 + a.m32 * b.m22 + a.m33 * b.m32,
            a.m31 * b.m13 + a.m32 * b.m23 + a.m33 * b.m33
        );
    };

    /** Multiply matrices in right to left order. */
    export const multiply = (...matrices: Matrix3[]): Matrix3 => {
        if (matrices.length === 0) return Matrix3.identity;

        let m = matrices[matrices.length - 1];
        for (let i = matrices.length - 2; i >= 0; i--) {
            m = _multiplyMatrixInternal(matrices[i], m);
        }

        return m;
    };

    export const transpose = (m: Matrix3) => {
        return Matrix3.fromValues(m.m11, m.m21, m.m31, m.m12, m.m22, m.m32, m.m13, m.m23, m.m33);
    };
}
