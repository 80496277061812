import { createTheme, PaletteColor, ThemeProvider } from '@mui/material';
import createPalette, { SimplePaletteColorOptions } from '@mui/material/styles/createPalette';
import React, { ReactNode } from 'react';
import {
    AssemblyColor,
    BendingColor,
    DefaultColor,
    ProducedColor,
    ProgressBlue,
    ProgressGrey,
    ProgressYellow,
    SawingColor,
    SentColor,
    ThreadingColor,
} from './internal/colors';

// Augment palette to accept custom colors
declare module '@mui/material/styles' {
    interface Palette {
        progressBlue: PaletteColor;
        progressGrey: PaletteColor;
        progressYellow: PaletteColor;
        sent: SimplePaletteColorOptions;
        produced: SimplePaletteColorOptions;
        assembly: SimplePaletteColorOptions;
        bending: SimplePaletteColorOptions;
        sawing: SimplePaletteColorOptions;
        threading: SimplePaletteColorOptions;
        default: SimplePaletteColorOptions;
    }

    interface PaletteOptions {
        progressBlue: PaletteColor;
        progressGrey: PaletteColor;
        progressYellow: PaletteColor;
        sent: SimplePaletteColorOptions;
        produced: SimplePaletteColorOptions;
        assembly: SimplePaletteColorOptions;
        bending: SimplePaletteColorOptions;
        sawing: SimplePaletteColorOptions;
        threading: SimplePaletteColorOptions;
        default: SimplePaletteColorOptions;
    }
}

// Update the Button's color options to include custom colors
declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        progressBlue: true;
        progressGrey: true;
        progressYellow: true;
        sent: true;
        produced: true;
        assembly: true;
        bending: true;
        sawing: true;
        threading: true;
        default: true;
    }
}
// Update the Button's color options to include custom colors
declare module '@mui/material/ButtonGroup' {
    interface ButtonGroupPropsColorOverrides {
        progressBlue: true;
        progressGrey: true;
        progressYellow: true;
        sent: true;
        produced: true;
        assembly: true;
        bending: true;
        sawing: true;
        threading: true;
        default: true;
    }
}
// Update the Button's color options to include custom colors
declare module '@mui/material/Fab' {
    interface FabPropsColorOverrides {
        progressBlue: true;
        progressGrey: true;
        progressYellow: true;
        sent: true;
        produced: true;
        assembly: true;
        bending: true;
        sawing: true;
        threading: true;
        default: true;
    }
}

export const ProgressPalette = createPalette({
    //For optimal backwards compatibility with legacy app themes, set primary to blue, secondary to yellow
    primary: ProgressBlue,
    secondary: ProgressYellow,
    progressBlue: ProgressBlue,
    progressYellow: ProgressYellow,
    progressGrey: ProgressGrey,
    sent: SentColor,
    assembly: AssemblyColor,
    produced: ProducedColor,
    bending: BendingColor,
    sawing: SawingColor,
    threading: ThreadingColor,
    default: DefaultColor,
});

export const ProgressTheme = createTheme({
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                a: {
                    color: ProgressBlue.main,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'initial',
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    textTransform: 'initial',
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    textTransform: 'initial',
                },
            },
        },
    },
    palette: ProgressPalette,
    mixins: {
        toolbar: {
            height: '50px',
            minHeight: '50px',
            maxHeight: '50px',
            background: ProgressGrey.main,
        },
    },
    typography: {
        fontFamily: 'Arial, sans-serif',
    },
});

export const ProgressThemeProvider: React.FC<{ children?: ReactNode | undefined }> = (props) => {
    return <ThemeProvider theme={ProgressTheme}>{props.children}</ThemeProvider>;
};
