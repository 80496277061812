import { ProgressError } from '@progress/base-ui';
import { AsyncThunk, AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit';
import { Log } from '../utils/debug';

/**
 * Executes a function and returns a promise that resolves to the result of the function.
 * This way a try-catch block can be replaced by a promise.catchError which contains the parsed ProgressError already
 *
 * @example
 * tryRun(() => { ... }).catchError(error => errorDialog(error))
 *
 * @param {() => T} fn - The function to be executed.
 * @return {Promise<T>} - A promise that resolves to the result of the function.
 */
export function tryRun<T = void>(fn: () => T) {
    return new Promise<T>((resolve, reject) => {
        try {
            resolve(fn());
        } catch (error) {
            reject(error);
        }
    });
}

//The thunkApiConfig must be generic with StateType to ensure the return type of the getState() function
export type ThunkAPIConfig<StateType = unknown> = {
    state: StateType;
    rejectValue: ProgressError;
};

export type ThunkCreatorConfig = {
    onError?: (error: ProgressError, type?: string) => void;
};

/**
 * Defines a "createAsyncThunk" function with typed error rejection
 * @param config Custom application-specific behaviour
 * @returns typed "createAsyncThunk" function
 *
 * @example
 * export const createTypedAsyncThunk = configureTypedThunkCreator<RootState>({
 *   onError: (error, type) => {
 *       Log.error(type, error);
 *   }
 * });
 */
export const configureTypedThunkCreator =
    <StateType = unknown>(config: ThunkCreatorConfig) =>
    <Returned, ThunkArg>(
        type: string,
        payloadCreator: AsyncThunkPayloadCreator<Promise<Returned>, ThunkArg, ThunkAPIConfig<StateType>>
    ): AsyncThunk<Returned, ThunkArg, ThunkAPIConfig<StateType>> =>
        createAsyncThunk<Returned, ThunkArg, ThunkAPIConfig<StateType>>(type, async (arg, thunkAPI) => {
            return tryRun(() => payloadCreator(arg, thunkAPI)).catchError(async (payloadCreatorError) => {
                await tryRun(() => {
                    config.onError && config.onError(payloadCreatorError, type);
                }).catchError((errorHandlerException) => {
                    //This approach ensures that both the payloadCreator function and the onError callback are executed within the context of the tryRun function
                    //and the catchError method, and any errors they throw are handled gracefully.
                    Log.error(
                        `Exception in onError-Handler for "${type}" when handling error:`,
                        payloadCreatorError,
                        ' - the handler threw: ',
                        errorHandlerException
                    );
                });
                return thunkAPI.rejectWithValue(payloadCreatorError);
            });
        });

/**
 * Standard variant of the "createAsyncThunk" function with typed error rejection
 */
export const createTypedAsyncThunk = configureTypedThunkCreator({});
