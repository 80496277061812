import CloseIcon from '@mui/icons-material/Close';
import { IconButton, StyledEngineProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterLuxon } from '@mui/x-date-pickers-pro/AdapterLuxon';
import { Store } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { Settings } from 'luxon';
import { SnackbarOrigin, SnackbarProvider } from 'notistack';
import React, { MutableRefObject, useRef } from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { useTypedTranslation } from '../definitions/languages';
import { UnitsProvider, UnitsProviderProps } from '../unitConverter/context';
import { ErrorBoundary } from './ErrorBoundary';

export type ProgressBaseProviderProps = {
    children?: React.ReactNode;
    store?: Store;
    unitsProviderProps?: UnitsProviderProps;
    snackbarAnchorOrigin?: SnackbarOrigin;
};

function InternalProgressBaseProvider(props: ProgressBaseProviderProps) {
    const { store, children, unitsProviderProps } = props;

    // we have to listen for language/locale changes in order to update the adapterLocale
    // eslint-disable-next-line no-empty-pattern
    const {} = useTypedTranslation();
    const luxonLocale = Settings.defaultLocale;
    const notistackRef: MutableRefObject<SnackbarProvider> = useRef<SnackbarProvider>() as MutableRefObject<SnackbarProvider>;

    return (
        <React.StrictMode>
            <ReduxProvider store={store}>
                <StyledEngineProvider injectFirst>
                    <SnackbarProvider
                        anchorOrigin={props.snackbarAnchorOrigin ?? { horizontal: 'right', vertical: 'bottom' }}
                        maxSnack={5}
                        ref={notistackRef}
                        style={{ whiteSpace: 'pre-line' }}
                        autoHideDuration={5000}
                        action={(key) => (
                            <IconButton
                                onClick={() => notistackRef.current.closeSnackbar(key)}
                                aria-label="delete"
                                size="large"
                            >
                                <CloseIcon style={{ color: '#fff' }} />
                            </IconButton>
                        )}
                    >
                        <UnitsProvider {...unitsProviderProps}>
                            <LocalizationProvider
                                dateAdapter={AdapterLuxon}
                                adapterLocale={luxonLocale}
                            >
                                <ErrorBoundary>{children}</ErrorBoundary>
                            </LocalizationProvider>
                        </UnitsProvider>
                    </SnackbarProvider>
                </StyledEngineProvider>
            </ReduxProvider>
        </React.StrictMode>
    );
}

function ReduxProvider(props: { store?: Store; children?: React.ReactNode }) {
    return (
        <React.Fragment>
            {props.store ? <Provider store={props.store}>{props.children}</Provider> : props.children}
        </React.Fragment>
    );
}

function ProgressBaseProviderLight(props: ProgressBaseProviderProps) {
    return (
        <I18nextProvider i18n={i18next}>
            <InternalProgressBaseProvider {...props}>{props.children}</InternalProgressBaseProvider>
        </I18nextProvider>
    );
}

export default ProgressBaseProviderLight;
