import { CheckOutlined, ClearOutlined } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import React from 'react';

export type InfoRowProps = {
    title: string;
    value: string | number | boolean | React.ReactElement;
};

const InfoRow: React.FC<InfoRowProps> = (props) => {
    let content = props.value;
    if (typeof content === 'boolean') {
        content = content ? <CheckOutlined /> : <ClearOutlined />;
    }

    return (
        <Grid
            container
            spacing={1}
        >
            <Grid
                item
                xs={12}
                sm={6}
            >
                <Typography variant="body1">
                    <strong style={{ wordBreak: 'break-all' }}>{props.title}</strong>
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                sm={6}
            >
                <Typography variant="body1">{content}</Typography>
            </Grid>
        </Grid>
    );
};

export default InfoRow;
