import { ApplicationType } from '@/definitions/autogenerated/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ExtApplicationType = keyof typeof ApplicationType;

export type ReleasesState = {
    releases: Partial<Record<ExtApplicationType, string[]>>;
};

const initialReleasesState: ReleasesState = {
    releases: {},
};

export const releaseSlice = createSlice({
    name: 'releases',
    initialState: initialReleasesState,
    reducers: {
        addNewElements(state, action: PayloadAction<Partial<Record<ExtApplicationType, string[]>>>) {
            state.releases = action.payload;
        },
    },
});

export const releaseActions = releaseSlice.actions;
