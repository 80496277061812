import React, {
    MouseEventHandler,
    TouchEventHandler,
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import { useOnMount } from '../hooks/useOnMount';
import { useOnUnmount } from '../hooks/useOnUnmount';
import { useResize } from '../hooks/useResize';
import { Log } from '../utils/debug';
import Pane from './Pane';
import { SplitterButton } from './SplitterButton';
import './splitter.scss';
import { SplitterPosition, SplitterProps, SplitterRef, clearSelection, getSecondaryPaneSize } from './splitterFn';

export const Splitter = React.forwardRef<SplitterRef, SplitterProps>(function Splitter(props: SplitterProps, ref) {
    const { onDragStart, onDragEnd } = props;

    const splitter = useRef<HTMLDivElement>(null);
    const container = useRef<HTMLDivElement>(null);

    const [secondaryPaneSize, setSecondaryPaneSize] = useState<number>(props.secondaryInitialSize || 0);
    const [secondaryPaneCollapsed, setSecondaryPaneCollapsed] = useState<boolean>(
        (props.secondaryCollapsible ?? false) && props.secondaryInitialSize === 0
    );

    const [primaryPaneRef, { width: primaryPaneWidth }] = useResize();
    const [secondaryPaneRef, { width: secondaryPaneWidth }] = useResize();

    const resizing = useRef<boolean>(false);

    useImperativeHandle(
        ref,
        () => ({
            setSecondarySize: (value) => {
                setSecondaryPaneSize(value);
            },
            setSecondaryPaneCollapsed: (value) => {
                setSecondaryPaneCollapsed(value);
            },
        }),
        []
    );

    /* destructure and set default props values */
    const {
        splitterSize = 10,
        vertical = false,
        percentage = false,
        primaryMinSize = 0,
        secondaryMinSize = 0,
        secondaryCollapsible = false,
        secondaryUnmountCollapsed = false,
        secondaryMaxSize = undefined,
    } = props;

    const primaryIndex = props.primaryIndex !== 0 && props.primaryIndex !== 1 ? 0 : props.primaryIndex; //primary index can only be 0 or 1, there are always at max. two children in a splitter

    /** handle window resize event to adjust pane size */
    const handleResize = useCallback(() => {
        if (splitter?.current && container?.current && !props.percentage) {
            const containerRect = container.current.getBoundingClientRect();
            const splitterRect = splitter.current.getBoundingClientRect();
            const secondaryPaneSize = getSecondaryPaneSize(
                containerRect,
                splitterRect,
                {
                    left: splitterRect.left,
                    top: splitterRect.top,
                },
                false,
                vertical,
                percentage,
                primaryIndex,
                primaryMinSize,
                secondaryMinSize,
                secondaryMaxSize
            );
            setSecondaryPaneSize(secondaryPaneSize);
        }
    }, [percentage, primaryIndex, primaryMinSize, props.percentage, secondaryMinSize, vertical, secondaryMaxSize]);

    /** handle touchmove/mousemove when splitter is dragged to adjust pane size */
    const handleMove = useCallback(
        (pos: SplitterPosition) => {
            if (splitter?.current && container?.current && resizing.current) {
                const containerRect = container.current.getBoundingClientRect();
                const splitterRect = splitter.current.getBoundingClientRect();
                const secondaryPaneSize = getSecondaryPaneSize(
                    containerRect,
                    splitterRect,
                    {
                        left: pos.left,
                        top: pos.top,
                    },
                    true,
                    vertical,
                    percentage,
                    primaryIndex,
                    primaryMinSize,
                    secondaryMinSize,
                    secondaryMaxSize
                );
                clearSelection();
                setSecondaryPaneSize(secondaryPaneSize);
            }
        },
        [percentage, primaryIndex, primaryMinSize, secondaryMinSize, vertical, secondaryMaxSize]
    );

    const handleMouseMove = useCallback(
        (event: MouseEvent) => {
            handleMove({
                left: event.clientX,
                top: event.clientY,
            });
        },
        [handleMove]
    );

    const handleTouchMove = useCallback(
        (event: TouchEvent) => {
            handleMove({
                left: event.changedTouches[0].clientX,
                top: event.changedTouches[0].clientY,
            });
        },
        [handleMove]
    );

    const beginResizing = useCallback(() => {
        clearSelection();
        resizing.current = true;
        if (onDragStart) {
            onDragStart();
        }
        container.current?.classList.add('libyc-splitter-moving');
    }, [onDragStart]);

    const handleSplitterMouseDown: MouseEventHandler<HTMLDivElement> = useCallback(
        (event) => {
            if (secondaryPaneCollapsed || event.button !== 0) {
                return; //only handle left button mouse
            }
            beginResizing();
        },
        [secondaryPaneCollapsed, beginResizing]
    );

    const handleSplitterTouchStart: TouchEventHandler<HTMLDivElement> = useCallback(() => {
        beginResizing();
    }, [beginResizing]);

    const handleMouseUp = useCallback(() => {
        resizing.current = false;
        if (onDragEnd) {
            onDragEnd();
        }
        container.current?.classList.remove('libyc-splitter-moving');
    }, [onDragEnd]);

    const handleSplitterButtonClick: MouseEventHandler<HTMLButtonElement> = useCallback(
        (event) => {
            Log.debug('handleSplitterButtonClick', { secondaryPaneHidden: secondaryPaneCollapsed });
            setSecondaryPaneCollapsed(!secondaryPaneCollapsed);
            setSecondaryPaneSize(Math.max(props.secondaryMinSize || 0, secondaryPaneSize));
            event.stopPropagation();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [secondaryPaneCollapsed]
    );

    useOnMount(() => {
        window.addEventListener('resize', handleResize);
        document.addEventListener('mouseup', handleMouseUp);
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('touchend', handleMouseUp);
        document.addEventListener('touchmove', handleTouchMove);

        //set initial secondary pane size, either from option or the half of the container
        let secondaryInitialSize;
        if (props.secondaryInitialSize !== undefined) {
            secondaryInitialSize = props.secondaryInitialSize;
        } else if (container?.current) {
            const containerRect = container.current.getBoundingClientRect();
            const splitterRect = splitter?.current
                ? splitter.current.getBoundingClientRect()
                : ({ width: splitterSize, height: splitterSize } as DOMRect);
            secondaryInitialSize = getSecondaryPaneSize(
                containerRect,
                splitterRect,
                {
                    left: containerRect.left + (containerRect.width - splitterRect.width) / 2,
                    top: containerRect.top + (containerRect.height - splitterRect.height) / 2,
                },
                false,
                vertical,
                percentage,
                primaryIndex,
                primaryMinSize,
                secondaryMinSize,
                secondaryMaxSize
            );
        }
        if (secondaryInitialSize !== undefined) {
            setSecondaryPaneSize(secondaryInitialSize);
        }
    });

    useOnUnmount(() => () => {
        window.removeEventListener('resize', handleResize);
        document.removeEventListener('mouseup', handleMouseUp);
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('touchend', handleMouseUp);
        document.removeEventListener('touchmove', handleTouchMove);
    });

    const { onSecondaryPaneSizeChange } = props;
    useEffect(() => {
        if (onSecondaryPaneSizeChange) {
            if (secondaryPaneCollapsed) {
                onSecondaryPaneSizeChange(0);
            } else {
                onSecondaryPaneSizeChange(secondaryPaneSize);
            }
        }
    }, [secondaryPaneSize, secondaryPaneCollapsed, onSecondaryPaneSizeChange]);

    let containerClasses = 'libyc-splitter';
    if (props.customClassName) {
        containerClasses += ` ${props.customClassName}`;
    }
    if (props.vertical) {
        containerClasses += ' libyc-splitter--vertical';
    }
    if (secondaryPaneCollapsed) {
        containerClasses += ' libyc-splitter-pane--hidden';
    }

    useEffect(() => {
        if (!props.primaryResponsiveSteps?.length || props.vertical) {
            return;
        }
        Log.debug('primary pane width changed', primaryPaneWidth);
        props.primaryResponsiveSteps.forEach((width, step) => {
            if (primaryPaneWidth >= width) {
                primaryPaneRef.current?.classList.add('libyc-splitter-pane--size-' + step);
            } else {
                primaryPaneRef.current?.classList.remove('libyc-splitter-pane--size-' + step);
            }
        });
        primaryPaneRef.current?.setAttribute('data-width', primaryPaneWidth.toString());
    }, [primaryPaneRef, primaryPaneWidth, props.primaryResponsiveSteps, props.vertical]);

    useEffect(() => {
        if (!props.secondaryResponsiveSteps?.length || props.vertical) {
            return;
        }
        Log.debug('secondary pane width changed', secondaryPaneWidth);
        props.secondaryResponsiveSteps.forEach((width, step) => {
            if (secondaryPaneWidth >= width) {
                secondaryPaneRef.current?.classList.add('libyc-splitter-pane--size-' + step);
            } else {
                secondaryPaneRef.current?.classList.remove('libyc-splitter-pane--size-' + step);
            }
        });
        secondaryPaneRef.current?.setAttribute('data-width', secondaryPaneWidth.toString());
    }, [props.secondaryResponsiveSteps, secondaryPaneRef, secondaryPaneWidth, props.vertical]);

    const splitterChildNodes = React.Children.toArray(props.children);
    Log.assert(
        splitterChildNodes.length < 3,
        `A Splitter will ignore more than two children. You have ${splitterChildNodes.length} nested within a Splitter. ${splitterChildNodes.length - 2} will be cut off.`
    );
    const children = splitterChildNodes.splice(0, 2); //a splitter works by having one "primary" and one "secondary" panel, there can't be any more
    if (children.length === 0) {
        children.push(<div />); //no children, put a dummy div
    }

    const splitterPanes = [];
    for (let i = 0; i < children.length; i++) {
        let primary = true;
        let size;
        if (children.length > 1 && i !== primaryIndex) {
            primary = false;
            size = secondaryPaneSize;
        }
        splitterPanes.push(
            <Pane
                vertical={vertical}
                ref={primary ? primaryPaneRef : secondaryPaneRef}
                percentage={percentage}
                primary={primary}
                size={size}
                hidden={!primary && secondaryPaneCollapsed}
            >
                {children[i]}
            </Pane>
        );
    }

    const cssVars = {
        '--libyc-splitter-size': `${splitterSize}px`,
    } as React.CSSProperties;

    return (
        <div
            className={containerClasses}
            ref={container}
            style={cssVars}
        >
            {splitterPanes[0]}
            {splitterPanes.length > 1 ? (
                <>
                    <div
                        role="separator"
                        className="libyc-splitter-separator"
                        ref={splitter}
                        onMouseDown={handleSplitterMouseDown}
                        onTouchStart={handleSplitterTouchStart}
                    >
                        {secondaryCollapsible ? (
                            <SplitterButton
                                vertical={vertical}
                                primaryIndex={primaryIndex}
                                secondaryPaneHidden={secondaryPaneCollapsed}
                                onClick={handleSplitterButtonClick}
                            />
                        ) : null}
                    </div>
                    {secondaryPaneCollapsed && secondaryUnmountCollapsed ? null : splitterPanes[1]}
                </>
            ) : null}
        </div>
    );
});
