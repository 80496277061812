import { Apps, BrowserUpdated, EventNote, FactCheck, GitHub, Person } from '@mui/icons-material';
import BorderStyleIcon from '@mui/icons-material/BorderStyle';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import Build from '@mui/icons-material/Build';
import { Typography } from '@mui/material';
import { AccountMenu, ApplicationType, AppModule, LayoutItem, ProgressLayout } from '@progress/base-ui';
import axios from 'axios';
import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { api, routes, useTypedTranslation } from '../definitions';
import { AuthenticationUtils } from '@/helpers/authenticationUtils';

const hasProgressDashboardUpdatePermission = AuthenticationUtils.hasProgressDashboardUpdatePermission();
const hasLicenseCheckPermissions = AuthenticationUtils.hasLicenseCheckPermissions();

const Layout: React.FC = (props) => {
    const { t } = useTypedTranslation();
    const history = useHistory();

    const applicationModules = useMemo<AppModule[]>(
        () => [
            { title: t('customerList', 'title'), baseRoute: routes.customerList, icon: Person },
            { title: t('apps', 'title'), baseRoute: routes.progressApps, icon: Apps },
            { title: t('github', 'title'), baseRoute: routes.githubList, icon: GitHub },
            { title: 'Progress Bin Updater', baseRoute: routes.progressBinUpdater, icon: BrowserUpdated },
            { title: 'Application Insights', baseRoute: routes.applicationInsights, icon: EventNote },
            { title: 'Config Sheet ', baseRoute: routes.configSheet, icon: BorderStyleIcon },
            { title: 'Pipelines ', baseRoute: routes.pipelines, icon: PlayCircleOutlineIcon },
            { title: 'Tools ', baseRoute: routes.tools, icon: Build },
            ...(hasLicenseCheckPermissions
                ? [{ title: 'License Checks ', baseRoute: routes.licenseChecks, icon: FactCheck }]
                : []),
        ],
        [t]
    );

    const handleLogout = useCallback(() => {
        window.location.href = api.logout();
    }, []);

    const accountMenu = useMemo<AccountMenu>(() => {
        const items: LayoutItem[] = [
            { children: <Typography>{t('main', 'about')}</Typography>, onClick: () => history.push(routes.about) },
            { children: <Typography>{t('main', 'logout')}</Typography>, onClick: () => handleLogout() },
        ];

        if (hasProgressDashboardUpdatePermission) {
            items.push({
                children: <Typography>{t('main', 'restart')}</Typography>,
                onClick: () => {
                    Swal.fire({
                        title: t('main', 'restartDialogText'),
                        confirmButtonColor: 'red',
                        cancelButtonColor: 'lightgrey',
                        icon: 'warning',
                        showCancelButton: true,
                        cancelButtonText: 'No',
                        showConfirmButton: true,
                        confirmButtonText: 'Yes',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // this stops the application
                            axios.post('./api/System/Stop');
                        }
                    });
                },
            });
        }

        return {
            title: t('main', 'account'),
            username: window.userInfo
                ? window.userInfo.FirstName
                    ? `${window.userInfo.FirstName} ${window.userInfo.LastName}`
                    : window.userInfo.UserName
                : '',
            items: items,
        };
    }, [handleLogout, history, t]);

    return (
        <ProgressLayout
            appType={ApplicationType.PROGRESSDASHBOARD}
            modules={applicationModules}
            customItems={[]}
            accountMenu={accountMenu}
            isDevelopment={import.meta.env.DEV}
        >
            <div
                className="container"
                style={{ overflowY: 'scroll', position: 'relative' }}
            >
                {props.children}
            </div>
        </ProgressLayout>
    );
};

export default Layout;
