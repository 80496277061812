import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import {
    Box,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    styled,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import React, { useLayoutEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { ProgressTooltip } from './internal/ProgressTooltip';
import { TopBarOffset } from './internal/sharedStyledComponents';
import { DrawerLinkItem, DrawerItem } from './types';

const isLink = (item: DrawerItem): item is DrawerLinkItem => (item as DrawerLinkItem).route !== undefined;

const DrawerContentBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    placeItems: 'center start',
    gap: '15px',
    width: '100%',
    color: theme.palette.progressGrey.dark,
    padding: '5px 0',
}));

const DrawerIconBox = styled(Box)({
    display: 'flex',
    placeItems: 'center center',
    padding: '5px 0',
});

const StyledListItemButton = styled(ListItemButton)({
    backgroundColor: 'transparent',
    borderRadius: '10px',
    padding: '0 5px',
});

const StyledNavLink = styled(NavLink)(({ theme }) => ({
    borderRadius: '10px',
    textDecoration: 'none',
    width: '100%',
    '&.active': {
        backgroundColor: '#00acff20',
        '& .MuiBox-root': {
            color: '#008acb',
        },
        '&:hover': {
            backgroundColor: '#00acff30',
            '& .MuiBox-root': {
                color: theme.palette.grey[800],
            },
        },
    },
}));

type ProgressDrawerBaseProps = {
    open: boolean;
    drawerWidth: number;
    collapseOnClick: boolean;
    items: (DrawerItem | DrawerLinkItem)[];
    showTopBar?: boolean;
    disableAnimation?: boolean;
    headerContent?: React.ReactNode; // New prop for header content
};

type ProgressDrawerProps =
    | (ProgressDrawerBaseProps & { variant: 'regular' })
    | (ProgressDrawerBaseProps & { variant: 'minimized'; minimizedDrawerWidth: number });

export const ProgressDrawer: React.FC<ProgressDrawerProps> = (props) => {
    const theme = useTheme();

    const widthLargerThan900 = useMediaQuery('(min-width:900px)');
    const widthLargerThan600 = useMediaQuery('(min-width:600px)');

    const [open, setOpen] = React.useState(props.open);
    useLayoutEffect(() => {
        setOpen(props.open && widthLargerThan900);
    }, [props.open, widthLargerThan900]);

    //Only allow minimize behaviour above this threshold
    const minimize = props.variant === 'minimized' && widthLargerThan600;
    //Below this threshold, always collapse after a click
    const collapseOnClick = props.collapseOnClick || !widthLargerThan900;

    const showTopBar = props.showTopBar ?? true;

    const currentWidth = React.useMemo(() => {
        if (open) {
            return props.drawerWidth;
        } else if (minimize) {
            return props.minimizedDrawerWidth;
        } else {
            return '0px';
        }
    }, [open, minimize, props]);

    const renderItemContent = React.useCallback(
        (item: DrawerItem) => {
            return (
                <ProgressTooltip
                    enterDelay={open ? 500 : 0}
                    sx={{ fontSize: 16 }}
                    title={item.text}
                    placement="right-start"
                >
                    <DrawerContentBox>
                        {open ? (
                            <>
                                <DrawerIconBox>{item.icon}</DrawerIconBox>
                                <Typography
                                    noWrap
                                    textOverflow="ellipsis"
                                    variant="h6"
                                >
                                    {item.text}
                                </Typography>
                            </>
                        ) : (
                            <>
                                <DrawerIconBox>{item.icon}</DrawerIconBox>
                                {item.minimizedText && <Typography variant="h6">{item.minimizedText}</Typography>}
                            </>
                        )}
                    </DrawerContentBox>
                </ProgressTooltip>
            );
        },
        [open]
    );

    return (
        <>
            {!open && !minimize && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: showTopBar ? theme.mixins.toolbar.height : '0px',
                        left: '0px',
                        background: 'white',
                        marginTop: '10px',
                        width: '41px',
                        height: '41px',
                        zIndex: (theme) => theme.zIndex.drawer,
                        border: '1px solid',
                        borderLeftStyle: 'hidden',
                        borderColor: (theme) => theme.palette.grey[400],
                        borderRadius: '0px 40px 40px 0px',
                    }}
                >
                    <IconButton
                        onClick={() => setOpen(!open)}
                        sx={{ height: '39px' }}
                    >
                        <ChevronRight />
                    </IconButton>
                </Box>
            )}
            <Box sx={{ display: 'flex', height: '100%' }}>
                <Drawer
                    variant="permanent"
                    open={open}
                    sx={{
                        width: currentWidth,
                        transition: props.disableAnimation
                            ? ''
                            : theme.transitions.create('width', {
                                  easing: theme.transitions.easing.sharp,
                                  duration: open
                                      ? theme.transitions.duration.enteringScreen
                                      : theme.transitions.duration.leavingScreen,
                              }),
                        flexShrink: 0,
                        whiteSpace: 'nowrap',
                        boxSizing: 'border-box',
                        '& .MuiDrawer-paper': {
                            whiteSpace: 'nowrap',
                            overflowX: 'hidden',
                            overflowY: 'hidden',
                            width: currentWidth,
                            transition: props.disableAnimation
                                ? ''
                                : theme.transitions.create('width', {
                                      easing: theme.transitions.easing.sharp,
                                      duration: open
                                          ? theme.transitions.duration.enteringScreen
                                          : theme.transitions.duration.leavingScreen,
                                  }),
                        },
                    }}
                >
                    {/* top bar content */}
                    {showTopBar && <TopBarOffset />}
                    <Box
                        {...(props.headerContent && open
                            ? {
                                  display: 'flex',
                                  width: '100%',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  minHeight: '70px',
                              }
                            : {
                                  display: 'flex',
                                  width: '100%',
                                  alignItems: 'center',
                                  justifyContent: 'flex-end',
                                  minHeight: '70px',
                              })}
                    >
                        {/* some additional optional content like a searchbar */}
                        {open && props.headerContent && props.headerContent}
                        {/* collaps drawer button */}
                        <Box
                            sx={{
                                // placeSelf: "end",
                                background: 'white',
                                width: '40px',
                                my: '10px',
                                height: '40px',
                                zIndex: (theme) => theme.zIndex.drawer + 1,
                                border: '1px solid',
                                borderRightStyle: 'hidden',
                                borderColor: (theme) => theme.palette.grey[300],
                                borderRadius: '40px 0px 0px 40px',
                            }}
                        >
                            <IconButton
                                onClick={() => setOpen(!open)}
                                sx={{ height: '40px' }}
                            >
                                {open ? <ChevronLeft /> : <ChevronRight />}
                            </IconButton>
                        </Box>
                    </Box>
                    <Divider />
                    {/* drawer content */}
                    <List sx={{ overflowX: 'hidden', overflowY: 'auto', padding: 0 }}>
                        {props.items.map((item, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <ListItem
                                key={index}
                                sx={{ padding: '3px' }}
                            >
                                {isLink(item) ? (
                                    <StyledNavLink
                                        {...item}
                                        to={item.route}
                                        onClick={() => {
                                            if (collapseOnClick) setOpen(false);
                                            if (item.onClick) item.onClick();
                                        }}
                                    >
                                        <StyledListItemButton className="drawerListItemButton">
                                            {renderItemContent(item)}
                                        </StyledListItemButton>
                                    </StyledNavLink>
                                ) : (
                                    <StyledListItemButton
                                        className="drawerListItemButton"
                                        onClick={() => {
                                            if (collapseOnClick) setOpen(false);
                                            if (item.onClick) item.onClick();
                                        }}
                                    >
                                        {renderItemContent(item)}
                                    </StyledListItemButton>
                                )}
                            </ListItem>
                        ))}
                    </List>
                </Drawer>

                {/* main content) */}
                <Box
                    component="main"
                    sx={{ flexGrow: 1, overflow: 'auto' }}
                >
                    {props.children}
                </Box>
            </Box>
        </>
    );
};
