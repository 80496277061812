import { ArrowLeft } from '@mui/icons-material';
import { MouseEventHandler } from 'react';

export type SplitterButtonProps = {
    vertical: boolean;
    primaryIndex: 0 | 1;
    secondaryPaneHidden: boolean;
    onClick: MouseEventHandler<HTMLButtonElement>;
};

export const SplitterButton = (props: SplitterButtonProps) => {
    const buttonClassNames = ['libyc-splitter-button'];
    if (props.vertical) {
        //both panes are vertically aligned (horizontal splitter)
        props.primaryIndex === 0
            ? buttonClassNames.push('libyc-splitter-button--above-side')
            : buttonClassNames.push('libyc-splitter-button--below-side');
    } else {
        //both panes are horizontally aligned (vertical splitter)
        props.primaryIndex === 0
            ? buttonClassNames.push('libyc-splitter-button--left-side')
            : buttonClassNames.push('libyc-splitter-button--right-side');
    }
    if (props.secondaryPaneHidden) {
        buttonClassNames.push('libyc-splitter-button--pane-hidden');
    }
    return (
        <button
            className={buttonClassNames.join(' ')}
            onClick={props.onClick}
            onMouseDown={(event) => event.stopPropagation()}
            onDragStart={(event) => event.stopPropagation()}
        >
            <ArrowLeft />
        </button>
    );
};
