import { useState, useEffect, useCallback } from 'react';

/**
 * A debounced state hook.
 * @param initialValue The initial state value
 * @param delay The debounce delay in milliseconds
 * @returns An array with the debounced state value and a setter function
 */
export function useDebouncedState<T>(initialValue: T, delay: number): [T, (value: T) => void] {
    const [value, setValue] = useState<T>(initialValue);
    const [debouncedValue, setDebouncedValue] = useState<T>(initialValue);

    const setDebounced = useCallback((newValue: T) => {
        setValue(newValue); // Update the actual state
    }, []);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value); // Update the debounced state after the delay
        }, delay);

        return () => {
            clearTimeout(handler); // Cleanup the timeout if the value or delay changes
        };
    }, [value, delay]);

    return [debouncedValue, setDebounced]; // Return the debounced state and the setter function
}
