/** styles */

import styled from '@emotion/styled';
import { bemClassGenerator } from '../utils/htmlUtils';

/** bem class name generator used by PDFViewer and its sub components */

export const pdfViewerClass = bemClassGenerator('libyc-pdfviewer');

export const PDFViewerContainer = styled('div')({
    position: 'relative',
    width: '100%',
    height: '100%',
    [`& .${pdfViewerClass('document-loading').className}, & .${pdfViewerClass('no-document').className}, & .${pdfViewerClass('no-pages').className}`]:
        {
            /** center the document loading spinner */
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'none',
        },
    [`& .${pdfViewerClass('no-document').className}, & .${pdfViewerClass('no-pages').className}`]: {
        fontSize: '18px',
    },
});

export const PDFPagesContainer = styled('div')({
    display: 'grid',
    boxSizing: 'border-box',
    overflow: 'hidden',
    /** other styles for the gridviewer container are state dependent */
    [`& .${pdfViewerClass('page').className}`]: {
        /* to center the content inside the grid */
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            position: 'relative',
        },
    },
    [`& .${pdfViewerClass('page-content').className}`]: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: '#FCFCFC',
        '& > *': {
            /** this is essential for sharp canvas scaling -
             *  the real size of the canvas is bigger than the container */
            width: '100%',
            height: '100%',
            display: 'block',
        },
    },
});
