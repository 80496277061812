import { LoadingButton } from '@mui/lab';
import { Button, Typography } from '@mui/material';
import { ProgressDialog } from '@progress/base-ui';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import ScreenLoading from '../../components/ScreenLoading';
import { api, useTypedTranslation } from '../../definitions';
import { useReduxDispatch, useTypedSelector } from '../../store/configureStore';
import { fetchDeployments } from '../../store/data/deployments/actions';
import { deploymentUIActions } from '../../store/ui/deploymentsUISlice';
import { AuthenticationUtils } from '@/helpers/authenticationUtils';

export const DeleteDialog = () => {
    const hasPermission = AuthenticationUtils.hasAppManagementPermission();
    const deploymentToDelete = useTypedSelector((state) => state.ui.deployments.deploymentToDelete);

    const dispatch = useReduxDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { t, tp } = useTypedTranslation();

    const [loading, setLoading] = useState(false);

    const handleCancel = useCallback(() => {
        dispatch(deploymentUIActions.setDeploymentToDelete(undefined));
    }, [dispatch]);

    const handleSubmit = useCallback(() => {
        if (!deploymentToDelete || !hasPermission) {
            return;
        }
        setLoading(true);

        axios
            .delete(
                api.configurationDeleteByUniqueId(
                    deploymentToDelete.kind === 'app' ? deploymentToDelete.appIdentifier : deploymentToDelete.name
                )
            )
            .then(() => {
                enqueueSnackbar('Success', { variant: 'success' });
                dispatch(deploymentUIActions.setDeploymentToDelete(undefined));

                dispatch(fetchDeployments());
            })
            .catchError((e) => {
                enqueueSnackbar(e.Description, { variant: 'error' });
            })
            .finally(() => {
                setLoading(false);
            });
    }, [deploymentToDelete, dispatch, enqueueSnackbar, hasPermission]);

    if (!deploymentToDelete) {
        return null;
    }

    return (
        <ProgressDialog
            title={t('main', 'delete')}
            open
            onClose={handleCancel}
            actions={
                <>
                    <Button
                        variant="text"
                        onClick={handleCancel}
                        disabled={loading}
                    >
                        {t('main', 'cancel')}
                    </Button>
                    <LoadingButton
                        variant="contained"
                        onClick={handleSubmit}
                        loading={loading}
                    >
                        {t('main', 'delete')}
                    </LoadingButton>
                </>
            }
        >
            <Typography variant="h6">
                {deploymentToDelete.kind === 'app'
                    ? tp({ name: deploymentToDelete.appIdentifier }, 'customerList', 'confirmDeleteAppMessage')
                    : tp({ name: deploymentToDelete.name }, 'customerList', 'confirmDeleteCustomerMessage')}
            </Typography>
            {loading && <ScreenLoading />}
        </ProgressDialog>
    );
};
