import { Customer } from '@/definitions/autogenerated/types';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../configureStore';
import { fetchCustomers } from './actions';

export const customersAdapter = createEntityAdapter<Customer>({
    selectId: (customer) => customer.Domain,
    sortComparer: (x, y) => x.DisplayName.localeCompare(y.DisplayName),
});

export const customersSlice = createSlice({
    name: 'customers',
    initialState: customersAdapter.getInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCustomers.fulfilled, (state, action) => {
            customersAdapter.setAll(state, action.payload);
        });
    },
});

export const customersSelectors = customersAdapter.getSelectors<RootState>((state) => state.data.customers);
