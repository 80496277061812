import { Splitter } from '@progress/base-ui';
import React, { MutableRefObject } from 'react';
import { ConfigSheetToolbar } from './components/ConfigSheetToolbar';
import { DropZone } from './components/DropZone';
import { FabricCanvas } from './components/FabricCanvas';
import { PropertyGridEditor } from './components/PropertyGridEditor';
import { TreeExplorer } from './components/TreeExplorer';
import { FabricContextProvider } from './FabricContext';
import './styles.css';
// import types to apply typescript global extensions
import {} from './types/fabric';

const ConfigSheet = () => {
    const wrapperRef: MutableRefObject<HTMLDivElement> = React.useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;

    return (
        <div
            ref={wrapperRef}
            style={{ display: 'flex', flexDirection: 'column', height: '0', flexGrow: 1, flexShrink: 1, background: '#fff' }}
        >
            <DropZone wrapperRef={wrapperRef} />

            <ConfigSheetToolbar />

            <div style={{ display: 'flex', flexGrow: 1, flexShrink: 1, overflow: 'hidden' }}>
                <TreeExplorer />

                <Splitter
                    splitterSize={4}
                    primaryIndex={0}
                    primaryMinSize={225}
                    secondaryInitialSize={325}
                    secondaryMinSize={125}
                >
                    <FabricCanvas />
                    <PropertyGridEditor />
                </Splitter>
            </div>
        </div>
    );
};

export const ConfigSheetPage = () => {
    return (
        <FabricContextProvider>
            <ConfigSheet />
        </FabricContextProvider>
    );
};

export default ConfigSheetPage;
