import styled from '@emotion/styled';
import {
    DataGridPremium,
    DataGridPremiumProps,
    GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    GridColDef,
    GridInitialState,
    GridPinnedColumnFields,
    GridRowId,
    GridRowModel,
    GridRowSelectionModel,
    GridSortDirection,
    GridValidRowModel,
    useGridApiRef,
    useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { getMuiDataGridTranslations, useTypedTranslation } from '../definitions/languages';
import { TypedOmit } from '../definitions/Partials';
import { Log } from '../utils/debug';
import BaseDataGridStyles from './baseDataGridStyles.css';
import {
    DataGridColumnOrder,
    DataGridColumnPinning,
    DataGridColumnSorting,
    DataGridColumnVisibility,
    DataGridColumnWidths,
    DataGridDensity,
    DataGridGetDetailParams,
    DataGridGetRowId,
    DataGridRowGrouping,
    DataGridRowId,
    DataGridRowType,
    DataGridSortDirection,
    DataGridUIState,
} from './baseDataGridTypes';
import { getAllExpandedGroupRowIds } from './baseDataGridUtils';

//export type BaseDataGridProps<T extends DataGridRowType> = DataGridPremiumProps<T>;
const StyledBaseDataGrid = styled(DataGridPremium)(BaseDataGridStyles);

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export type BaseDataGridProps<T extends DataGridRowType> = TypedOmit<
    DataGridPremiumProps<T>,
    | 'getDetailPanelContent'
    | 'getDetailPanelHeight'
    | 'getRowId'
    | 'sortModel'
    | 'onSortModelChange'
    | 'rowGroupingModel'
    | 'onRowSelectionModelChange'
    | 'onRowGroupingModelChange'
    | 'onColumnWidthChange'
    | 'onColumnOrderChange'
    | 'columnVisibilityModel'
    | 'onColumnVisibilityModelChange'
    | 'initialState'
    | 'localeText'
    | 'density'
> & {
    density?: DataGridDensity;
    gridState?: Partial<DataGridUIState<T>>;
    onGridStateChange?: (state: Partial<DataGridUIState<T>>) => void;
    onRowSelectionChange?: (rowIds: DataGridRowId[]) => void;
    getDetail?: ({ rowId, row }: DataGridGetDetailParams<T>) => React.ReactNode;
    getRowId: DataGridGetRowId<T>;
};

export type MuiGridSortItem<T extends GridValidRowModel> = {
    field: keyof T;
    sort: GridSortDirection;
};

export type MuiGridSortModel<T extends GridValidRowModel> = MuiGridSortItem<T>[];

export function BaseDataGrid<T extends DataGridRowType>(props: BaseDataGridProps<T>) {
    const { language } = useTypedTranslation();

    const {
        rows,
        columns: externalColumns,
        apiRef: externalApiRef,
        getDetail,
        gridState,
        density,
        onGridStateChange,
        onRowSelectionChange,
        ...rest
    } = props;

    const internalGridApiRef = useGridApiRef();
    const gridApiRef = externalApiRef || internalGridApiRef;

    const localeText = useMemo(() => getMuiDataGridTranslations(language), [language]);

    const selectedRowIdsRef = useRef<DataGridRowId[]>([]);

    const initialState: GridInitialState = useKeepGroupedColumnsHidden({ apiRef: gridApiRef, initialState: {} });
    // for unknown reasons, the mui datagrid resets the row expansion state after the rows are "updated" (new reference)
    // therefore, we have to track the expanded row before a "possible update" and reset the state after the rows have been updated
    // see: https://github.com/mui/mui-x/issues/13064
    useEffect(() => {
        const expandedGroupRowIds: GridRowId[] = getAllExpandedGroupRowIds(gridApiRef);
        gridApiRef.current.setRows(rows as unknown as GridRowModel[]);
        expandedGroupRowIds.forEach((rowId) => {
            const node = gridApiRef.current.getRowNode(rowId);
            if (!node || node.type !== 'group') return;
            gridApiRef.current.setRowChildrenExpansion(rowId, true);
        });
    }, [rows, gridApiRef]);

    /** strongly typed getDetail - with rowId as string and row as T */
    const getDetailPanelContent = useCallback<NonNullable<DataGridPremiumProps['getDetailPanelContent']>>(
        ({ id, row }) => (getDetail ? getDetail({ rowId: id as string, row }) : undefined),
        [getDetail]
    );

    /* === grid state management === */

    /** column order changed: build a typed column order object through the api */
    const handleGridColumnOrderChanged = useCallback(() => {
        if (!onGridStateChange) return;
        const order: DataGridColumnOrder<T> = gridApiRef.current.getAllColumns().reduce((result, column, index) => {
            if (column.field.startsWith('__')) return result; // exclude all build in grid fields from the column order
            (result as Record<string, number>)[column.field] = index;
            return result;
        }, {});
        Log.debug('onGridStateChange', { order });
        onGridStateChange({ order });
    }, [gridApiRef, onGridStateChange]);

    /** column visibility changed, return a typed column visibility object */
    const handleColumnVisibilityModelChange = useCallback(
        (visibility: DataGridColumnVisibility<T>) => {
            if (!onGridStateChange) return;
            Log.debug('onGridStateChange', { visibility });
            onGridStateChange({ visibility });
        },
        [onGridStateChange]
    );

    /** column width changed, return a typed column width object omitting the build in grid fields */
    const handleColumnWidthChange = useCallback(() => {
        if (!onGridStateChange) return;
        const columnWidths: DataGridColumnWidths<T> = gridApiRef.current.getAllColumns().reduce((result, column, index) => {
            if (column.field.startsWith('__') || column.flex) return result; // exclude all build in grid fields from the column width
            (result as Record<string, number>)[column.field] = column.width || 0;
            return result;
        }, {});
        Log.debug('onGridStateChange', { width: columnWidths });
        onGridStateChange({ width: columnWidths });
    }, [gridApiRef, onGridStateChange]);

    /** column sorting conversion */
    const gridSorting = useMemo(() => {
        if (!gridState?.sorting) return undefined;
        return Object.entries(gridState.sorting).map(([field, sort]) => ({
            field: field,
            sort: sort,
        }));
    }, [gridState?.sorting]);

    /** column sorting changed, build a typed column sorting object, omitting all null values */
    const handleSortModelChange = useCallback(
        (model: MuiGridSortModel<T>) => {
            if (!onGridStateChange) return;
            const sorting: DataGridColumnSorting<T> = model.reduce((result, sort) => {
                if (sort.sort) (result as Record<keyof T, DataGridSortDirection>)[sort.field] = sort.sort;
                return result;
            }, {});
            Log.debug('onGridStateChange', { sorting });
            onGridStateChange({ sorting });
        },
        [onGridStateChange]
    );

    /** row grouping conversion */
    const gridGrouping = useMemo(() => {
        if (!gridState?.grouping) return undefined;
        return Object.entries(gridState.grouping).map(([field, index]) => field);
    }, [gridState?.grouping]);

    /** row grouping changed, build a typed row grouping object with field name and grouping index */
    const handleRowGroupingModelChange = useCallback(
        (model: Array<keyof T>) => {
            if (!onGridStateChange) return;
            const grouping: DataGridRowGrouping<T> = (model ?? {}).reduce((record, key, index) => {
                record[key as keyof T] = index;
                return record;
            }, {} as DataGridRowGrouping<T>);
            Log.debug('onGridStateChange', { grouping });
            onGridStateChange({ grouping });
        },
        [onGridStateChange]
    );

    const gridPinning = useMemo(() => {
        if (!gridState?.pinning) return undefined;
        const result: GridPinnedColumnFields = {};
        if (gridState.pinning.left) result.left = Object.entries(gridState.pinning.left).map(([field, index]) => field);
        if (gridState.pinning.right) result.right = Object.entries(gridState.pinning.right).map(([field, index]) => field);
        return result;
    }, [gridState?.pinning]);

    /** column pinning changed */
    const handlePinnedColumnsChange = useCallback(
        (pinnedColumns: GridPinnedColumnFields) => {
            if (!onGridStateChange) return;
            Log.debug('onGridStateChange', { pinning: pinnedColumns });
            const pinning: DataGridColumnPinning<T> = {
                left: (pinnedColumns.left ?? []).reduce(
                    (result, field, index) => {
                        result[field as keyof T] = index;
                        return result;
                    },
                    {} as Record<keyof T, number>
                ),
                right: (pinnedColumns.right ?? []).reduce(
                    (result, field, index) => {
                        result[field as keyof T] = index;
                        return result;
                    },
                    {} as Record<keyof T, number>
                ),
            };
            onGridStateChange({ pinning });
        },
        [onGridStateChange]
    );

    const columns = useMemo(() => {
        const result = externalColumns
            .toSorted((a, b) => {
                return (gridState?.order?.[a.field] ?? 0) - (gridState?.order?.[b.field] ?? 0);
            })
            .map((column) => {
                if (gridState?.width?.[column.field] !== undefined) {
                    column.width = gridState.width[column.field];
                    column.flex = undefined;
                }
                return column;
            });
        //Fix: manually add the detail panel toggle column, otherwise the columns "resort" when grouping and resizing afterwards
        getDetail && result.unshift({ ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF, hideable: false });
        return result;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [externalColumns, gridState?.order, gridState?.width]);

    const handleRowSelectionModelChange = useCallback(
        (rowSelectionModel: GridRowSelectionModel) => {
            if (!onRowSelectionChange) return;
            if (
                selectedRowIdsRef.current.length !== rowSelectionModel.length ||
                !selectedRowIdsRef.current.every((id) => rowSelectionModel.includes(id))
            ) {
                selectedRowIdsRef.current = [...rowSelectionModel] as DataGridRowId[];
                onRowSelectionChange([...selectedRowIdsRef.current]);
            }
        },
        [onRowSelectionChange]
    );

    const getTogglableColumns = (columns: GridColDef[]) => {
        return columns
            .filter((column) => ![GRID_DETAIL_PANEL_TOGGLE_COL_DEF.field].includes(column.field))
            .map((column) => column.field);
    };

    Log.debug(columns);
    return (
        <StyledBaseDataGrid
            /* eslint-disable-next-line @typescript-eslint/no-explicit-any -- type assertion needed for styled since it doesn't pass the generics properly */
            {...(rest as any)}
            columns={columns}
            apiRef={gridApiRef}
            initialState={initialState}
            //headerFilterHeight={contentHeight}
            columnHeaderHeight={props.density === 'compact' ? 34 : 32} //note the height is not exact with density set
            rowHeight={props.density === 'compact' ? 29 : 32}
            localeText={localeText}
            getDetailPanelContent={getDetail ? getDetailPanelContent : undefined}
            getDetailPanelHeight={() => 'auto'}
            columnVisibilityModel={gridState?.visibility}
            sortModel={gridSorting}
            pinnedColumns={gridPinning}
            rowGroupingModel={gridGrouping}
            onSortModelChange={onGridStateChange ? handleSortModelChange : undefined}
            onRowGroupingModelChange={onGridStateChange ? handleRowGroupingModelChange : undefined}
            onColumnWidthChange={onGridStateChange ? handleColumnWidthChange : undefined}
            onColumnOrderChange={onGridStateChange ? handleGridColumnOrderChanged : undefined}
            onColumnVisibilityModelChange={onGridStateChange ? handleColumnVisibilityModelChange : undefined}
            onPinnedColumnsChange={onGridStateChange ? handlePinnedColumnsChange : undefined}
            onRowSelectionModelChange={onRowSelectionChange ? handleRowSelectionModelChange : undefined}
            slotProps={{
                ...(props.slotProps ?? {}),
                columnsManagement: {
                    getTogglableColumns,
                    disableResetButton: true,
                    ...(props.slotProps?.columnsManagement ?? {}),
                },
            }}
        />
    );
}
