import { Logger } from '@progress/base-ui';
import axios from 'axios';

axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (401 === error.response.status) {
            Logger.debug('AXIOS INTERCEPTOR... 401 response', error);

            const target = api.logout();
            Logger.debug('Logout target', target);
            window.location.href = target;

            return Promise.reject(error);
        } else {
            return Promise.reject(error);
        }
    }
);

export const api = {
    deploymentList: './api/data/deploymentList',
    releasesList: './api/data/releaseList',
    releases: './api/data/releases',
    githubList: './api/data/githubList',
    createGithubRelease: './api/data/createGithubRelease',
    progressApps: './api/data/progressApps',
    progressAIRequest: (name: string) => `./api/data/progressAIRequest/${name}`,
    progressBinUpdater: './api/kopia/token',
    applicationInsights: './api/data/applicationInsightKeys',
    pipelines: './api/Pipeline',
    pipelineById: (id: number) => `./api/Pipeline/${id}`,
    getPipelineDifferenceById: (id: number) => `./api/Pipeline/difference/${id}`,
    pipelineManualTrigger: `./api/Pipeline/trigger`,
    logout: (returnUrl: string = window.location.href) =>
        './api/authentication/logout?returnUrl=' + encodeURIComponent(returnUrl),

    configSheetRemoteFiles: './api/ConfigSheet/files',
    configSheetRemoteFileById: (fileId: string) => `./api/ConfigSheet/files/${encodeURIComponent(fileId)}`,

    configurationCustomer: './api/Configuration/customers',

    syncZabbixState: `./api/Data/syncZabbixState`,
    configurationByName: (configName: string) => `./api/Configuration/_ByName/${configName}`,

    configurationGetConfig: (userId: string) => `./api/Configuration/${userId}`,

    // special config: Progress Admins
    progressAdmins: `./api/Configuration/ProgressAdmins`,
    progressAdminById: (id: string) => `./api/Configuration/ProgressAdmins/${id}`,

    // Create
    configurationAddConfiguration: `./api/Configuration/AddConfiguration`,

    // Update
    configurationUpdateConfiguration: `./api/Configuration/UpdateConfiguration`,

    configurationUpdateVersion: (uniqueIdentifier: string, version: string) =>
        `./api/Configuration/${uniqueIdentifier}/versions/${version}`,
    configurationUpdateGlobalVersion: (uniqueIdentifier: string, version: string) =>
        `./api/Configuration/global-apps/${uniqueIdentifier}/versions/${version}`,

    // Delete
    configurationDeleteByUniqueId: (uniqueIdentifier: string) => `./api/Configuration/${uniqueIdentifier}`,

    encryptPassword: () => `./api/Tools/Encrypt`,
    decryptPassword: () => `./api/Tools/Decrypt`,
};
