import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GithubReleaseRespond, GithubRelease } from '../../../definitions/autogenerated/types';

export type GithubReleasesState = {
    releases: GithubRelease[];
    maxState: number;
};

const initialReleasesState: GithubReleasesState = { releases: [], maxState: 0 };

export const githubSlice = createSlice({
    name: 'githubReleases',
    initialState: initialReleasesState,
    reducers: {
        addNewElements(state, action: PayloadAction<GithubReleaseRespond>) {
            state.releases = action.payload.Release;
            state.maxState = action.payload.MaxState;
        },
    },
});

export const githubActions = githubSlice.actions;
