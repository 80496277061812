import { createTypedAsyncThunk } from '@/helpers/helpers';
import axios from 'axios';
import { api } from '../../../definitions';
import { PipelineDto } from '../../../definitions/autogenerated/types';
import { pipelinesActions } from './pipelinesSlice';

export const fetchPipelines = createTypedAsyncThunk<PipelineDto[], void>('pipelines/fetch', async (params, thunkAPI) => {
    const response = await axios.get<PipelineDto[]>(api.pipelines);
    const pipelines = response.data;
    thunkAPI.dispatch(pipelinesActions.addNewElements(pipelines));
    return pipelines;
});

export const createGithubRelease = createTypedAsyncThunk<void, void>('githubRelease/createGithubReleases', async () => { //passt
    await axios.post(api.createGithubRelease);
    return;
});
