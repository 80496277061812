import { useReduxDispatch, useTypedSelector } from '@/store/configureStore';
import { fetchCustomers } from '@/store/data/customers/actions';
import { customersSelectors } from '@/store/data/customers/customersSlice';
import { ExtApplicationType } from '@/store/data/release/releasesSlice';
import { Alert, AlertTitle, Button, Checkbox, FormControlLabel, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { EnumUtils } from '@progress/base-ui';
import { DateTime, DurationLike } from 'luxon';
import React, { ChangeEvent, useCallback, useMemo } from 'react';
import { useTypedTranslation } from '../definitions';
import { IConfigAssemblyMapping } from '../definitions/autogenerated/assemblyMappings';
import { AheadModules, EbosWebApiEndpoints, MebosYCApiEndpoints } from '../definitions/autogenerated/types';
import AddCustomerDomain from './AddCustomerDomain';

type FieldType = {
    key: string;
    label: string;
    placeholder?: string;
} & (
    | { type: 'string' }
    | { type: 'domain' }
    | { type: 'flag' }
    | { type: 'customer' }
    | { type: 'fixed'; value: string | number | boolean }
    | { type: 'imageTag'; applicationType: ExtApplicationType }
    | { type: 'flags'; flags: { flagName: string; flagValue: number }[] }
    | { type: 'singleSelect'; values: { displayName: string; value: string | number }[] }
    | { type: 'multiSelect'; values: { displayName: string; value: string }[] }
    | { type: 'info'; description: string }
    | { type: 'upgradeToStabosYCv2' }
    | { type: 'subscriptionDatePicker' }
);

const licenses = [
    'ERPbos.CommonFeedbacks',
    'ERPbos.DefectManagement',
    'ERPbos.ElementInfo',
    'ERPbos.ManualProduction',
    'ERPbos.Activities',
    'ERPbos.DeliveryNotes',
    'ERPbos.GoodsReceived',
    'ERPbos.Complaints',
    'ERPbos.DeliveryManagement',
    'ERPbos.RepairOrder',
    'ERPbos.PurchaseInquiries',
];

const applicationFields: Record<ExtApplicationType | 'Customer', FieldType[] | undefined> = {
    Customer: [
        { key: 'Domain', label: 'Customer', type: 'domain' },
        { key: 'DisplayName', label: 'Display name', type: 'string' },
        { key: 'Remark', label: 'Remark', type: 'string' },
        { key: 'ServiceContract', label: 'Service contract', type: 'flag' },
        { key: 'CreateCustomerPortal', label: 'Create CustomerPortal', type: 'flag' },
        { key: 'ClusterId', label: 'ClusterId', type: 'fixed', value: 'cluster01' },
        {
            key: 'Licenses',
            label: 'MebosYC Licenses',
            type: 'multiSelect',
            values: licenses.sort().map((l) => ({ displayName: l, value: l })),
        },
    ],
    AdminCenterYC: [
        { key: 'Domain', label: 'Customer', type: 'customer' },
        { key: 'DisplayName', label: 'Display name', type: 'string' },
        { key: 'Remark', label: 'Remark', type: 'string' },
        { key: 'ServiceContract', label: 'Service contract', type: 'flag' },
        { key: 'ClusterId', label: 'ClusterId', type: 'fixed', value: 'cluster01' },
        {
            key: 'Licenses',
            label: 'MebosYC Licenses',
            type: 'multiSelect',
            values: licenses.sort().map((l) => ({ displayName: l, value: l })),
        },
    ],
    AdvancedPlanningService: [
        { key: 'Domain', label: 'Customer', type: 'customer' },
        { key: 'InstanceId', label: 'Instance-Id', type: 'string' },
        { key: 'ImageTag', label: 'Image tag', type: 'imageTag', applicationType: 'AdvancedPlanningService' },
        { key: 'UserName', label: 'UserName', type: 'string' },
        { key: 'Password', label: 'Password', type: 'string' },
    ],
    Ahead: [
        { key: 'Domain', label: 'Customer', type: 'customer' },
        { key: 'CatalogName', label: 'Catalog name', type: 'string', placeholder: 'Database name' },
        { key: 'SqlConnectionName', label: 'SQL Connection name', type: 'fixed', value: 'db-mssql-psd-internal' },
        { key: 'ImageTag', label: 'Image tag', type: 'imageTag', applicationType: 'Ahead' },
        { key: 'InstanceId', label: 'Instance Id', type: 'string', placeholder: 'CUSTOMER_PROD or CUSTOMER_TEST' },
        { key: 'WCFServiceName', label: 'WCF Service name', type: 'string' },
        { key: 'WCFEncryptedPassword', label: 'WCF Encrypted password', type: 'string' },
        { key: 'IsDemo', label: 'WCF Encrypted password', type: 'fixed', value: true },
        {
            key: 'EnabledModules',
            label: 'EnabledModules',
            type: 'flags',
            flags: Object.entries(AheadModules)
                .filter((e) => Number.isNaN(Number(e[0])))
                .map((e) => ({ flagName: e[0], flagValue: e[1] as number })),
        },
    ],
    AheadProxy: [
        { key: 'Domain', label: 'Customer', type: 'customer' },
        { key: 'InstanceId', label: 'Instance Id', type: 'string', placeholder: 'e.g. ahead-prod or ahead-test' },
    ],
    AvicadYC: [
        { key: 'Domain', label: 'Customer', type: 'customer' },
        { key: 'ClusterId', label: 'ClusterId', type: 'fixed', value: 'cluster01' },
    ],
    Vision: [{ key: 'Domain', label: 'Customer', type: 'customer' }],
    ERPbosWebAPI: [
        { key: 'Domain', label: 'Customer', type: 'customer' },
        { key: 'InstanceId', label: 'Instance Id', type: 'string', placeholder: 'CUSTOMER_PROD or CUSTOMER_TEST' },
        { key: 'WCFServiceName', label: 'WCF Service name', type: 'string', placeholder: 'CUSTOMER_PROD or CUSTOMER_TEST' },
        { key: 'WCFEncryptedPassword', label: 'WCF Encrypted password', type: 'string' },
        { key: 'ImageTag', label: 'Image tag', type: 'imageTag', applicationType: 'ERPbosWebAPI' },
        { key: 'Pathbase', label: 'Pathbase', type: 'string', placeholder: 'e. g. ERP/CUSTOMER_PROD/PxmlApi' },
    ],
    EbosWebAPI: [
        { key: 'Domain', label: 'Customer', type: 'customer' },
        { key: 'InstanceId', label: 'Instance Id', type: 'string', placeholder: 'Location-Plant (Brixen-240)' },
        { key: 'WCFServiceName', label: 'WCF Service name', type: 'string', placeholder: 'ebos ConfigType' },
        { key: 'WCFEncryptedPassword', label: 'WCF Encrypted password', type: 'string' },
        { key: 'ImageTag', label: 'Image tag', type: 'imageTag', applicationType: 'EbosWebAPI' },
        {
            key: 'EnabledEndpoints',
            label: 'EnabledEndpoints',
            type: 'flags',
            flags: Object.entries(EbosWebApiEndpoints)
                .filter((e) => Number.isNaN(Number(e[0])))
                .map((e) => ({ flagName: e[0], flagValue: e[1] as number })),
        },
    ],
    EbosYC: [
        { key: 'Domain', label: 'Customer', type: 'customer' },
        { key: 'InstanceId', label: 'Instance Id', type: 'string', placeholder: 'Location-Plant (Brixen-240)' },
        { key: 'WCFServiceName', label: 'WCF Service name', type: 'string', placeholder: 'ebos ConfigType' },
        { key: 'WCFEncryptedPassword', label: 'WCF Encrypted password', type: 'string' },
        { key: 'ImageTag', label: 'Image tag', type: 'imageTag', applicationType: 'EbosYC' },
    ],
    EbosYCProxy: [
        { key: 'Domain', label: 'Customer', type: 'customer' },
        { key: 'InstanceId', label: 'Instance-Id', type: 'string', placeholder: 'Location-Plant (Brixen-240)' },
        { key: 'LegacyEbosWebApiUrl', label: 'Legacy WebApi Url', type: 'string', placeholder: '/ProgressB240/eboswebapi' },
        {
            key: 'LegacyEbosWebApiInfo',
            label: '"Legacy" PTS API support',
            type: 'info',
            description:
                'The Legacy EbosWebAPI Url specifies the url of the EbosWebAPI (if any). Internally, all requests to this api are forwarded to the ebosYC Backend. Set this property if the ebos-server had a PXML-Api for PTS requests and you cannot modify the params of each PTS client (ebos, provit, AviCAD, ...).',
        },
    ],
    MebosYCAPI: [
        { key: 'Domain', label: 'Customer', type: 'customer' },
        { key: 'InstanceId', label: 'Instance Id', type: 'string', placeholder: 'CUSTOMER_PROD or CUSTOMER_TEST' },
        { key: 'WCFServiceName', label: 'WCF Service name', type: 'string', placeholder: 'CUSTOMER_PROD or CUSTOMER_TEST' },
        { key: 'WCFEncryptedPassword', label: 'WCF Encrypted password', type: 'string' },
        { key: 'ImageTag', label: 'Image tag', type: 'imageTag', applicationType: 'MebosYCAPI' },
        {
            key: 'MebosType',
            label: 'Type',
            type: 'singleSelect',
            values: Object.entries(MebosYCApiEndpoints)
                .filter((e) => Number.isNaN(Number(e[0])))
                .map((e) => ({ displayName: e[0], value: e[1] as number })),
        },
        {
            key: 'AdditionalEndpoints',
            label: 'Additional Endpoints',
            type: 'flags',
            flags: Object.entries(MebosYCApiEndpoints)
                .filter((e) => Number.isNaN(Number(e[0])))
                .map((e) => ({ flagName: e[0], flagValue: e[1] as number })),
        },
    ],
    MebosYCWebApp: [
        { key: 'Domain', label: 'Customer', type: 'customer' },
        { key: 'SystemDisplayName', label: 'System Displayname', type: 'string' },
        // TODO @grimor
    ],
    ProfitV4WebAPI: [
        { key: 'Domain', label: 'Customer', type: 'customer' },
        { key: 'InstanceId', label: 'Instance Id', type: 'string', placeholder: 'Location-Plant (Brixen-240 or Brixen)' },
        { key: 'WCFServiceName', label: 'WCF Service name', type: 'string', placeholder: 'profit ConfigType' },
        { key: 'WCFEncryptedPassword', label: 'WCF Encrypted password', type: 'string' },
        { key: 'ImageTag', label: 'Image tag', type: 'imageTag', applicationType: 'ProfitV4WebAPI' },
    ],
    ProfitYC: [
        { key: 'Domain', label: 'Customer', type: 'customer' },
        { key: 'InstanceId', label: 'Instance Id', type: 'string', placeholder: 'Location-Plant (Brixen-240 or Brixen)' },
        { key: 'WCFServiceName', label: 'WCF Service name', type: 'string', placeholder: 'profit ConfigType' },
        { key: 'WCFEncryptedPassword', label: 'WCF Encrypted password', type: 'string' },
        { key: 'ImageTag', label: 'Image tag', type: 'imageTag', applicationType: 'ProfitYC' },
    ],
    ProfitYCProxy: [
        { key: 'Domain', label: 'Customer', type: 'customer' },
        { key: 'InstanceId', label: 'Instance-Id', type: 'string', placeholder: 'Location-Plant (Brixen-240)' },
    ],
    StabosYC: [
        { key: 'Domain', label: 'Customer', type: 'customer' },
        { key: 'InstanceId', label: 'Instance Id', type: 'string', placeholder: 'Location-Plant (Brixen-240 or Brixen)' },
        { key: 'WCFServiceName', label: 'WCF Service name', type: 'string', placeholder: 'stabos ConfigType or .*' },
        { key: 'WCFEncryptedPassword', label: 'WCF Encrypted password', type: 'string' },
    ],
    StabosYCProxy: [
        { key: 'Domain', label: 'Customer', type: 'customer' },
        { key: 'InstanceId', label: 'Instance-Id', type: 'string', placeholder: 'Location-Plant (Brixen-240 or Brixen)' },
        { key: 'DisplayName', label: 'Displayname', type: 'string', placeholder: 'Brixen (customer portal link name)' },
        { key: 'UpgradeToStabosYCv2', label: 'Migrate to stabosYCv2', type: 'upgradeToStabosYCv2' },
    ],
    StabosYCV2Proxy: [
        { key: 'Domain', label: 'Customer', type: 'customer' },
        { key: 'InstanceId', label: 'Instance-Id', type: 'string', placeholder: 'Location-Plant (Brixen-240 or Brixen)' },
        { key: 'DisplayName', label: 'Displayname', type: 'string', placeholder: 'Brixen (customer portal link name)' },
        { key: 'SubscriptionValidUntil', label: 'Subscription valid until', type: 'subscriptionDatePicker' },
    ],
    CustomerPortal: [
        { key: 'Domain', label: 'Customer', type: 'customer' },
        { key: 'ClusterId', label: 'ClusterId', type: 'fixed', value: 'cluster01' },
    ],
    PTSManagerYCProxy: [
        { key: 'Domain', label: 'Customer', type: 'customer' },
        { key: 'InstanceId', label: 'Instance-Id', type: 'string', placeholder: 'Location-Plant (Brixen-240)' },
    ],
    ApplicationProxy: undefined,
    GitHub: undefined,
    ProgressDashboard: undefined,
    ProgressWiki: undefined,
    StabosYCLegacy: undefined,
    Zabbix: undefined,
    AksDashboard: undefined,
    ProgressInsights: undefined,
    ProgressAi: undefined,
    ConfluenceWiki: undefined,
};

type ApplicationFieldProps = {
    operation: 'create' | 'update';

    // we need to differentiate between 'AdminCenterYC' and 'Customer' because
    // the the filed 'Domain' needs to be a TextField when creating a new customer
    // and a Select in any other case
    applicationType: ExtApplicationType | 'Customer';
    values: Record<string, string | number | boolean | string[]>;
    onChange: (key: string, newValue: string | number | boolean | string[]) => void;
};

export const ApplicationFields = (props: ApplicationFieldProps) => {
    const { t } = useTypedTranslation();

    const allReleases = useTypedSelector((state) => state.data.releases.releases);
    const customers = useTypedSelector(customersSelectors.selectAll);
    const fields = useMemo(() => applicationFields[props.applicationType] ?? [], [props.applicationType]);

    const dispatch = useReduxDispatch();

    React.useLayoutEffect(() => {
        dispatch(fetchCustomers());

        // build initial state
        if (props.operation === 'create') {
            fields.forEach((k) => {
                switch (k.type) {
                    case 'fixed': {
                        props.onChange(k.key, k.value);
                        break;
                    }

                    case 'flags': {
                        props.onChange(k.key, 0);
                        break;
                    }

                    case 'flag': {
                        props.onChange(k.key, false);
                        break;
                    }

                    case 'multiSelect': {
                        props.onChange(k.key, []);
                        break;
                    }

                    case 'subscriptionDatePicker': {
                        props.onChange(k.key, DateTime.now().startOf('day').plus({ months: 6 }).toISO());
                        break;
                    }

                    default:
                        props.onChange(k.key, '');
                        break;
                }
            });
        } else if (props.operation === 'update') {
            fields.forEach((k) => {
                if (k.type === 'upgradeToStabosYCv2') {
                    props.onChange('SubscriptionValidUntil', DateTime.now().startOf('day').plus({ months: 6 }).toISO());
                }
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFlagChange = useCallback(
        (propKey: string, flagValue: number) => (event: ChangeEvent<HTMLInputElement>) => {
            if (event.target.checked) {
                props.onChange(propKey, EnumUtils.setFlag(props.values[propKey] as number, flagValue));
            } else {
                props.onChange(propKey, EnumUtils.removeFlag(props.values[propKey] as number, flagValue));
            }
        },
        [props]
    );

    const handleFieldChange = useCallback(
        (key: string, value: string | number | boolean | string[]) => {
            props.onChange(key, value);
        },
        [props]
    );

    return (
        <>
            {fields.map((field, index) => {
                if (field.type === 'string') {
                    return (
                        <div
                            key={field.key}
                            style={{ display: 'flex', gap: 10, alignItems: 'center' }}
                        >
                            <InputLabel
                                htmlFor={`${field.key}-${index}`}
                                style={{ width: 225, textAlign: 'right' }}
                            >
                                {field.label}
                            </InputLabel>
                            <TextField
                                id={`${field.key}-${index}`}
                                fullWidth
                                autoFocus={index === 0}
                                autoComplete="off"
                                placeholder={field.placeholder}
                                value={props.values[field.key] ?? ''}
                                onChange={(e) => props.onChange(field.key, e.target.value)}
                                variant="outlined"
                                size="small"
                            />
                        </div>
                    );
                } else if (field.type === 'domain') {
                    return (
                        <AddCustomerDomain
                            key={field.key}
                            fieldKey={field.key}
                            fieldPlaceholder={field.placeholder}
                            fieldLabel={field.label}
                            index={index}
                            values={props.values}
                            onChange={handleFieldChange}
                        />
                    );
                } else if (field.type === 'customer') {
                    return (
                        <div
                            key={field.key + props.values[field.key]}
                            style={{ display: 'flex', gap: 10, alignItems: 'center' }}
                        >
                            <InputLabel
                                htmlFor={`${field.key}-${index}`}
                                style={{ width: 225, textAlign: 'right' }}
                            >
                                {field.label}
                            </InputLabel>
                            <Select
                                id={`${field.key}-${index}`}
                                fullWidth
                                autoFocus={index === 0}
                                placeholder={field.placeholder}
                                autoComplete="off"
                                size="small"
                                disabled={props.operation === 'update'}
                                value={props.values[field.key] ?? ''}
                                onChange={(e) => props.onChange(field.key, e.target.value)}
                            >
                                {customers.map((c) => {
                                    return (
                                        <MenuItem
                                            key={c.Domain}
                                            value={c.Domain}
                                        >
                                            {c.DisplayName}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </div>
                    );
                } else if (field.type === 'imageTag') {
                    const releases = allReleases[field.applicationType] ?? [];

                    return (
                        <div
                            key={field.key + props.values[field.key]}
                            style={{ display: 'flex', gap: 10, alignItems: 'center' }}
                        >
                            <InputLabel
                                htmlFor={`${field.key}-${index}`}
                                style={{ width: 225, textAlign: 'right' }}
                            >
                                {field.label}
                            </InputLabel>
                            <Select
                                fullWidth
                                id={`${field.key}-${index}`}
                                autoFocus={index === 0}
                                placeholder={field.placeholder}
                                autoComplete="off"
                                size="small"
                                value={props.values[field.key] ?? ''}
                                onChange={(e) => props.onChange(field.key, e.target.value)}
                            >
                                {releases.map((r) => {
                                    return (
                                        <MenuItem
                                            key={r}
                                            value={r}
                                        >
                                            {r}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </div>
                    );
                } else if (field.type === 'flags') {
                    return (
                        <div
                            key={field.key + props.values[field.key]}
                            style={{
                                display: 'flex',
                                gap: 10,
                                alignItems: 'left',
                                flexDirection: 'column',
                                border: '1px solid gray',
                                padding: 5,
                            }}
                        >
                            <InputLabel
                                htmlFor={`${field.key}-${index}`}
                                style={{ width: 225, textAlign: 'left' }}
                            >
                                {field.label}
                            </InputLabel>
                            <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 20 }}>
                                {field.flags.map((flag) => (
                                    <FormControlLabel
                                        key={flag.flagName}
                                        control={
                                            <Checkbox
                                                checked={EnumUtils.hasFlag(props.values[field.key] as number, flag.flagValue)}
                                                onChange={handleFlagChange(field.key, flag.flagValue)}
                                            />
                                        }
                                        label={flag.flagName}
                                    />
                                ))}
                            </div>
                        </div>
                    );
                } else if (field.type === 'flag') {
                    return (
                        <div
                            key={field.key}
                            style={{ display: 'flex', gap: 10, alignItems: 'center' }}
                        >
                            <InputLabel
                                htmlFor={`${field.key}-${index}`}
                                style={{ minWidth: 225, textAlign: 'right' }}
                            >
                                {field.label}
                            </InputLabel>
                            <div style={{ width: '100%' }}>
                                <Checkbox
                                    id={`${field.key}-${index}`}
                                    checked={props.values[field.key] === true}
                                    onChange={(e) => props.onChange(field.key, e.target.checked)}
                                />
                            </div>
                        </div>
                    );
                } else if (field.type === 'singleSelect') {
                    return (
                        <div
                            key={field.key + props.values[field.key]}
                            style={{ display: 'flex', gap: 10, alignItems: 'center' }}
                        >
                            <InputLabel
                                htmlFor={`${field.key}-${index}`}
                                style={{ width: 225, textAlign: 'right' }}
                            >
                                {field.label}
                            </InputLabel>
                            <Select
                                id={`${field.key}-${index}`}
                                fullWidth
                                autoFocus={index === 0}
                                placeholder={field.placeholder}
                                autoComplete="off"
                                size="small"
                                value={props.values[field.key] ?? ''}
                                onChange={(e) => props.onChange(field.key, e.target.value)}
                            >
                                {field.values.map((v, index) => {
                                    return (
                                        <MenuItem
                                            key={v.displayName + index}
                                            value={v.value}
                                        >
                                            {v.displayName}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </div>
                    );
                } else if (field.type === 'multiSelect') {
                    const currentValue = (props.values[field.key] ?? []) as string[];

                    return (
                        <div
                            key={field.key + props.values[field.key]}
                            style={{
                                display: 'flex',
                                gap: 10,
                                alignItems: 'left',
                                flexDirection: 'column',
                                border: '1px solid gray',
                                padding: 5,
                            }}
                        >
                            <InputLabel
                                htmlFor={`${field.key}-${index}`}
                                style={{ width: 225, textAlign: 'left' }}
                            >
                                {field.label}
                            </InputLabel>
                            <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 20 }}>
                                {field.values.map((value) => (
                                    <FormControlLabel
                                        key={value.value}
                                        control={
                                            <Checkbox
                                                checked={currentValue.includes(value.value)}
                                                onChange={(e) => {
                                                    if (!e.currentTarget.checked) {
                                                        props.onChange(
                                                            field.key,
                                                            currentValue.filter((license) => license !== value.value)
                                                        );
                                                    } else {
                                                        props.onChange(field.key, [...currentValue, value.value]);
                                                    }
                                                }}
                                            />
                                        }
                                        label={value.displayName}
                                    />
                                ))}
                            </div>
                        </div>
                    );
                } else if (field.type === 'info') {
                    return (
                        <div key={field.key}>
                            <Alert severity="info">
                                {field.label && <AlertTitle>{field.label}</AlertTitle>}
                                {field.description}
                            </Alert>
                        </div>
                    );
                } else if (field.type === 'upgradeToStabosYCv2') {
                    const checked = props.values[field.key] === true;
                    const setChecked = (checked: boolean) => {
                        if (checked) {
                            props.onChange('$type', IConfigAssemblyMapping['StabosYCV2Proxy']);
                        } else {
                            props.onChange('$type', IConfigAssemblyMapping['StabosYCProxy']);
                        }
                        props.onChange(field.key, checked);
                    };

                    const value = !props.values['SubscriptionValidUntil']
                        ? null
                        : DateTime.fromISO(props.values['SubscriptionValidUntil'] as string);

                    return (
                        <React.Fragment key={field.key}>
                            <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                                <InputLabel
                                    htmlFor={`${field.key}-${index}`}
                                    style={{ minWidth: 225, textAlign: 'right' }}
                                >
                                    {field.label}
                                </InputLabel>
                                <div style={{ width: '100%' }}>
                                    <Checkbox
                                        id={`${field.key}-${index}`}
                                        checked={checked}
                                        onChange={(e) => setChecked(e.target.checked)}
                                    />
                                </div>
                            </div>
                            {checked && (
                                <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                                    <InputLabel
                                        htmlFor={`${field.key}-${index}-subscription-valid-until`}
                                        style={{ width: 225, textAlign: 'right' }}
                                    >
                                        {'Subscription valid until'}
                                    </InputLabel>

                                    <DatePicker<DateTime>
                                        value={value}
                                        onChange={(value) =>
                                            props.onChange('SubscriptionValidUntil', value!.startOf('day').toISO())
                                        }
                                        format="D"
                                        disablePast
                                        slotProps={{
                                            textField: {
                                                size: 'small',
                                                margin: 'dense',
                                                id: `${field.key}-${index}-subscription-valid-until`,
                                                fullWidth: true,
                                                inputProps: { readOnly: true },
                                            },
                                        }}
                                    />
                                </div>
                            )}
                        </React.Fragment>
                    );
                } else if (field.type === 'subscriptionDatePicker') {
                    const value = !props.values[field.key] ? null : DateTime.fromISO(props.values[field.key] as string);

                    const handleAddDuration = (duration: DurationLike) => {
                        const newValue = DateTime.now().startOf('day').plus(duration);
                        props.onChange(field.key, newValue.toISO());
                    };

                    const handleCancelSubscription = () => {
                        const newValue = DateTime.fromMillis(0).startOf('day');
                        props.onChange(field.key, newValue.toISO());
                    };

                    return (
                        <React.Fragment key={field.key}>
                            <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                                <InputLabel
                                    htmlFor={`${field.key}-${index}`}
                                    style={{ width: 225, textAlign: 'right' }}
                                >
                                    {field.label}
                                </InputLabel>
                                <DatePicker<DateTime>
                                    value={value}
                                    onChange={(value) => props.onChange(field.key, value!.startOf('day').toISO())}
                                    format="D"
                                    slotProps={{
                                        textField: {
                                            size: 'small',
                                            margin: 'dense',
                                            id: `${field.key}-${index}`,
                                            fullWidth: true,
                                            inputProps: { readOnly: true },
                                        },
                                    }}
                                />
                            </div>
                            <div style={{ display: 'flex', gap: '5px', justifyContent: 'end' }}>
                                <Button
                                    variant="outlined"
                                    onClick={() => handleAddDuration({ months: 6 })}
                                >
                                    {t('customerList', 'add6Months')}
                                </Button>
                                <Button
                                    variant="outlined"
                                    onClick={() => handleAddDuration({ years: 1 })}
                                >
                                    {t('customerList', 'add1Year')}
                                </Button>
                                <Button
                                    variant="outlined"
                                    onClick={handleCancelSubscription}
                                    color="error"
                                >
                                    {t('customerList', 'cancelSubscription')}
                                </Button>
                            </div>
                        </React.Fragment>
                    );
                }
                return <React.Fragment key={field.key} />;
            })}
        </>
    );
};
