import { createTypedAsyncThunk } from '@/helpers/helpers';
import axios from 'axios';
import { api } from '../../../definitions';
import { ExtApplicationType, releaseActions } from './releasesSlice';

export const fetchReleases = createTypedAsyncThunk<Partial<Record<ExtApplicationType, string[]>>, void>(
    'release/fetchReleases',
    async (params, thunkAPI) => {
        const response = await axios.get<Partial<Record<ExtApplicationType, string[]>>>(api.releases);
        const releases = response.data;
        thunkAPI.dispatch(releaseActions.addNewElements(releases));
        return releases;
    }
);
