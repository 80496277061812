import { Log } from './debug';

/**
 * Gradually changes the opacity of an HTMLDivElement to a specified value over a given duration.
 *
 * @param element - The HTMLDivElement whose opacity will be changed.
 * @param targetOpacity - The target opacity value (between 0 and 1).
 * @param duration - The duration of the fade effect in milliseconds.
 */
export function fadeTo(element: HTMLDivElement, targetOpacity: number, duration: number): void {
    const interval = 50; // Interval in milliseconds to adjust opacity
    const steps = duration / interval; // Total number of steps to reach the target
    const increment = (targetOpacity - parseFloat(element.style.opacity || '1')) / steps; // Calculate increment/decrement per step

    const fadeEffect = setInterval(() => {
        const currentOpacity = parseFloat(element.style.opacity || '0') + increment;
        // Check if the fade effect should be stopped
        if ((increment > 0 && currentOpacity >= targetOpacity) || (increment < 0 && currentOpacity <= targetOpacity)) {
            element.style.opacity = targetOpacity.toString();
            clearInterval(fadeEffect);
        } else {
            element.style.opacity = currentOpacity.toString();
        }
    }, interval);
}

/**
 * Utilizes the fadeTo function to fade in an element by gradually increasing its opacity to 1.
 *
 * @param element - The HTMLDivElement to be faded in.
 * @param duration - The duration of the fade-in effect in milliseconds.
 */
export function fadeIn(element: HTMLDivElement, duration: number): void {
    fadeTo(element, 1, duration);
}

/**
 * Utilizes the fadeTo function to fade out an element by gradually decreasing its opacity to 0.
 *
 * @param element - The HTMLDivElement to be faded out.
 * @param duration - The duration of the fade-out effect in milliseconds.
 */
export function fadeOut(element: HTMLDivElement, duration: number): void {
    fadeTo(element, 0, duration);
}

/**
 * Retrieves an element from the document with the specified ID, or triggers an assertion if the element is not found.
 *
 * @param {string} id - The ID of the element to retrieve.
 * @return {HTMLElement | null} The element with the specified ID, or null if no such element exists.
 */
export function getElementById(id: string): HTMLElement | null {
    const el = document.getElementById(id);
    Log.assert(!!el, `Element with id '${id}' not found.`);
    return el;
}

/**
 * Generates a string of class names with optional modifiers, prefixed appropriately.
 * @param prefix The prefix to apply to the class names.
 * @param classNames A space-separated string of class names.
 * @param modifiers A space-separated string of modifiers to append to each class name.
 * @returns A string of fully qualified class names with modifiers.
 */
export const bemClass = (prefix: string, classNames?: string, modifiers?: string): string => {
    const baseClasses = classNames
        ? classNames.split(' ').map((className) => `${prefix}__${className}`) // Split classNames and prefix
        : modifiers
          ? [prefix, ...modifiers.split(' ').map((modifier) => `${prefix}__${modifier}`)]
          : [prefix];
    let allClasses = [...baseClasses];
    if (classNames && modifiers) {
        const modifierClasses = baseClasses.flatMap(
            (baseClass) => modifiers.split(' ').map((modifier) => `${baseClass}--${modifier}`) // Create modifier classes
        );
        allClasses = [...allClasses, ...modifierClasses]; // Combine base classes and modifier classes
    }
    return allClasses.join(' '); // Join all classes into a single string
};

/**
 * Generates a function for creating prefixed class names with optional modifiers.
 * @param prefix The prefix to apply to all class names generated by the returned function.
 * @returns A function that takes class names and optional modifiers, then returns the fully qualified class names.
 */
export const bemClassGenerator = (prefix: string) => {
    return (classNames?: string, modifiers?: string) => ({
        className: bemClass(prefix, classNames, modifiers),
    });
};
