import { Divider, Typography } from '@mui/material';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { AppModule } from '../types';
import { ColorScheme } from './colors';

type ModuleTitleProps = {
    module: AppModule;
    colors: ColorScheme;
};

const ModuleTitle: React.FC<ModuleTitleProps> = ({ module, colors }) => {
    const match = useRouteMatch(module.baseRoute);

    if (!match || (module.exact && !match.isExact)) {
        return null;
    }

    return (
        <>
            <Divider
                orientation="vertical"
                sx={{ background: colors.activeText, height: '28px', marginLeft: '20px', marginRight: '20px' }}
            />
            <Typography
                variant="h6"
                fontSize="20px"
                noWrap
                sx={{ color: colors.activeText }}
            >
                {module.title}
            </Typography>
        </>
    );
};

export default ModuleTitle;
