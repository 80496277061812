import { useReduxDispatch } from '@/store/configureStore';
import { fetchCustomers } from '@/store/data/customers/actions';
import { customersSelectors } from '@/store/data/customers/customersSlice';
import { Alert, Button, InputLabel, MenuItem, Select } from '@mui/material';
import { ProgressDialog } from '@progress/base-ui';
import { useSnackbar } from 'notistack';
import React, { useLayoutEffect, useState, useCallback } from 'react';
import { useTypedSelector } from '../../store/configureStore';

type GenerateCrossProgressAuthTokenProps = {
    onClose: () => void;
};

export const GenerateCrossProgressAuthToken: React.FC<GenerateCrossProgressAuthTokenProps> = (props) => {
    const customers = useTypedSelector(customersSelectors.selectAll);
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const { enqueueSnackbar } = useSnackbar();

    const dispatch = useReduxDispatch();

    useLayoutEffect(() => {
        dispatch(fetchCustomers());
    }, [dispatch]);

    const customer = customers.find((c) => c.Domain === selectedCustomer);

    const copyToClipboard = useCallback(() => {
        const token = customer?.CrossProgressAuthToken;
        if (token) {
            navigator.clipboard.writeText(token);
            enqueueSnackbar('Copied to the clipboard', { variant: 'success' });
        }
    }, [customer?.CrossProgressAuthToken, enqueueSnackbar]);

    return (
        <ProgressDialog
            open
            onClose={props.onClose}
            title="Generate X-Progress-Auth Token"
            maxWidth="sm"
            fullWidth
            actions={
                <>
                    <Button
                        variant="contained"
                        onClick={props.onClose}
                    >
                        Close
                    </Button>
                </>
            }
        >
            <div style={{ display: 'flex', gap: 10, alignItems: 'center', marginBottom: '10px' }}>
                <InputLabel
                    htmlFor="customer"
                    style={{ width: 225, textAlign: 'right' }}
                >
                    Customer
                </InputLabel>
                <Select
                    id="customer"
                    fullWidth
                    autoFocus
                    placeholder="Customer"
                    autoComplete="off"
                    size="small"
                    value={selectedCustomer}
                    onChange={(e) => setSelectedCustomer(e.target.value)}
                >
                    {customers.map((c) => {
                        return (
                            <MenuItem
                                key={c.Domain}
                                value={c.Domain}
                            >
                                {c.DisplayName}
                            </MenuItem>
                        );
                    })}
                </Select>
            </div>

            <div
                style={{
                    border: 'solid 1px #bbb',
                    borderRadius: 5,
                    minHeight: 50,
                    wordWrap: 'break-word',
                    padding: 10,
                    marginBottom: '10px',
                }}
            >
                {!customer && <i style={{ color: '#999' }}>Select a customer</i>}
                {customer && customer.CrossProgressAuthToken}
            </div>

            <div style={{ display: 'flex', gap: 10, justifyContent: 'end', marginBottom: '10px' }}>
                <Button
                    variant="outlined"
                    disabled={!customer}
                    onClick={copyToClipboard}
                >
                    Copy to clipboard
                </Button>
            </div>

            <Alert severity="info">
                Token for authentication within Progress applications.
                <br />
                Is used, for example, to establish communications between ebosYC and stabosYC.
                <br />
                The token must be parameterised in the general settings (X-Progress Authentication Token)
            </Alert>
        </ProgressDialog>
    );
};
