import { api } from '@/definitions';
import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import axios, { AxiosRequestConfig } from 'axios';
import { LayoutFile, LayoutFileContent } from '../../../definitions/autogenerated/types';

export type LocalFile = { name: string; fileHandle: FileSystemFileHandle };
export type SelectedFile = {
    sourceType: 'remoteFile' | 'localFile' | 'playground';
    name: string;
};

const initialState = {
    localFiles: [] as LocalFile[],
    selectedFile: { sourceType: 'playground', name: 'playground' } as SelectedFile,
    loading: false,
    showTreeExplorer: true,
};

export const remoteFilesAdapter = createEntityAdapter<LayoutFile>({
    selectId: (remoteFile) => remoteFile.FileId,
});

export const localFilesAdapter = createEntityAdapter<LocalFile>({
    selectId: (localFile) => localFile.name,
});

export const remoteFilesSlice = createSlice({
    name: 'remoteFiles',
    initialState: remoteFilesAdapter.getInitialState(),
    reducers: {
        upsertOneRemoteFile: remoteFilesAdapter.upsertOne,
        setAllRemoteFiles: remoteFilesAdapter.setAll,
    },
});

export const localFilesSlice = createSlice({
    name: 'localFiles',
    initialState: localFilesAdapter.getInitialState(),
    reducers: {
        upsertOneLocalFile: localFilesAdapter.upsertOne,
        addOneLocalFile: localFilesAdapter.addOne,
        setAllLocalFiles: localFilesAdapter.setAll,
    },
});

export const configSheetSlice = createSlice({
    name: 'configSheet',
    initialState: initialState,
    reducers: {
        setLocalFiles(state, payload: PayloadAction<LocalFile[]>) {
            state.localFiles = payload.payload;
        },
        setSelectedFile(state, payload: PayloadAction<SelectedFile>) {
            state.selectedFile = payload.payload;
        },
        setLoading(state, payload: PayloadAction<boolean>) {
            state.loading = payload.payload;
        },
        setShowTreeExplorer(state, payload: PayloadAction<boolean>) {
            state.showTreeExplorer = payload.payload;
        },
    },
});

export const { setLocalFiles, setSelectedFile, setLoading, setShowTreeExplorer } = configSheetSlice.actions;

export const { upsertOneRemoteFile, setAllRemoteFiles } = remoteFilesSlice.actions;

export const { upsertOneLocalFile, setAllLocalFiles, addOneLocalFile } = localFilesSlice.actions;

const axiosBaseQuery =
    (
        { baseUrl }: { baseUrl: string } = { baseUrl: '' }
    ): BaseQueryFn<
        {
            url: string;
            method: AxiosRequestConfig['method'];
            data?: AxiosRequestConfig['data'];
            responseType?: AxiosRequestConfig['responseType'];
        },
        unknown,
        unknown
    > =>
    async ({ url, method, data, responseType }) => {
        try {
            const result = await axios({ url: baseUrl + url, method, data, responseType });
            return { data: result.data };
        } catch (axiosError) {
            return {
                error: axiosError,
            };
        }
    };

export const remoteFilesAPI = createApi({
    reducerPath: 'remoteFiles',
    baseQuery: axiosBaseQuery({ baseUrl: '' }),
    tagTypes: ['RemoteFiles'],
    endpoints: (build) => ({
        getRemoteFiles: build.query<EntityState<LayoutFile>, void>({
            query: () => ({
                url: api.configSheetRemoteFiles,
                method: 'GET',
            }),
            transformResponse: (response: LayoutFile[]) =>
                remoteFilesAdapter.setAll(remoteFilesAdapter.getInitialState(), response),
            providesTags: ['RemoteFiles'],
        }),

        getRemoteFile: build.query<LayoutFileContent, { fileId: string }>({
            query: ({ fileId }) => ({
                url: api.configSheetRemoteFileById(fileId),
                method: 'GET',
            }),
            // transformResponse: (response: RemoteFileContent) => remoteFilesAdapter.setAll(remoteFilesAdapter.getInitialState(), response),
            providesTags: ['RemoteFiles'],
        }),
    }),
});

export const { useGetRemoteFilesQuery, useGetRemoteFileQuery } = remoteFilesAPI;
