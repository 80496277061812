import { Deployment } from '../../../definitions/autogenerated/types';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../configureStore';
import { fetchDeployments } from './actions';

export const deploymentAdapter = createEntityAdapter<Deployment>({
    selectId: (deployment) => deployment.AppName,
});

export const deploymentSlice = createSlice({
    name: 'deployments',
    initialState: deploymentAdapter.getInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchDeployments.fulfilled, (state, action) => {
            deploymentAdapter.setAll(state, action.payload);
        });
    },
});

export const deploymentSelectors = deploymentAdapter.getSelectors<RootState>((state) => state.data.deployments);
