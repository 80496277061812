import { apiKeyAdapter, useGetApiKeysQuery, usePostLogsMutation } from '@/store/data/applicationInsights/actions';
import { Send } from '@mui/icons-material';
import { Box, Button, Grid, LinearProgress, MenuItem, Paper, Select, SelectChangeEvent, TextField } from '@mui/material';
import {
    DataGridPremiumProps,
    GridRowId,
    GridRowParams,
    GridToolbarColumnsButton,
    GridToolbarQuickFilter,
    useGridApiRef,
} from '@mui/x-data-grid-premium';
import { DataGridBase, useOnUnmount } from '@progress/base-ui';
import { getMuiDataGridTranslations } from '@progress/base-ui/src/definitions/languages';
import { Dictionary } from '@reduxjs/toolkit';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTypedTranslation } from '../../definitions';
import { APIKey } from '../../definitions/autogenerated/types';
const tableOptions = ['exceptions', 'customEvents'];

const allowedColumns = ['date', 'time', 'customer', 'message', 'LogTypeError', 'clientId', 'category', 'RequestPath', 'logType'];

const ApplicationInsightsQuery = (props: APIKey & { customer?: string }) => {
    const [days, setDays] = useState(1);
    const [table, setTable] = useState(tableOptions[0]);

    const { t } = useTypedTranslation();

    const [loadLogs, { isLoading }] = usePostLogsMutation({ fixedCacheKey: 'applicationInsights' });

    const selection = useMemo(
        () =>
            tableOptions.map((option, index) => (
                <MenuItem
                    key={index}
                    value={option}
                >
                    {option === 'exceptions' ? 'errors' : 'info & warnings'}
                </MenuItem>
            )),
        []
    );

    const handleSelection = useCallback((event: SelectChangeEvent<string>) => {
        setTable(event.target.value);
    }, []);

    return (
        <Grid
            component={Paper}
            container
            style={{ marginBottom: '10px' }}
            direction="row"
        >
            <Grid
                container
                spacing={2}
                item
                xs={12}
                justifyContent="space-evenly"
                style={{ margin: '10px' }}
            >
                <Grid
                    item
                    xs={6}
                >
                    <Select
                        fullWidth
                        size="small"
                        value={table}
                        onChange={handleSelection}
                    >
                        {selection}
                    </Select>
                </Grid>
                <Grid
                    item
                    xs={6}
                    container
                    alignItems="center"
                    direction="row"
                >
                    <TextField
                        variant="outlined"
                        label={t('main', 'days')}
                        size="small"
                        value={days}
                        type="number"
                        style={{ marginRight: '10px', flexGrow: 1 }}
                        onChange={(event) => setDays(Number(event.target.value))}
                    ></TextField>
                    <Button
                        variant="contained"
                        color="primary"
                        endIcon={<Send />}
                        onClick={() => {
                            loadLogs({
                                key: props,
                                parameter: {
                                    days: days,
                                    table: table,
                                    customer: props.customer,
                                },
                            });
                        }}
                    >
                        {t('main', 'send')}
                    </Button>
                </Grid>
            </Grid>

            {isLoading && <LinearProgress style={{ width: '100%' }} />}
        </Grid>
    );
};

const ApplicationInsightsTable = () => {
    const getDetailPanelContent: DataGridPremiumProps['getDetailPanelContent'] = React.useCallback(
        ({ row }) => (Object.entries(row).length !== 0 ? <ExceptionDetail row={row} /> : undefined),
        []
    );
    const [, { isLoading, data: logs }] = usePostLogsMutation({ fixedCacheKey: 'applicationInsights' });
    const apiRef = useGridApiRef();
    const { language } = useTypedTranslation();
    const messages = React.useMemo(() => getMuiDataGridTranslations(language), [language]);
    const [, { reset: restApplicationInsightsGrid }] = usePostLogsMutation({ fixedCacheKey: 'applicationInsights' });

    //clean data from Redux when unmounting Component
    useOnUnmount(() => {
        restApplicationInsightsGrid();
    });

    //on RowClick show row Detail
    const onRowClick = React.useCallback(
        (params: GridRowParams) => {
            const rowNode = apiRef.current.getRowNode(params.id);
            if (rowNode) {
                let rowsToExpand: GridRowId[] = [];
                const expandedPanels = apiRef.current.getExpandedDetailPanels();
                if (expandedPanels && expandedPanels.length > 0) {
                    if (expandedPanels.includes(params.id)) {
                        rowsToExpand = expandedPanels.filter((p) => p !== params.id);
                    } else {
                        rowsToExpand = [...expandedPanels, params.id];
                    }
                } else {
                    rowsToExpand = [params.id];
                }
                apiRef.current.setExpandedDetailPanels(rowsToExpand);
            }
        },
        [apiRef]
    );

    //DataGrid is rendered inside useMemo to force it to always use initialState when data changes.
    const ApplicationInsightsDataGrid = React.useMemo(() => {
        return (
            <DataGridBase
                key={uuidv4()}
                className="progress-table"
                rowHeight={32}
                rows={logs?.rows || []}
                columns={logs?.columns || []}
                loading={isLoading}
                initialState={{
                    sorting: {
                        sortModel: [
                            { field: 'date', sort: 'desc' },
                            { field: 'time', sort: 'desc' },
                        ],
                    },
                    columns: {
                        columnVisibilityModel: logs?.columns.reduce((prev: Record<string, boolean>, cur) => {
                            prev[cur.field] = allowedColumns.includes(cur.field);
                            return prev;
                        }, {}),
                    },
                }}
                slotProps={{
                    toolbar: GridToolBar,
                }}
                getDetailPanelHeight={() => 'auto'}
                getDetailPanelContent={getDetailPanelContent}
                localeText={messages}
                onRowClick={onRowClick}
            />
        );
    }, [getDetailPanelContent, isLoading, logs?.columns, logs?.rows, messages, onRowClick]);

    return <Paper className="container">{ApplicationInsightsDataGrid}</Paper>;
};

const gridToolbarContainerStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
};

const GridToolBar = () => {
    return (
        <div style={gridToolbarContainerStyle}>
            <GridToolbarColumnsButton />
            <GridToolbarQuickFilter
                autoComplete="off"
                variant="outlined"
                size="small"
                style={{ padding: '5px' }}
                debounceMs={500}
            />
            <div></div> {/*forces "GridToolbarQuickFilter"  to the center*/}
        </div>
    );
};

const ExceptionDetail = ({ row }: { row: Record<string, string> }) => {
    return (
        <Box marginBottom={3}>
            <pre>{row['message']}</pre>
            <DetailTable row={row} />
        </Box>
    );
};

const DetailTable = ({ row }: { row: object }) => {
    const data = Object.entries(row).map(([key, value], index) => ({ id: index, columnName: key, value: value }));
    const columns = [{ field: 'columnName' }, { field: 'value', flex: 1 }];

    return (
        <DataGridBase
            density="compact"
            hideFooter
            rows={data}
            columns={columns}
        />
    );
};

const ApplicationInsightsData = (params: { type?: string; customer?: string }) => {
    const { data: apiKeyData } = useGetApiKeysQuery();

    const [apiKeyEntities, setApiKeyEnities] = useState<Dictionary<APIKey>>();

    useEffect(() => {
        if (apiKeyData) setApiKeyEnities(apiKeyAdapter.getSelectors().selectEntities(apiKeyData));
    }, [apiKeyData, setApiKeyEnities]);

    const apiKeyEntity = apiKeyEntities?.[params.type ?? ''];

    return (
        <>
            {apiKeyEntity && (
                <ApplicationInsightsQuery
                    Name={apiKeyEntities[params.type || '']?.Name || ''}
                    Key={apiKeyEntities[params.type || '']?.Key || ''}
                    ApplicationId={apiKeyEntities[params.type || '']?.ApplicationId || ''}
                    Type={apiKeyEntity.Type}
                    customer={params.customer}
                />
            )}
            <ApplicationInsightsTable />
        </>
    );
};
export default ApplicationInsightsData;
