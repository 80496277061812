import AdminCenterYCLogo from './logos/admincenteryc';
import AdvancedPlanningServiceLogo from './logos/advancedplanningservice';
import AheadAPSLogo from './logos/aheadaps';
import AKSDashboardLogo from './logos/aksdashboard';
import AvicadYCLogo from './logos/avicadyc';
import BimProLogo from './logos/bimpro';
import CustomerPortalLogo from './logos/customerportal';
import EbosPXMLLogo from './logos/ebospxml';
import EbosYCLogo from './logos/ebosyc';
import ErpbosPXMLLogo from './logos/erpbospxml';
import InformationExchangeLogo from './logos/informationexchange';
import MebosYCLogo from './logos/mebosyc';
import MebosYCAPILogo from './logos/mebosycapi';
import MebosYCWebAppLogo from './logos/mebosycwebapp';
import ProfitPXMlLogoLogo from './logos/profitpxml';
import ProfitYCLogo from './logos/profityc';
import ProgressDashboardLogo from './logos/progressdashboard';
import ProgressInsightsLogo from './logos/progressinsights';
import PTSManagerYCLogo from './logos/ptsmanageryc';
import StabosYCLogo from './logos/stabosyc';
import StabosYCv2Logo from './logos/stabosycv2';
import StackPlanYCLogo from './logos/stackplanyc';
import SteelbosLogo from './logos/steelbos';
import UITemplatesLogo from './logos/uitemplates';
import VisionLogo from './logos/vision';

export enum ApplicationType {
    ADMINCENTERYC = 'ADMINCENTERYC',
    ADVANCEDPLANNINGSERVICE = 'ADVANCEDPLANNINGSERVICE',
    AHEADAPS = 'AHEADAPS',
    AVICADYC = 'AVICADYC',
    BIMPRO = 'BIMPRO',
    CUSTOMERPORTAL = 'CUSTOMERPORTAL',
    PROGRESSDASHBOARD = 'PROGRESSDASHBOARD',
    EBOSPXML = 'EBOSPXML',
    EBOSYC = 'EBOSYC',
    ERPBOSPXML = 'ERPBOSPXML',
    INFORMATIONEXCHANGE = 'INFORMATIONEXCHANGE',
    MEBOSYC = 'MEBOSYC',
    MEBOSYCAPI = 'MEBOSYCAPI',
    MEBOSYCWEBAPP = 'MEBOSYCWEBAPP',
    PROFITPXML = 'PROFITPXML',
    PROFITYC = 'PROFITYC',
    PROGRESSINSIGHTS = 'PROGRESSINSIGHTS',
    STABOSYC = 'STABOSYC',
    STABOSYCV2 = 'STABOSYCV2',
    STACKPLANYC = 'STACKPLANYC',
    STEELBOS = 'STEELBOS',
    VISION = 'VISION',
    AKSDASHBOARD = 'AKSDASHBOARD',
    PTSMANAGERYC = 'PTSMANAGERYC',
    UITEMPLATES = 'UITEMPLATES',
}

export type LogoOptions = {
    fontSizeInPx?: number;
};

/**
 * Get a JSX.Element containing spans with the application name in Progress font.
 * @param appType application type
 * @returns span with the application name in Progress font
 */
export const getAppLogo = (appType: ApplicationType, options?: LogoOptions): JSX.Element => {
    const fontSizeInPx = options?.fontSizeInPx ?? 28;

    switch (appType) {
        case ApplicationType.ADMINCENTERYC:
            return <AdminCenterYCLogo fontSizeInPx={fontSizeInPx} />;
        case ApplicationType.AHEADAPS:
            return <AheadAPSLogo fontSizeInPx={fontSizeInPx} />;
        case ApplicationType.AVICADYC:
            return <AvicadYCLogo fontSizeInPx={fontSizeInPx} />;
        case ApplicationType.BIMPRO:
            return <BimProLogo fontSizeInPx={fontSizeInPx} />;
        case ApplicationType.CUSTOMERPORTAL:
            return <CustomerPortalLogo fontSizeInPx={fontSizeInPx} />;
        case ApplicationType.PROGRESSDASHBOARD:
            return <ProgressDashboardLogo fontSizeInPx={fontSizeInPx} />;
        case ApplicationType.EBOSYC:
            return <EbosYCLogo fontSizeInPx={fontSizeInPx} />;
        case ApplicationType.EBOSPXML:
            return <EbosPXMLLogo fontSizeInPx={fontSizeInPx} />;
        case ApplicationType.ERPBOSPXML:
            return <ErpbosPXMLLogo fontSizeInPx={fontSizeInPx} />;
        case ApplicationType.INFORMATIONEXCHANGE:
            return <InformationExchangeLogo fontSizeInPx={fontSizeInPx} />;
        case ApplicationType.MEBOSYC:
            return <MebosYCLogo fontSizeInPx={fontSizeInPx} />;
        case ApplicationType.MEBOSYCAPI:
            return <MebosYCAPILogo fontSizeInPx={fontSizeInPx} />;
        case ApplicationType.MEBOSYCWEBAPP:
            return <MebosYCWebAppLogo fontSizeInPx={fontSizeInPx} />;
        case ApplicationType.PROFITPXML:
            return <ProfitPXMlLogoLogo fontSizeInPx={fontSizeInPx} />;
        case ApplicationType.PROFITYC:
            return <ProfitYCLogo fontSizeInPx={fontSizeInPx} />;
        case ApplicationType.PROGRESSINSIGHTS:
            return <ProgressInsightsLogo fontSizeInPx={fontSizeInPx} />;
        case ApplicationType.STABOSYC:
            return <StabosYCLogo fontSizeInPx={fontSizeInPx} />;
        case ApplicationType.STABOSYCV2:
            return <StabosYCv2Logo fontSizeInPx={fontSizeInPx} />;
        case ApplicationType.STACKPLANYC:
            return <StackPlanYCLogo fontSizeInPx={fontSizeInPx} />;
        case ApplicationType.STEELBOS:
            return <SteelbosLogo fontSizeInPx={fontSizeInPx} />;
        case ApplicationType.VISION:
            return <VisionLogo fontSizeInPx={fontSizeInPx} />;
        case ApplicationType.AKSDASHBOARD:
            return <AKSDashboardLogo fontSizeInPx={fontSizeInPx} />;
        case ApplicationType.ADVANCEDPLANNINGSERVICE:
            return <AdvancedPlanningServiceLogo fontSizeInPx={fontSizeInPx} />;
        case ApplicationType.PTSMANAGERYC:
            return <PTSManagerYCLogo fontSizeInPx={fontSizeInPx} />;
        case ApplicationType.UITEMPLATES:
            return <UITemplatesLogo fontSizeInPx={fontSizeInPx} />;
        default:
            return <></>;
    }
};
