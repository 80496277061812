import { Deployment } from '../../../definitions/autogenerated/types';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../configureStore';
import { fetchProgressApps } from './actions';

export const progressAppsAdapter = createEntityAdapter<Deployment>({
    selectId: (app) => app.AppName,
});

export const progressAppsSlice = createSlice({
    name: 'progressApps',
    initialState: progressAppsAdapter.getInitialState(),
    reducers: {
        setApps: progressAppsAdapter.upsertMany,
        removeAllApps: progressAppsAdapter.removeAll,
    },
    extraReducers: (builder) => {
        builder.addCase(fetchProgressApps.fulfilled, (state, action) => {
            progressAppsAdapter.upsertMany(state, action.payload);
        });
    },
});

export const { setApps, removeAllApps } = progressAppsSlice.actions;
export const progressAppsSelectors = progressAppsAdapter.getSelectors<RootState>((state) => state.data.progressApps);
