import * as d3 from 'd3';
import { fabric } from 'fabric';
import { EditableProperty } from './objects';

export const loadImage = (base64: string) => {
    return new Promise<HTMLImageElement>((resolve, reject) => {
        const image = document.createElement('img');

        image.onerror = function (e) {
            image.onload = image.onerror = null;
            reject(e);
        };

        image.onload = function () {
            image.onload = image.onerror = null;
            resolve(image);
        };

        image.src = base64;
    });
};

export class Image extends fabric.Image {
    controlType = 'Image';
    isDirty = false;

    static async fromDataURL(base64: string, options: fabric.IImageOptions) {
        const img = await loadImage(base64);
        const image = new Image(img, options);
        return image;
    }

    constructor(imageElement: HTMLImageElement, options: fabric.IImageOptions) {
        options.objectCaching = false;
        super(imageElement, {
            ...options,
            // overrides
            ...{
                objectCaching: false,
                strokeUniform: true,
                cornerColor: 'black',
                borderColor: 'black',
            },
        });

        this.setControlsVisibility({ mtr: false });
    }

    getProperties(): EditableProperty[] {
        return [
            { propertyName: 'left', displayName: 'X', type: 'number' },
            { propertyName: 'top', displayName: 'Y', type: 'number' },
            { propertyName: 'scaleX', displayName: 'Scale X', type: 'number' },
            { propertyName: 'scaleY', displayName: 'Scale Y', type: 'number' },
        ];
    }

    update<K extends keyof this>(propertyName: K, value: this[K] | ((value: this[K]) => this[K])): fabric.Object {
        this.set(propertyName, value);
        this.set('dirty', true);
        this.isDirty = true;
        this.canvas?.fire('object:modified');
        return this;
    }

    serialize() {
        const top = this.top ?? 0;
        const left = this.left ?? 0;

        const base = {
            type: this.controlType,
            width: this.width,
            height: this.height,
            dataUri: this.getSrc(),
            scaleX: this.scaleX,
            scaleY: this.scaleY,
        };

        if (this.group) {
            const groupTop = this.group.top ?? 0;
            const groupLeft = this.group.left ?? 0;
            const groupHeight = this.group.height ?? 0;
            const groupWidth = this.group.width ?? 0;

            return {
                ...base,
                top: top + groupTop + groupHeight / 2,
                left: left + groupLeft + groupWidth / 2,
            };
        } else {
            return {
                ...base,
                top: top,
                left: left,
            };
        }
    }

    renderToSVG(svgElement: SVGSVGElement) {
        d3.select(svgElement)
            .append('g')
            .append('image')
            .attr('x', this.left ?? 0)
            .attr('y', this.top ?? 0)
            .attr('width', (this.width ?? 0) * (this.scaleX ?? 1))
            .attr('height', (this.height ?? 0) * (this.scaleY ?? 1))
            .attr('href', this.getSrc())
            // this allows the image to be stretched
            .attr('preserveAspectRatio', 'none');

        return svgElement;
    }
}
