import * as d3 from 'd3';
import { fabric } from 'fabric';
import { EditableProperty } from './objects';

// TODO: improve point movements: http://fabricjs.com/stickman
export class Line extends fabric.Line {
    controlType = 'Line';
    isDirty = false;

    constructor(points: number[], options?: fabric.ILineOptions) {
        super(points, {
            // defaults
            ...{
                stroke: '#000',
                strokeWidth: 1,
            },
            ...options,
            // overrides
            ...{
                strokeUniform: true,
                objectCaching: false,
                cornerColor: 'black',
                borderColor: 'black',
                padding: 10,
            },
        });

        this.setControlsVisibility({ mtr: false });

        this.on('scaling', () => {
            const w = (this.width ?? 0) * (this.scaleX ?? 0);
            const h = (this.height ?? 0) * (this.scaleY ?? 0);
            this.height = h;
            this.width = w;
            this.scaleX = 1;
            this.scaleY = 1;
        });
    }

    update<K extends keyof this>(propertyName: K, value: this[K] | ((value: this[K]) => this[K])): fabric.Object {
        this.set(propertyName, value);
        this.set('dirty', true);
        this.isDirty = true;
        this.canvas?.fire('object:modified');
        return this;
    }

    getProperties(): EditableProperty[] {
        return [
            { propertyName: 'left', displayName: 'X', type: 'number' },
            { propertyName: 'top', displayName: 'Y', type: 'number' },
            { propertyName: 'width', displayName: 'Width', type: 'number' },
            { propertyName: 'height', displayName: 'Height', type: 'number' },
            { propertyName: 'stroke', displayName: 'Stroke color', type: 'color' },
            { propertyName: 'strokeWidth', displayName: 'Stroke width', type: 'number' },
        ];
    }

    serialize() {
        const top = this.top ?? 0;
        const left = this.left ?? 0;

        const base = {
            type: this.controlType,
            stroke: this.stroke,
            strokeWidth: this.strokeWidth,
            width: this.width,
            height: this.height,
        };

        if (this.group) {
            const groupTop = this.group.top ?? 0;
            const groupLeft = this.group.left ?? 0;
            const groupHeight = this.group.height ?? 0;
            const groupWidth = this.group.width ?? 0;
            return {
                ...base,
                top: top + groupTop + groupHeight / 2,
                left: left + groupLeft + groupWidth / 2,
            };
        } else {
            return {
                ...base,
                left: left,
                top: top,
            };
        }
    }

    renderToSVG(svgElement: SVGSVGElement) {
        d3.select(svgElement)
            .append('line')
            .attr('x1', this.x1 ?? 0)
            .attr('y1', this.y1 ?? 0)
            .attr('x2', this.x2 ?? 0)
            .attr('y2', this.y2 ?? 0)
            .style('stroke', this.stroke ?? '#fff')
            .style('stroke-width', this.strokeWidth ?? 1);

        return svgElement;
    }
}
