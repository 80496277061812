import { ProgressDashboardRoles } from '../definitions/autogenerated/types';

export class AuthenticationUtils {
    static hasAppManagementPermission = () => {
        return window.userInfo.Roles.includes(ProgressDashboardRoles.APP_MANAGEMENT_PERMISSION);
    };
    static hasConfigAdminPermission = () => {
        return window.userInfo.Roles.includes(ProgressDashboardRoles.CONFIG_ADMIN_PERMISSION);
    };
    static hasProgressDashboardUpdatePermission = () => {
        return window.userInfo.Roles.includes(ProgressDashboardRoles.PROGRESS_DASHBOARD_UPATE_PERMISSION);
    };
    static hasManageProgressAdminsPermissions = () => {
        return window.userInfo.Roles.includes(ProgressDashboardRoles.MANAGE_PROGRESS_ADMINS_PERMISSIONs);
    };
    static hasLicenseCheckPermissions = () => {
        return window.userInfo.Roles.includes(ProgressDashboardRoles.LICENSE_CHECK_PERMISSION);
    };
}
