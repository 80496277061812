import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, CircularProgress, Tab, Typography } from '@mui/material';
import { TOptions } from '@progress/base-ui';
import axios from 'axios';
import { getTime } from 'date-fns';
import { useSnackbar } from 'notistack';
import React from 'react';
import { DeploymentInformationVisualizer } from './components/DeploymentInformationVisualizer';
import { registerTranslations, useTypedTranslation } from './definitions/languages';
import { AboutInfo } from './definitions/autogenerated/types';

registerTranslations();

export const AboutPage = (props: { apiPath?: string }) => {
    const { t, tp } = useTypedTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [tabValue, setTabValue] = React.useState('1');
    const [about, setAbout] = React.useState<AboutInfo>();
    const [lastFetchTS, setLastFetchTS] = React.useState(0);

    const path = React.useMemo(() => `${props.apiPath ?? '.'}/api/AboutPage/about`, [props.apiPath]);

    React.useEffect(() => {
        axios
            .get<AboutInfo>(path)
            .then((r) => {
                setLastFetchTS(getTime(new Date()));
                setAbout(r.data);
            })
            .catchError((e) => {
                console.error(e);
                enqueueSnackbar(tp({ description: e.Description } as TOptions, 'about', 'loadError'), {
                    variant: 'error',
                    anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
                });
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = React.useCallback((event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    }, []);

    const hasBackend = !!about?.Backend;

    return (
        <>
            <Typography
                variant="h4"
                component="h4"
                gutterBottom
                color="primary"
            >
                {t('about', 'title')}
            </Typography>

            {!about && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexGrow: 1,
                        alignItems: 'center',
                        width: '100%',
                        minHeight: 250,
                    }}
                >
                    <CircularProgress size={75} />
                </div>
            )}

            {!!about && (
                <TabContext value={tabValue}>
                    {hasBackend && (
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', pb: 1 }}>
                            <TabList onChange={handleChange}>
                                <Tab
                                    label={t('about', 'webServer')}
                                    value="1"
                                />
                                <Tab
                                    label={t('about', 'backend')}
                                    value="2"
                                />
                            </TabList>
                        </Box>
                    )}

                    {/* WebService Info */}
                    <TabPanel
                        value="1"
                        sx={{ p: 0 }}
                    >
                        <DeploymentInformationVisualizer
                            lastFetchTS={lastFetchTS}
                            info={about.WebServer}
                        />
                    </TabPanel>

                    {/* Backend Info */}
                    <TabPanel
                        value="2"
                        sx={{ p: 0 }}
                    >
                        {!!about.Backend && (
                            <DeploymentInformationVisualizer
                                lastFetchTS={lastFetchTS}
                                info={about.Backend}
                            />
                        )}
                    </TabPanel>
                </TabContext>
            )}
        </>
    );
};
