import { useMemo } from 'react';
import { Log } from '../utils/debug';
import { PDFPageRotation } from '../hooks/usePdfConverter';
import { arrayChunk } from '../utils/arrayUtils';
import { PDFHtmlElementSize, PDFPageFit } from './pdfViewerTypes';

/**
 * Returns the size of the pdf page when displayed in a grid to fit the container
 *
 * @param contentSize The size of the pdf page at scale 1
 * @param containerSize The size of the whole pdf viewer container
 * @param pagesPerRow how many pdf pages should be displayed in a row
 * @param pageGap the gap between the pdf pages
 * @param contentFit fit the content to 'width' or 'height'
 * @returns the size of the pdf page when displayed in a grid to fit the container
 */
function getScaledPageSize(
    contentSize: PDFHtmlElementSize,
    containerSize: PDFHtmlElementSize,
    pagesPerRow: number,
    pageGap: number,
    contentFit: PDFPageFit
) {
    Log.debug('getScaledPageSize', { contentSize, containerSize, pagesPerRow, pageGap, contentFit });
    const aspectRatio = contentSize.width / contentSize.height;
    let height = contentSize.height,
        width = contentSize.width;
    const contentWrapperWidth = (containerSize.width - pageGap * (pagesPerRow - 1)) / pagesPerRow;
    if (contentFit === 'width') {
        width = contentWrapperWidth;
        height = width / aspectRatio;
    } else if (contentFit === 'height') {
        height = containerSize.height;
        width = height * aspectRatio;
        if (width > contentWrapperWidth) {
            width = contentWrapperWidth;
            height = width / aspectRatio;
        }
    }
    //'custom' pagefit
    return { width: Math.floor(width), height: Math.floor(height) };
}

/**
 * Calculates the size of the pages wrapper based on the content sizes, container size, pages per row, and gap between pages.
 *
 * @param pagesContentSizes - Array of page content sizes
 * @param containerSize - The size of the container
 * @param pagesPerRow - Number of pages per row
 * @param pageGap - Gap between pages
 * @returns The size of the pages wrapper
 */
function getPagesWrapperSize(pagesContentSizes: PDFPageContentSize[], pagesPerRow: number, pageGap: number) {
    const pageRows = arrayChunk(pagesContentSizes, pagesPerRow); // Chunk pages into rows
    const height =
        pageRows.reduce((totalHeight, row) => {
            const rowHeight = row.reduce((acc, cur) => Math.max(acc, cur.scaledSize.height), 0); // get max height of the row
            return totalHeight + rowHeight; // Add row height to total height
        }, 0) +
        (pageRows.length - 1) * pageGap; // Add gaps between rows
    const width = pageRows.reduce((maxWidth, row) => {
        const rowWidth = row.reduce((totalWidth, page, index) => {
            const pageWidth = page.scaledSize.width; // Width of the current page
            const gap = index < row.length - 1 ? pageGap : 0; // Add gap if it's not the last page in the row
            return totalWidth + pageWidth + gap; // Add page width and gap to the total row width
        }, 0);
        return Math.max(maxWidth, rowWidth); // Determine the maximum width across all rows
    }, 0);
    return {
        width,
        height,
    };
}

type UsePDFPageSizesProps = {
    /** the size of the whole container of the pdf viewer */
    containerSize: PDFHtmlElementSize;
    /** the size of the pdf page at scale 1 */
    unscaledPageSizes: PDFHtmlElementSize[];
    pagesPerRow: number;
    pageGap: number;
    pageFit: PDFPageFit;
    pageRotation: PDFPageRotation;
    onlyShowPage?: number;
};

export type PDFPageContentSize = {
    /** the index of the page in the pdf document */
    index: number;
    /** the real size of the pdf page at scale 1 */
    unscaledSize: PDFHtmlElementSize;
    /** the size of the pdf page when fitting in the container */
    scaledSize: PDFHtmlElementSize;
};

export const usePDFPageSizes = ({
    containerSize,
    unscaledPageSizes,
    pagesPerRow,
    pageGap,
    pageFit,
    pageRotation,
    onlyShowPage,
}: UsePDFPageSizesProps) => {
    const pagesContentSizes = useMemo((): PDFPageContentSize[] => {
        return (
            unscaledPageSizes
                /** for page rotation, we flip content sizes width/height. This will cause a re-render on the cells and force to clear the cells (size is used in the cell key) */
                .map((unscaledSize) => {
                    return pageRotation === 90 || pageRotation === 270
                        ? { width: unscaledSize.height, height: unscaledSize.width }
                        : unscaledSize;
                })
                /** for each page, we get the size of the page when displayed in a grid to fit the container */
                .map((unscaledSize, index) => ({
                    index,
                    unscaledSize,
                    scaledSize:
                        pageFit === 'custom'
                            ? unscaledSize //with custom page fit, we just use the unscaled size
                            : getScaledPageSize(
                                  unscaledSize,
                                  containerSize,
                                  onlyShowPage ? 1 : Math.min(unscaledPageSizes.length, pagesPerRow),
                                  pageGap,
                                  pageFit
                              ),
                }))
                //ATTENTION "onlyShowPage" must be checked in the first place to not exceed the available pages from pdfDocumentInfo
                .filter((_, index) => onlyShowPage === undefined || onlyShowPage === index)
        ); // only show the selected page.
    }, [containerSize, unscaledPageSizes, pagesPerRow, pageGap, pageFit, pageRotation, onlyShowPage]);

    const pagesWrapperSize = useMemo(() => {
        return getPagesWrapperSize(pagesContentSizes, pagesPerRow, pageGap);
    }, [pagesContentSizes, pagesPerRow, pageGap]);

    return {
        pagesWrapperSize,
        pagesContentSizes,
    };
};
