import { LoadingButton } from '@mui/lab';
import { ButtonProps, LinearProgress } from '@mui/material';
import { useEffect, useState } from 'react';

export type AutoRefreshButtonProps = ButtonProps & {
    onRefresh(): void;
    refreshInterval: number;
    loading: boolean;
};

/**
 * Refresh of a component after a given interval
 * @param props as {@link AutoRefreshButtonProps}
 */
export const AutoRefreshButton = (props: AutoRefreshButtonProps) => {
    const { onRefresh, refreshInterval, loading, children, disabled, ...rest } = props;

    const [progress, setProgress] = useState(0);

    useEffect(() => {
        let timer: NodeJS.Timeout | null = null;
        if (!loading && !disabled) {
            timer = setInterval(() => {
                let shouldRefresh = false;
                setProgress((oldProgress) => {
                    if (oldProgress >= 100) {
                        shouldRefresh = true;
                        return 0;
                    }
                    const diff = (100 / refreshInterval) * 1000;
                    return Math.min(oldProgress + diff, 100);
                });
                if (shouldRefresh) {
                    onRefresh();
                }
            }, 1000);
        }

        return () => {
            if (timer) {
                clearInterval(timer);
            }
        };
    }, [loading, onRefresh, refreshInterval, disabled]);

    return (
        <>
            <LoadingButton
                loading={loading}
                {...rest}
                style={{ position: 'relative', minWidth: '100px' }}
            >
                {children}
                <div style={{ width: '100%', position: 'absolute', bottom: 0, left: 0, right: 0 }}>
                    <LinearProgress
                        variant="determinate"
                        value={progress}
                    />
                </div>
            </LoadingButton>
        </>
    );
};
