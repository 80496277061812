import {
    addBaseUIResources,
    applyMUIGridLicense,
    initLogging,
    ProgressApp,
    ProgressThemeProvider,
    ProgressWindow,
} from '@progress/base-ui';
import axios from 'axios';
import React from 'react';
import App from './App';
import { initI18Next } from './definitions/languages';
import { store } from './store/configureStore';
import { WebUserInfo } from './definitions/autogenerated/types';
import ProgressBaseProvider from '@progress/base-ui/src/components/ProgressBaseProvider';

// TODO: remove this after migration to react 18
declare module 'react' {
    interface HTMLAttributes<T> {
        /**
         * @deprecated This does only work in react 18!!
         */
        onResize?: React.ReactEventHandler<T> | undefined;
        /**
         * @deprecated This does only work in react 18!!
         */
        onResizeCapture?: React.ReactEventHandler<T> | undefined;
    }
}

// https://github.com/iamhosseindhv/notistack
// import { IUser } from './store/auth/types';

axios.defaults.withCredentials = true;

// export interface IUser {
//   access_token: string
//   email: string
//   name: string
//   firstName: string
//   lastName: string
//   id: string
//   roles: string[]
//   licenses: string[]
// }

declare global {
    interface Window extends ProgressWindow {
        userInfo: WebUserInfo;
    }
}
const userInfo = window.userInfo;

initI18Next();
addBaseUIResources();
applyMUIGridLicense();
initLogging();

ProgressApp.conditionalRender(
    userInfo !== undefined,
    <ProgressBaseProvider store={store}>
        <ProgressThemeProvider>
            <App />
        </ProgressThemeProvider>
    </ProgressBaseProvider>
);
