import { css } from '@emotion/react';

const styles = css`
    // The expand icon is not centered vertically when density is "compact"
    [data-field='__detail_panel_toggle__'] {
        display: flex;
        background: #f2f2f2;

        > button.Mui-disabled {
            display: none;
        }
    }

    // positioning of the grouping column cell: otherwise the grouping cell is cut off at the first/grouping column end
    .MuiDataGrid-groupingCriteriaCell {
        position: absolute;
    }

    &.MuiDataGrid-root {
        border-radius: 0;
        margin-bottom: 0.5rem;
        &.MuiDataGrid-root--densityCompact {
            font-size: 12px;
        }
    }

    // center the grouping expand/collapse icon when density is set to compact
    &.MuiDataGrid-root--densityCompact .MuiDataGrid-groupingCriteriaCellToggle > button {
        padding-top: 0;
        padding-bottom: 0;
    }

    .MuiDataGrid-scrollbar {
        z-index: 1;
    }

    .MuiDataGrid-columnHeader,
    .MuiDataGrid-columnHeaders > div,
    .MuiDataGrid-pinnedColumnHeaders {
        background-color: #f2f2f2 !important;
    }
    .MuiDataGrid-pinnedColumnHeaders {
        box-shadow: none;
    }
    .MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
    .MuiDataGrid-root .MuiDataGrid-cell:focus {
        outline: none;
    }
    .MuiDataGrid-filler--pinnedRight,
    .MuiDataGrid-cell--pinnedRight {
        background-color: #f2f2f2;
        box-shadow: none;
        border-left: none;
        border-right: none;
    }
    .MuiDataGrid-filler--pinnedLeft,
    .MuiDataGrid-cell--pinnedLeft {
        background-color: #f2f2f2;
        box-shadow: none;
        border-left: none;
        border-right: none;
    }
    .MuiLinearProgress-root {
        z-index: 1000;
    }

    &.MuiDataGrid-root--densityCompact {
    }
`;
export default styles;
