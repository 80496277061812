import { defaultGridProps, minWidthColumnProps } from '@/components/CustomGridComponents';
import { useTypedTranslation } from '@/definitions';
import { commands } from '@/definitions/autogenerated/commands';
import { DtoERPbosLicenseCheck } from '@/definitions/autogenerated/types';
import { FileDownload, Send } from '@mui/icons-material';
import { Box, Button, Chip, Container, Paper, styled } from '@mui/material';
import {
    GridColDef,
    GridGroupNode,
    GridInitialState,
    GridSlotsComponent,
    GridSortModel,
    GridToolbarContainer,
    GridToolbarQuickFilter,
    useGridApiContext,
    useOnMount,
} from '@mui/x-data-grid-premium';
import { CustomDateTimePicker, DataGridBase, Logger, nameof } from '@progress/base-ui';
import { getMuiDataGridTranslations } from '@progress/base-ui/src/definitions/languages';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo, useState } from 'react';

type DateHolder = {
    rawValue: DateTime;
    formattedValue: string;
};

type DtoERPbosLicenseCheckRow = Omit<DtoERPbosLicenseCheck, 'CreatedAt'> & {
    CreatedAt: DateHolder;
};

const LicenseChecks: React.FC = () => {
    const { language } = useTypedTranslation();

    const messages = useMemo(() => getMuiDataGridTranslations(language), [language]);
    const { enqueueSnackbar } = useSnackbar();

    const [isLoading, setLoading] = useState(false);
    const [rows, setRows] = useState<DtoERPbosLicenseCheck[]>([]);

    const displayRows = useMemo<DtoERPbosLicenseCheckRow[]>(() => {
        return rows.map((row) => {
            const { CreatedAt, ...rest } = row;

            const dateTime = DateTime.fromISO(CreatedAt);

            return {
                ...rest,
                CreatedAt: {
                    rawValue: dateTime,
                    formattedValue: dateTime.toFormat('D T'),
                },
            };
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rows, language]);

    const [filterStart, setFilterStart] = useState<DateTime | null>(DateTime.now().startOf('day').minus({ days: 30 }));
    const [filterEnd, setFilterEnd] = useState<DateTime | null>(DateTime.now().startOf('day').plus({ days: 1 }));

    const loadData = useCallback(() => {
        setLoading(true);
        commands
            .LoadERPbosLicenseChecks({ StartTimeStamp: filterStart!.toISO(), EndTimeStamp: filterEnd!.toISO() })
            .then((result) => {
                setRows(result.Result);
            })
            .catchError((e) => {
                enqueueSnackbar(`Error: ${e.Description}`, { variant: 'error' });
                Logger.error(e.Description);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [filterStart, filterEnd, enqueueSnackbar]);

    useOnMount(() => {
        loadData();
    });

    const columns = useMemo<GridColDef<DtoERPbosLicenseCheckRow>[]>(
        () => [
            {
                minWidth: 75,
                width: 100,
                maxWidth: 150,
                field: nameof<DtoERPbosLicenseCheckRow>('Id'),
                headerName: 'Id',
            },
            {
                ...minWidthColumnProps,
                width: 225,
                field: nameof<DtoERPbosLicenseCheckRow>('ServiceName'),
                headerName: 'ServiceName',
            },
            {
                ...minWidthColumnProps,
                width: 350,
                field: nameof<DtoERPbosLicenseCheckRow>('MachineName'),
                headerName: 'MachineName',
            },
            {
                ...minWidthColumnProps,
                width: 225,
                field: nameof<DtoERPbosLicenseCheckRow>('UserName'),
                headerName: 'UserName',
            },
            {
                ...minWidthColumnProps,
                width: 500,
                field: nameof<DtoERPbosLicenseCheckRow>('PublicUrl'),
                headerName: 'PublicUrl',
            },
            {
                ...minWidthColumnProps,
                flex: undefined,
                width: 200,
                field: nameof<DtoERPbosLicenseCheckRow>('CreatedAt'),
                headerName: 'CreatedAt',
                valueGetter: (_, row: DtoERPbosLicenseCheckRow, __, apiRef) => {
                    // row can be autogenerated when grouping is enabled
                    const rowId = apiRef.current.getRowId(row);
                    const node = apiRef.current.getRowNode<GridGroupNode>(rowId);
                    if (!node || node.isAutoGenerated) {
                        return;
                    }
                    return row.CreatedAt.rawValue;
                },
                groupingValueGetter: (_, row) => {
                    return row.CreatedAt.formattedValue;
                },
                valueFormatter: (_, row: DtoERPbosLicenseCheckRow, __, apiRef) => {
                    // row can be autogenerated when grouping is enabled
                    const rowId = apiRef.current.getRowId(row);
                    const node = apiRef.current.getRowNode<GridGroupNode>(rowId);
                    if (!node || node.isAutoGenerated) {
                        return;
                    }
                    return row.CreatedAt.formattedValue;
                },
            },
        ],
        []
    );

    return (
        <StyledContainer maxWidth="lg">
            <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                <div style={divStyleHead}>
                    <Box
                        m={1}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-end"
                    >
                        <h1 className="containerTitle">License Checks</h1>
                    </Box>
                </div>

                <div style={{ display: 'flex', gap: '10px' }}>
                    <CustomDateTimePicker
                        sx={{ m: 0, width: '200px' }}
                        label="From"
                        value={filterStart!}
                        maxDate={DateTime.now().plus({ days: 1 })}
                        onChange={setFilterStart}
                    />
                    <CustomDateTimePicker
                        sx={{ m: 0, width: '200px' }}
                        label="To"
                        value={filterEnd!}
                        maxDate={DateTime.now().plus({ days: 1 })}
                        onChange={setFilterEnd}
                    />
                    <Button
                        variant="contained"
                        size="small"
                        sx={{ marginLeft: '10px', height: '40px' }}
                        onClick={loadData}
                    >
                        <Send />
                    </Button>
                </div>

                <Paper
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'relative',
                        flexGrow: 1,
                        height: '1px',
                        marginTop: '8px',
                    }}
                >
                    <DataGridBase
                        {...defaultGridProps}
                        rows={displayRows}
                        columns={columns}
                        initialState={initialState}
                        getRowId={getRowId}
                        localeText={messages}
                        slots={slots}
                        loading={isLoading}
                        disableAggregation
                    />
                </Paper>
            </div>
        </StyledContainer>
    );
};

export default LicenseChecks;

const StyledContainer = styled(Container)(({ theme }) => ({
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    maxWidth: '2100px !important',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
}));

const divStyleHead: React.CSSProperties = {
    display: 'inline-block',
    width: '100%',
    alignItems: 'relative',
    margin: '1',
};

const defaultSortModel: GridSortModel = [
    { field: '__row_group_by_columns_group__', sort: 'asc' },
    { field: nameof<DtoERPbosLicenseCheck>('CreatedAt'), sort: 'asc' },
    { field: 'mobile', sort: 'asc' },
];

const initialState: GridInitialState = {
    sorting: {
        sortModel: defaultSortModel,
    },
};

const getRowId = (row: DtoERPbosLicenseCheck) => row.Id;

const CustomToolbar = () => {
    const apiRef = useGridApiContext();

    const exportAsExcel = useCallback(() => {
        const allColumns = apiRef.current.getAllColumns();
        const filteredColumns = allColumns.filter((c) => !c.field.startsWith('__row_group_by')).map((c) => c.field);
        apiRef.current.exportDataAsExcel({
            fields: filteredColumns,
            fileName: 'ProgressDashboard_LicenseChecks',
        });
    }, [apiRef]);

    const groupByServiceName = useCallback(() => {
        if (!apiRef.current.state.rowGrouping.model.includes('ServiceName')) {
            apiRef.current.setRowGroupingModel(['ServiceName']);
        } else {
            apiRef.current.setRowGroupingModel([]);
        }
    }, [apiRef]);

    return (
        <GridToolbarContainer sx={{ padding: '8px' }}>
            Group by:
            <Chip
                label="ServiceName"
                onClick={groupByServiceName}
                variant="filled"
                style={{ marginLeft: 10 }}
            />
            <div style={{ flexGrow: 1 }}></div>
            <GridToolbarQuickFilter
                autoComplete="off"
                variant="outlined"
                size="small"
                sx={{ p: 0 }}
            />
            {/* <div style={{ : 1 }}></div> */}
            <Button
                onClick={exportAsExcel}
                variant="outlined"
                size="small"
                startIcon={<FileDownload />}
            >
                Excel Export
            </Button>
        </GridToolbarContainer>
    );
};

const slots: Partial<GridSlotsComponent> = {
    toolbar: CustomToolbar,
};
