import { PropsWithChildren } from 'react';

export type SplitterPosition = {
    top: number;
    left: number;
};

/**
 * @param customClassName - class name that will be attached to the "splitter" conainer
 * @param splitterSize - size in px of the splitter bars. Defaults to 10px
 * @param vertical - split the view vertically (splitter is horizontal), defaults to false
 * @param percentage - use percentages as unit
 * @param primaryIndex - which of the 2 splitter panes should be the primary (only the secondary is resized, primary takes as much space as possible)
 * @param primaryMinSize - smallest width the primary splitter pane must occupy
 * @param secondaryInitialSize - initial size in pixels of the secondary (resizable) splitter pane after first load
 * @param primaryMinSize - smallest width the secondary splitter pane must occupy
 * @param secondaryCollapsible - show collapse / expand button on the secondary pane
 * @param secondaryUnmountCollapsed - unmount the secondary pane when it is collapsed (defaults to false)
 *
 */
export type SplitterProps = PropsWithChildren<{
    customClassName?: string;
    splitterSize?: number;
    vertical?: boolean;
    percentage?: boolean;
    primaryIndex?: 0 | 1;
    primaryMinSize?: number;
    primaryResponsiveSteps?: number[];
    secondaryInitialSize?: number;
    secondaryMinSize?: number;
    secondaryMaxSize?: number;
    secondaryCollapsible?: boolean;
    secondaryUnmountCollapsed?: boolean;
    secondaryResponsiveSteps?: number[];
    onDragStart?: () => void;
    onDragEnd?: () => void;
    onSecondaryPaneSizeChange?: (size: number) => void;
}>;

export type SplitterRef = {
    setSecondarySize: (value: number) => void;
    setSecondaryPaneCollapsed: (value: boolean) => void;
};

export function clearSelection() {
    // TODO -> check what the typing should be
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const doc = document as any;
    const documentBody = doc.body;
    if (documentBody.createTextRange) {
        // https://github.com/zesik/react-splitter-layout/issues/16
        // https://stackoverflow.com/questions/22914075/#37580789
        const range = documentBody.createTextRange();
        range.collapse();
        range.select();
    } else if (window.getSelection) {
        const selection = window.getSelection();
        if (selection) {
            if (selection.empty) {
                selection.empty();
            } else {
                selection.removeAllRanges();
            }
        }
    } else if (doc.selection) {
        doc.selection.empty();
    }
}

export function getSecondaryPaneSize(
    containerRect: DOMRect,
    splitterRect: DOMRect,
    clientPosition: SplitterPosition,
    offsetMouse: boolean,
    vertical: boolean,
    percentage: boolean,
    primaryIndex: number,
    primaryMinSize: number,
    secondaryMinSize: number,
    secondaryMaxSize?: number
) {
    let totalSize;
    let splitterSize;
    let offset;
    if (vertical) {
        totalSize = containerRect.height;
        splitterSize = splitterRect.height;
        offset = clientPosition.top - containerRect.top;
    } else {
        totalSize = containerRect.width;
        splitterSize = splitterRect.width;
        offset = clientPosition.left - containerRect.left;
    }
    if (offsetMouse) {
        offset -= splitterSize / 2;
    }
    if (offset < 0) {
        offset = 0;
    } else if (offset > totalSize - splitterSize) {
        offset = totalSize - splitterSize;
    }

    let secondaryPaneSize;
    if (primaryIndex === 1) {
        secondaryPaneSize = offset;
    } else {
        secondaryPaneSize = totalSize - splitterSize - offset;
    }
    let primaryPaneSize = totalSize - splitterSize - secondaryPaneSize;
    if (percentage) {
        secondaryPaneSize = (secondaryPaneSize * 100) / totalSize;
        primaryPaneSize = (primaryPaneSize * 100) / totalSize;
        splitterSize = (splitterSize * 100) / totalSize;
        totalSize = 100;
    }

    if (primaryPaneSize < primaryMinSize) {
        secondaryPaneSize = Math.max(secondaryPaneSize - (primaryMinSize - primaryPaneSize), 0);
    } else if (secondaryPaneSize < secondaryMinSize) {
        secondaryPaneSize = Math.min(totalSize - splitterSize - primaryMinSize, secondaryMinSize);
    } else if (secondaryMaxSize !== undefined && secondaryPaneSize > secondaryMaxSize) {
        secondaryPaneSize = Math.min(totalSize - splitterSize - primaryMinSize, secondaryMaxSize);
    }

    return secondaryPaneSize;
}
