import * as d3 from 'd3';
import { fabric } from 'fabric';
import { BaseComponent } from './baseComponent';
import { EditableProperty } from './objects';
import { Logger } from '@progress/base-ui';

export class RuntimeParamEditor extends BaseComponent {
    controlType = 'runtimeParamEditor';
    paramGroup: string | undefined;
    valueFormatter: string | undefined;
    paramName: string | undefined;
    paramType: string | undefined;
    textColor: string | undefined;
    fontSize: number;
    testValue: number | undefined;
    isDirty = false;

    constructor(
        options?: fabric.IRectOptions & {
            paramGroup?: string;
            valueFormatter?: string;
            paramName?: string;
            paramType?: string;
            textColor?: string;
            fontSize?: number;
        }
    ) {
        super({
            // defaults
            ...{
                width: 100,
                height: 50,
                fill: '#FFDD00',
                stroke: 'black',
                strokeWidth: 0.75,
                objectCaching: false,
                cornerColor: 'black',
                borderColor: 'black',
            },
            ...options,
        });

        this.fontSize = options?.fontSize ?? 12;
        this.paramGroup = options?.paramGroup;
        this.paramName = options?.paramName;
        this.paramType = options?.paramType;
        this.valueFormatter = options?.valueFormatter;
        this.textColor = options?.textColor ?? '#000000';
    }

    _render(ctx: CanvasRenderingContext2D) {
        super._render(ctx);
        ctx.font = this.fontSize + 'px sans-serif';
        ctx.fillStyle = this.textColor ?? 'black';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';

        let text: string | undefined = undefined;
        if (this.testValue && this.valueFormatter) {
            try {
                // eslint-disable-next-line no-eval
                const formatter = eval(this.valueFormatter) as (value: number) => string;
                text = formatter(this.testValue);
            } catch (err) {
                Logger.error('[valueFormatter] failed with', err);
                text = 'ERROR';
            }
        }

        ctx.fillText(text ?? 'RunTimeParamEditor', 0, 0);
    }

    update<K extends keyof this>(propertyName: K, value: this[K] | ((value: this[K]) => this[K])): fabric.Object {
        this.set(propertyName, value);
        this.set('dirty', true);
        this.isDirty = true;
        this.canvas?.fire('object:modified');
        return this;
    }

    getProperties(): EditableProperty[] {
        return [
            ...super.getProperties(),
            { propertyName: 'fontSize', displayName: 'Font size', type: 'number' },
            { propertyName: 'strokeWidth', displayName: 'Stroke width', type: 'number' },
            { propertyName: 'stroke', displayName: 'Stroke color', type: 'color' },
            { propertyName: 'textColor', displayName: 'Text color', type: 'color' },
            { propertyName: 'fill', displayName: 'Fill color', type: 'color' },
            { propertyName: 'paramGroup', displayName: 'ParamGroup', type: 'string' },
            { propertyName: 'paramName', displayName: 'ParamName', type: 'string' },
            { propertyName: 'valueFormatter', displayName: 'Value formatter', type: 'string' },
            { propertyName: 'paramType', displayName: 'ParamType', type: 'string[]', elements: ['int', 'string'] },
            { propertyName: 'testValue', displayName: 'Test value', type: 'number' },
        ];
    }

    serialize() {
        const top = this.top ?? 0;
        const left = this.left ?? 0;

        const base = {
            type: this.controlType,
            width: this.width ?? 0,
            height: this.height ?? 0,
            textColor: this.textColor,
            fontSize: this.fontSize,
            strokeWidth: this.strokeWidth,
            stroke: this.stroke,
            fill: this.fill,
            paramGroup: this.paramGroup ?? '',
            paramName: this.paramName ?? '',
            paramType: this.paramType ?? '',
            valueFormatter: this.valueFormatter ?? '',
        };

        if (this.group) {
            const groupTop = this.group.top ?? 0;
            const groupLeft = this.group.left ?? 0;
            const groupHeight = this.group.height ?? 0;
            const groupWidth = this.group.width ?? 0;

            return {
                ...base,
                top: top + groupTop + groupHeight / 2,
                left: left + groupLeft + groupWidth / 2,
            };
        } else {
            return {
                ...base,
                top: top,
                left: left,
            };
        }
    }

    renderToSVG(svgElement: SVGSVGElement) {
        d3.select(svgElement)
            .append('rect')
            .attr('x', this.left ?? 0)
            .attr('y', this.top ?? 0)
            .attr('width', this.width ?? 0)
            .attr('height', this.height ?? 0)
            .style('fill', (this.fill ?? '') as string)
            .attr('type', this.controlType)
            .attr('textColor', this.textColor ?? 'black')
            .attr('fontSize', this.fontSize)
            .style('stroke-width', this.strokeWidth ?? 1)
            .style('stroke', this.stroke ?? '#000')
            .attr('paramGroup', this.paramGroup ?? '')
            .attr('paramName', this.paramName ?? '')
            .attr('paramType', this.paramType ?? '')
            .attr('valueFormatter', this.valueFormatter ?? '');

        return svgElement;
    }
}
