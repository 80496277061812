import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PipelineDto } from '../../../definitions/autogenerated/types';

export type PipelineState = {
    pipelines: PipelineDto[];
};

const initialPipelinesState: PipelineState = { pipelines: [] };

export const pipelinesSlice = createSlice({
    name: 'pipelines',
    initialState: initialPipelinesState,
    reducers: {
        addNewElements(state, action: PayloadAction<PipelineDto[]>) {
            state.pipelines = action.payload;
        },
    },
});

export const pipelinesActions = pipelinesSlice.actions;
