import { api, useTypedTranslation } from '@/definitions';
import { useReduxDispatch, useTypedSelector } from '@/store/configureStore';
import {
    remoteFilesAPI,
    setLoading,
    setShowTreeExplorer,
    useGetRemoteFilesQuery,
} from '@/store/data/configSheet/configSheetSlice';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import FlipToBackIcon from '@mui/icons-material/FlipToBack';
import FlipToFrontIcon from '@mui/icons-material/FlipToFront';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import RedoIcon from '@mui/icons-material/Redo';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import {
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    Grid,
    TextField,
    Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import { fabric } from 'fabric';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { LayoutFileContent, LayoutFileUpdateRequest } from '../../../definitions/autogenerated/types';
import { withCanvasContext } from '../FabricContext';
import { addNativeFileHandle, copy, paste } from '../fabricUtils';
import { readFileAsDataURL } from '../fileHelpers';
import { LocFlag } from '../objects/locFlag';
import { MileStone } from '../objects/milestone';
import {
    CarouselLocation,
    DryChamberLocation,
    DryHourLegend,
    Image,
    Line,
    Rectangle,
    StockyardLocation,
    Text,
    Ellipse,
} from '../objects/objects';
import { PalletStackLabel } from '../objects/palletStackLabel';
import { PLCLabel } from '../objects/plcLabel';
import { RuntimeParamEditor } from '../objects/runtimeParamEditor';
import { RuntimeParamLabel } from '../objects/runtimeParamLabel';
import { TransportJobLabel } from '../objects/transportJobLabel';
import ChoiceButton, { Choice } from '@/components/ChoiceButton';
import { Logger } from '@progress/base-ui';

export const ConfigSheetToolbar = withCanvasContext(({ canvas }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { enqueueSnackbar } = useSnackbar();

    const selectedFile = useTypedSelector((store) => store.data.configSheet.selectedFile);
    const localFiles = useTypedSelector((store) => store.data.configSheet.localFiles);
    const loading = useTypedSelector((store) => store.data.configSheet.loading);
    const showTreeExplorer = useTypedSelector((store) => store.data.configSheet.showTreeExplorer);
    const dispatch = useReduxDispatch();

    const { tp } = useTypedTranslation();

    const { refetch, data } = useGetRemoteFilesQuery();

    const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const open = Boolean(anchorEl);

    const openLocalFile = useCallback(async () => {
        const pickerOpts = {
            types: [
                {
                    description: 'JSON',
                    accept: {
                        'application/json': ['.json'],
                    },
                },
            ],
            excludeAcceptAllOption: true,
            multiple: false,
        };

        const result = await window.showOpenFilePicker(pickerOpts);

        if (result && result.length) {
            addNativeFileHandle(canvas, result[0]);
        }
    }, [canvas]);

    const addLine = useCallback(() => {
        const line = new Line([20, 20, 220, 20], {});
        canvas.add(line);
        canvas.setActiveObject(line);
        canvas.renderAll();
        handleClose();
    }, [canvas, handleClose]);

    const addCarouselLocation = useCallback(() => {
        const carouselLocation = new CarouselLocation({ locationName: 'TP 1' });
        canvas.add(carouselLocation);
        canvas.setActiveObject(carouselLocation);
        canvas.renderAll();
        handleClose();
    }, [canvas, handleClose]);

    const addDrychamberLocation = useCallback(() => {
        const drychamberLocation = new DryChamberLocation({ locationName: 'LG 1|1' });
        canvas.add(drychamberLocation);
        canvas.setActiveObject(drychamberLocation);
        canvas.renderAll();
        handleClose();
    }, [canvas, handleClose]);

    const addStockyardLocation = useCallback(() => {
        const stockyardLocation = new StockyardLocation({ locationName: 'A 1' });
        canvas.add(stockyardLocation);
        canvas.setActiveObject(stockyardLocation);
        canvas.renderAll();
        handleClose();
    }, [canvas, handleClose]);

    const addLabel = useCallback(() => {
        const label = new Text({ text: 'Text', top: 10, left: 15 });
        canvas.add(label);
        canvas.setActiveObject(label);
        canvas.renderAll();
        handleClose();
    }, [canvas, handleClose]);

    const addRectangle = useCallback(() => {
        const rect = new Rectangle({});
        canvas.add(rect);
        canvas.setActiveObject(rect);
        canvas.renderAll();
        handleClose();
    }, [canvas, handleClose]);

    const addEllipse = useCallback(() => {
        const ellipse = new Ellipse({});
        canvas.add(ellipse);
        canvas.setActiveObject(ellipse);
        canvas.renderAll();
        handleClose();
    }, [canvas, handleClose]);

    const addDryHourLegend = useCallback(() => {
        const dryHourLegend = new DryHourLegend({});
        canvas.add(dryHourLegend);
        canvas.setActiveObject(dryHourLegend);
        canvas.renderAll();
        handleClose();
    }, [canvas, handleClose]);

    const addPLCLabel = useCallback(() => {
        const plcLabel = new PLCLabel({});
        canvas.add(plcLabel);
        canvas.setActiveObject(plcLabel);
        canvas.renderAll();
        handleClose();
    }, [canvas, handleClose]);

    const addPalletStackLabel = useCallback(() => {
        const palletStackLabel = new PalletStackLabel({});
        canvas.add(palletStackLabel);
        canvas.setActiveObject(palletStackLabel);
        canvas.renderAll();
        handleClose();
    }, [canvas, handleClose]);

    const addRunTimeParamLabel = useCallback(() => {
        const runtimeParamLabel = new RuntimeParamLabel({});
        canvas.add(runtimeParamLabel);
        canvas.setActiveObject(runtimeParamLabel);
        canvas.renderAll();
        handleClose();
    }, [canvas, handleClose]);

    const addRuntimeParamEditor = useCallback(() => {
        const runtimeParamEditor = new RuntimeParamEditor({});
        canvas.add(runtimeParamEditor);
        canvas.setActiveObject(runtimeParamEditor);
        canvas.renderAll();
        handleClose();
    }, [canvas, handleClose]);

    const addTransportJobLabel = useCallback(() => {
        const transportJobLabel = new TransportJobLabel({});
        canvas.add(transportJobLabel);
        canvas.setActiveObject(transportJobLabel);
        canvas.renderAll();
        handleClose();
    }, [canvas, handleClose]);

    const addLocFlag = useCallback(() => {
        const locFlag = new LocFlag({});
        canvas.add(locFlag);
        canvas.setActiveObject(locFlag);
        canvas.renderAll();
        handleClose();
    }, [canvas, handleClose]);

    const addMileStone = useCallback(() => {
        const mileStone = new MileStone({});
        canvas.add(mileStone);
        canvas.setActiveObject(mileStone);
        canvas.renderAll();
        handleClose();
    }, [canvas, handleClose]);

    const addImage = useCallback(async () => {
        const pickerOpts = {
            types: [
                {
                    description: 'Image',
                    accept: {
                        'image/*': ['.png', '.jpg', '.jpeg', '.svg'],
                    },
                },
            ],
            excludeAcceptAllOption: true,
            multiple: false,
        };

        const [fileHandle] = await window.showOpenFilePicker(pickerOpts);

        const file = (await fileHandle.getFile()) as File;

        const base64 = await readFileAsDataURL(file);
        const image = await Image.fromDataURL(base64, {});

        image.scaleToWidth(200, false);

        canvas.add(image);
        canvas.setActiveObject(image);
        canvas.renderAll();

        handleClose();
    }, [canvas, handleClose]);

    const clearCanvas = useCallback(() => {
        const bg = canvas.backgroundColor;
        canvas.clear();
        canvas.backgroundColor = bg;
        canvas.renderAll();
    }, [canvas]);

    const handleCopy = useCallback(() => {
        copy(canvas);
    }, [canvas]);

    const handlePaste = useCallback(() => {
        paste(canvas);
    }, [canvas]);

    const deleteSelectedObject = useCallback(() => {
        const c = canvas;
        const objects: fabric.Object[] = [];
        if (c.getActiveObjects().length) {
            if (c.getActiveObjects().length > 0) {
                objects.push(...c.getActiveObjects());
                if (objects.length > 0) {
                    objects.forEach(function (o: fabric.Object) {
                        c.remove(o);
                        c.discardActiveObject();
                    });
                } else {
                    const aktivObject = c.getActiveObject();
                    if (aktivObject) {
                        c.remove(aktivObject);
                    }
                }
            }
        } else {
            Logger.debug('nothing to delete');
        }
    }, [canvas]);

    const sendSelectedObjectBack = useCallback(() => {
        canvas.getActiveObject()?.sendBackwards(true);
        canvas.renderAll();
    }, [canvas]);

    const bringSelectedObjectFront = useCallback(() => {
        canvas.getActiveObject()?.bringForward(true);
        canvas.renderAll();
    }, [canvas]);

    const zoomIn = useCallback(() => {
        canvas.zoomIn();
    }, [canvas]);

    const zoomOut = useCallback(() => {
        canvas.zoomOut();
    }, [canvas]);

    const serialize = useCallback(() => {
        const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(JSON.stringify(canvas.serialize()))}`;
        const link = document.createElement('a');
        link.href = jsonString;
        link.download =
            selectedFile.name.endsWith('.svg') || selectedFile.name.endsWith('.json')
                ? selectedFile.name.substring(0, selectedFile.name.lastIndexOf('.')) + '.json'
                : selectedFile.name + '.json';
        link.click();
    }, [canvas, selectedFile.name]);

    const downloadSVG = useCallback(async () => {
        const c = canvas;
        const svg = c.renderToSVG();

        const s = new XMLSerializer();
        const str = s.serializeToString(svg);
        const file = new Blob([str], {
            type: 'text/plain',
        });

        if (window.showSaveFilePicker) {
            // this doesnt exist in firefox!

            const fileHandle = await window.showSaveFilePicker({
                types: [
                    {
                        description: 'Image',
                        accept: {
                            'image/*': ['.png', '.jpg', '.jpeg', '.svg'],
                        },
                    },
                ],
                excludeAcceptAllOption: true,
                suggestedName:
                    selectedFile.name.endsWith('.svg') || selectedFile.name.endsWith('.json')
                        ? selectedFile.name.substring(0, selectedFile.name.lastIndexOf('.')) + '.svg'
                        : selectedFile.name + '.svg',
            });

            const fileStream = await fileHandle.createWritable();
            await fileStream.write(file);
            await fileStream.close();
        } else {
            const element = document.createElement('a');
            element.href = URL.createObjectURL(file);
            element.download =
                selectedFile.name.endsWith('.svg') || selectedFile.name.endsWith('.json')
                    ? selectedFile.name.substring(0, selectedFile.name.lastIndexOf('.')) + '.svg'
                    : selectedFile.name + '.svg';
            document.body.appendChild(element);
            element.click();
        }
    }, [canvas, selectedFile.name]);

    const handleUndo = useCallback(() => {
        canvas.undo();
    }, [canvas]);

    const handleRedo = useCallback(() => {
        canvas.redo();
    }, [canvas]);

    const save = useCallback(async () => {
        const updatedData = canvas.serialize();

        if (selectedFile.sourceType === 'playground') {
            dispatch(setLoading(true));
            // download
            const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(JSON.stringify(canvas.serialize(), null, 2))}`;
            const link = document.createElement('a');
            link.href = jsonString;
            link.download =
                selectedFile.name.endsWith('.svg') || selectedFile.name.endsWith('.json')
                    ? selectedFile.name.substring(0, selectedFile.name.lastIndexOf('.')) + '.json'
                    : selectedFile.name + '.json';
            link.click();
            canvas.acceptChanges();
            dispatch(setLoading(false));
        } else if (selectedFile.sourceType === 'localFile') {
            const file = localFiles.find((e) => e.name === selectedFile.name);
            if (file) {
                dispatch(setLoading(true));

                const permissions = await file.fileHandle.requestPermission({ mode: 'readwrite' });
                if (permissions === 'granted') {
                    const writable = await file.fileHandle.createWritable();
                    await writable.write(JSON.stringify(updatedData, null, 2));
                    await writable.close();
                    canvas.acceptChanges();
                    enqueueSnackbar('Layout saved...', { variant: 'success' });
                } else {
                    enqueueSnackbar(`Access to file '${file.name}' not granted`, { variant: 'error' });
                }
                dispatch(setLoading(false));
            }
        } else if (selectedFile.sourceType === 'remoteFile') {
            if (!data) return;

            dispatch(setLoading(true));

            const payload: LayoutFileUpdateRequest = {
                FileHash: canvas.fileHash,
                LayoutContent: JSON.stringify(updatedData),
                SvgContent: canvas.renderToSVG().outerHTML,
            };

            axios
                .put<LayoutFileContent>(api.configSheetRemoteFileById(selectedFile.name), payload)
                .then((response) => {
                    Logger.debug('Layout saved', response);

                    // invalidate tags
                    dispatch({
                        type: `${remoteFilesAPI.reducerPath}/invalidateTags`,
                        payload: ['RemoteFiles'],
                    });

                    canvas.fileHash = response.data.FileHash;

                    refetch();
                    canvas.acceptChanges();

                    enqueueSnackbar('Layout saved...', { variant: 'success' });
                })
                .catchError((e) => {
                    Logger.error('[Save] could not save layout', e.Description);
                    enqueueSnackbar('Could not save layout: ' + e.Description, { variant: 'error' });
                })
                .finally(() => {
                    dispatch(setLoading(false));
                });
        }
    }, [canvas, data, dispatch, enqueueSnackbar, localFiles, refetch, selectedFile.name, selectedFile.sourceType]);

    const toggleTreeExplorer = () => {
        dispatch(setShowTreeExplorer(!showTreeExplorer));
    };

    const sourceName = useMemo(() => {
        switch (selectedFile.sourceType) {
            case 'localFile':
                return '(local)';
            case 'remoteFile':
                return '(remote)';
            default:
                return '';
        }
    }, [selectedFile.sourceType]);

    const [generateDrychamberDialogOpen, setGenerateDrychamberDialogOpen] = useState(false);

    const options = useMemo<Choice[]>(
        () => [
            {
                displayName: tp({ format: 'SVG' }, 'main', 'exportToFormat'),
                action: downloadSVG,
                mainItem: true,
            },
            {
                displayName: tp({ format: 'JSON' }, 'main', 'exportToFormat'),
                action: serialize,
            },
        ],
        [downloadSVG, serialize, tp]
    );

    return (
        <div style={{ display: 'flex', borderBottom: 'solid 1px #333' }}>
            {loading && (
                <div
                    style={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        zIndex: 1000,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: '#4f60ef4f',
                    }}
                >
                    <CircularProgress
                        size={80}
                        disableShrink
                    />
                </div>
            )}

            <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={toggleTreeExplorer}
            >
                {showTreeExplorer ? <MenuOpenIcon /> : <MenuIcon />}
            </IconButton>

            <Button onClick={handleClick}>New Object</Button>

            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                <MenuItem onClick={addRectangle}>Rectangle</MenuItem>
                <MenuItem onClick={addEllipse}>Ellipse</MenuItem>
                <MenuItem onClick={addImage}>Image</MenuItem>
                <MenuItem onClick={addLine}>Line</MenuItem>
                <MenuItem onClick={addLabel}>Label</MenuItem>
                <MenuItem onClick={addCarouselLocation}>CarouselLocation</MenuItem>
                <MenuItem onClick={addDrychamberLocation}>DrychamberLocation</MenuItem>
                <MenuItem onClick={addStockyardLocation}>StockyardLocation</MenuItem>
                <MenuItem onClick={addDryHourLegend}>Dryhourlegend</MenuItem>
                <MenuItem onClick={addPLCLabel}>PLCLabel</MenuItem>
                <MenuItem onClick={addPalletStackLabel}>PalletStackLabel</MenuItem>
                <MenuItem onClick={addRunTimeParamLabel}>RunTimeParamLabel</MenuItem>
                <MenuItem onClick={addRuntimeParamEditor}>RuntimeParamEditor</MenuItem>
                <MenuItem onClick={addTransportJobLabel}>TransportJobLabel</MenuItem>
                <MenuItem onClick={addLocFlag}>LocFlag</MenuItem>
                <MenuItem onClick={addMileStone}>MileStone</MenuItem>
            </Menu>

            <Tooltip title="save">
                <span>
                    <IconButton
                        onClick={save}
                        disabled={selectedFile.sourceType === 'playground'}
                    >
                        <SaveIcon />
                    </IconButton>
                </span>
            </Tooltip>
            <Tooltip title="copy (CTRL c)">
                <IconButton onClick={handleCopy}>
                    <ContentCopyIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="paste (CTRL v)">
                <IconButton onClick={handlePaste}>
                    <ContentPasteIcon />
                </IconButton>
            </Tooltip>

            <Divider
                orientation="vertical"
                sx={{ mx: 1 }}
            />

            <Tooltip title="undo (CTRL z)">
                <IconButton onClick={handleUndo}>
                    <UndoIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="redo (CTRL y)">
                <IconButton onClick={handleRedo}>
                    <RedoIcon />
                </IconButton>
            </Tooltip>

            <Divider
                orientation="vertical"
                sx={{ mx: 1 }}
            />

            <Tooltip title="send front (f or CTRL f)">
                <IconButton onClick={bringSelectedObjectFront}>
                    <FlipToFrontIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="send back (b or CTRL b)">
                <IconButton onClick={sendSelectedObjectBack}>
                    <FlipToBackIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="delete active objects (DEL)">
                <IconButton onClick={deleteSelectedObject}>
                    <DeleteSweepIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="delete all objects">
                <IconButton onClick={clearCanvas}>
                    <DeleteIcon />
                </IconButton>
            </Tooltip>

            <Divider
                orientation="vertical"
                sx={{ mx: 1 }}
            />

            <Tooltip title="zoom in (CTRL + / wheel)">
                <IconButton onClick={zoomIn}>
                    <ZoomInIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="zoom out (CTRL - / wheel)">
                <IconButton onClick={zoomOut}>
                    <ZoomOutIcon />
                </IconButton>
            </Tooltip>

            <Divider
                orientation="vertical"
                sx={{ mx: 1 }}
            />

            <ChoiceButton
                choices={options}
                style={{ marginRight: 10, marginLeft: 10 }}
            />

            <Tooltip title="open local file">
                <IconButton onClick={openLocalFile}>
                    <DriveFolderUploadIcon />
                </IconButton>
            </Tooltip>

            <Divider
                orientation="vertical"
                sx={{ mx: 1 }}
            />

            <Tooltip title="Generate drychambers">
                <IconButton onClick={() => setGenerateDrychamberDialogOpen(true)}>
                    <AccountTreeIcon />
                </IconButton>
            </Tooltip>

            <Typography
                sx={{ ml: '100px', alignSelf: 'center' }}
                variant="h5"
            >
                {selectedFile.name} {sourceName}
            </Typography>

            {generateDrychamberDialogOpen && <GenerateDryChambers onClose={() => setGenerateDrychamberDialogOpen(false)} />}
        </div>
    );
});

const GenerateDryChambers = withCanvasContext<{ onClose(): void }>(({ canvas, onClose }) => {
    const [x, setX] = useState<number | null>(4);
    const [y, setY] = useState<number | null>(10);

    const [width, setWidth] = useState<number | null>(100);
    const [height, setHeight] = useState<number | null>(50);
    const [padding, setPadding] = useState<number | null>(20);

    const [orderX, setOrderX] = useState<string>([...Array(x).keys()].map((x) => ++x).join(', '));
    const [orderY, setOrderY] = useState<string>([...Array(y).keys()].map((x) => ++x).join(', '));

    const [generateLabelsTop, setGenerateLabelsTop] = useState(true);
    const [generateLabelsBottom, setGenerateLabelsBottom] = useState(false);
    const [generateLabelsLeft, setGenerateLabelsLeft] = useState(true);
    const [generateLabelsRight, setGenerateLabelsRight] = useState(false);

    const [pattern, setPattern] = useState('LG {X}|{Y}');

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        setOrderX([...Array(x).keys()].map((x) => ++x).join(', '));
    }, [x]);

    useEffect(() => {
        setOrderY([...Array(y).keys()].map((x) => ++x).join(', '));
    }, [y]);

    const generate = useCallback(() => {
        if (x === null || y === null || width === null || height === null || padding === null) return;

        if (x > 0 && y > 0) {
            canvas.discardActiveObject();

            const shelfOrderX = orderX.split(', ');
            const shelfOrderY = orderY.split(', ');

            const spaceLeft = 40;
            const spaceTop = 20;

            const elementsToAdd: fabric.Object[] = [];
            for (let i_x = 1; i_x <= x; i_x++) {
                for (let i_y = 1; i_y <= y; i_y++) {
                    const left = (i_x - 1) * (width + padding) + padding + spaceLeft;
                    const top = (i_y - 1) * (height + padding) + padding + spaceTop;

                    const control = new DryChamberLocation({
                        left: left,
                        top: top,
                        width: width,
                        height: height,
                        locationName: pattern
                            .replace('{X}', shelfOrderX[i_x - 1].toString())
                            .replace('{Y}', shelfOrderY[y - i_y].toString()),
                    });

                    elementsToAdd.push(control);
                    canvas.add(control);
                }
            }

            for (let i_x = 1; i_x <= x; i_x++) {
                const leftText = (i_x - 1) * (width + padding) + padding + width / 2 + spaceLeft;

                if (generateLabelsTop) {
                    const text = new Text({
                        text: 'Regal ' + shelfOrderX[i_x - 1],
                        translationKey: 'shelf#' + shelfOrderX[i_x - 1],
                        left: leftText,
                        top: spaceTop,
                    });

                    elementsToAdd.push(text);
                    canvas.add(text);
                }

                if (generateLabelsBottom) {
                    const top = y * (height + padding) + padding + spaceTop;

                    const text = new Text({
                        text: 'Regal ' + shelfOrderX[i_x - 1],
                        translationKey: 'shelf#' + shelfOrderX[i_x - 1],
                        left: leftText,
                        top: top,
                    });

                    elementsToAdd.push(text);
                    canvas.add(text);
                }
            }

            for (let i_y = 1; i_y <= y; i_y++) {
                const topText = (i_y - 1) * (height + padding) + padding + height / 2 + spaceTop;

                if (generateLabelsLeft) {
                    const leftText = (padding + spaceLeft) / 2;

                    const text = new Text({
                        text: 'Etage ' + shelfOrderY[y - i_y],
                        translationKey: 'level#' + shelfOrderY[y - i_y],
                        left: leftText,
                        top: topText,
                    });

                    elementsToAdd.push(text);
                    canvas.add(text);
                }

                if (generateLabelsRight) {
                    const leftText =
                        (x - 1) * (width + padding) +
                        padding +
                        spaceLeft +
                        width +
                        (padding + spaceLeft) / 2; /* + width + (spaceLeft / 2) */

                    const text = new Text({
                        text: 'Etage ' + shelfOrderY[y - i_y],
                        translationKey: 'level#' + shelfOrderY[y - i_y],
                        left: leftText,
                        top: topText,
                    });

                    elementsToAdd.push(text);
                    canvas.add(text);
                }
            }

            const activeSelection = new fabric.ActiveSelection(elementsToAdd, { canvas: canvas });
            canvas.setActiveObject(activeSelection);
            onClose();
        } else {
            enqueueSnackbar('Invalid parameters', { variant: 'error' });
        }
    }, [
        canvas,
        enqueueSnackbar,
        generateLabelsBottom,
        generateLabelsLeft,
        generateLabelsRight,
        generateLabelsTop,
        height,
        onClose,
        orderX,
        orderY,
        padding,
        pattern,
        width,
        x,
        y,
    ]);

    const handleUpdate = useCallback(
        (
            e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
            updateFunction: (value: React.SetStateAction<number | null>) => void
        ) => {
            const value = parseFloat(e.target.value);
            updateFunction(!isNaN(value) ? value : null);
        },
        []
    );

    const handleBlur = useCallback(
        (
            e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
            updateFunction: (value: React.SetStateAction<number | null>) => void
        ) => {
            const value = parseFloat(e.target.value);
            if (isNaN(value)) {
                updateFunction(0);
            }
        },
        []
    );

    return (
        <Dialog open>
            <DialogTitle>Generate Drychamber controls</DialogTitle>
            <DialogContent>
                <Grid
                    container
                    spacing={1}
                >
                    <Grid
                        item
                        xs={6}
                    >
                        <TextField
                            value={width}
                            onBlur={(e) => handleBlur(e, setWidth)}
                            onChange={(e) => handleUpdate(e, setWidth)}
                            size="small"
                            margin="dense"
                            fullWidth
                            label="Control width"
                            type="number"
                        />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                    >
                        <TextField
                            value={height}
                            onBlur={(e) => handleBlur(e, setHeight)}
                            onChange={(e) => handleUpdate(e, setHeight)}
                            size="small"
                            margin="dense"
                            fullWidth
                            label="Control height"
                            type="number"
                        />
                    </Grid>

                    <Grid
                        item
                        xs={6}
                    >
                        <TextField
                            value={x}
                            onBlur={(e) => handleBlur(e, setX)}
                            onChange={(e) => handleUpdate(e, setX)}
                            size="small"
                            margin="dense"
                            fullWidth
                            label="X"
                            type="number"
                        />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                    >
                        <TextField
                            value={y}
                            onBlur={(e) => handleBlur(e, setY)}
                            onChange={(e) => handleUpdate(e, setY)}
                            size="small"
                            margin="dense"
                            fullWidth
                            label="Y"
                            type="number"
                        />
                    </Grid>

                    <Grid
                        item
                        xs={6}
                    >
                        <TextField
                            value={padding}
                            onBlur={(e) => handleBlur(e, setPadding)}
                            onChange={(e) => handleUpdate(e, setPadding)}
                            size="small"
                            margin="dense"
                            fullWidth
                            label="Padding"
                            type="number"
                        />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                    >
                        <TextField
                            value={pattern}
                            onChange={(e) => setPattern(e.target.value)}
                            size="small"
                            margin="dense"
                            fullWidth
                            label="Pattern"
                            type="text"
                        />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                    >
                        <TextField
                            value={orderX}
                            onChange={(e) => setOrderX(e.target.value)}
                            disabled={!generateLabelsLeft && !generateLabelsRight}
                            size="small"
                            margin="dense"
                            fullWidth
                            label="OrderX"
                            type="text"
                        />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                    >
                        <TextField
                            value={orderY}
                            onChange={(e) => setOrderY(e.target.value)}
                            disabled={!generateLabelsTop && !generateLabelsBottom}
                            size="small"
                            margin="dense"
                            fullWidth
                            label="OrderY"
                            type="text"
                        />
                    </Grid>

                    <Grid
                        item
                        xs={6}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={generateLabelsTop}
                                    onChange={(e) => setGenerateLabelsTop(e.target.checked)}
                                />
                            }
                            label="Generate labels top"
                        />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={generateLabelsLeft}
                                    onChange={(e) => setGenerateLabelsLeft(e.target.checked)}
                                />
                            }
                            label="Generate labels left"
                        />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={generateLabelsBottom}
                                    onChange={(e) => setGenerateLabelsBottom(e.target.checked)}
                                />
                            }
                            label="Generate labels bottom"
                        />
                    </Grid>
                    <Grid
                        item
                        xs={6}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={generateLabelsRight}
                                    onChange={(e) => setGenerateLabelsRight(e.target.checked)}
                                />
                            }
                            label="Generate labels right"
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                    onClick={generate}
                    variant="contained"
                >
                    Generate
                </Button>
            </DialogActions>
        </Dialog>
    );
});
