export enum DistanceUnit {
    //package
    MILLIMETRES = 'mm',
    CENTIMETRES = 'cm',
    METRES = 'm',
    KILOMETERS = 'km',
    INCHES = 'in',
    FEET_US = 'ft-us',
    FEET = 'ft',
    MILES = 'mi',
    //custom
    FEET_AND_INCHES = 'ft+in',
}

export enum MassUnit {
    //package
    MICROGRAMS = 'mcg',
    MILLIGRAMS = 'mg',
    GRAMS = 'g',
    KILOGRAMS = 'kg',
    OUNCES = 'oz',
    POUNDS = 'lb',
    TONNES = 'mt', //spelling difference is intentional here (!)
    AMERICAN_TONS = 't',
    //custom
}

export enum AreaUnit {
    //package
    SQUARE_MILIMETRES = 'mm2',
    SQUARE_CENTIMETRES = 'cm2',
    SQUARE_METRES = 'm2',
    HECTARES = 'ha',
    SQUARE_KILOMETRES = 'km2',
    SQUARE_INCHES = 'in2',
    SQUARE_FEET = 'ft2',
    ACRE = 'ac',
    SQAURE_MILES = 'mi2',
    //custom
}

export enum VolumeUnit {
    //package
    CUBIC_MILIMETRES = 'mm3',
    CUBIC_CENTIMETRES = 'cm3',
    CUBIC_METRES = 'm3',
    CUBIC_KILOMETRES = 'km3',
    CUBIC_INCHES = 'in3',
    CUBIC_FEET = 'ft3',
    //custom
}

export enum DurationUnit {
    //package
    MILLISECONDS = 'ms',
    SECONDS = 's',
    MINUTES = 'min',
    HOURS = 'h',
    //custom
}

export enum AngleUnit {
    //package
    DEGREES = 'deg',
    RADIANTS = 'rad',
    //custom
}

// type union for functions that accept all types supported by this hook
export type SupportedUnit = DistanceUnit | MassUnit | AreaUnit | VolumeUnit | DurationUnit | AngleUnit;
export type UnitType = 'distance' | 'mass' | 'area' | 'volume' | 'duration' | 'angle' | 'none';
