import { createTypedAsyncThunk } from '@/helpers/helpers';
import axios from 'axios';
import { api } from '../../../definitions';
import { Deployment } from '../../../definitions/autogenerated/types';

export const fetchProgressApps = createTypedAsyncThunk<Deployment[], void>(
    'progressApps/fetchProgressApps',
    async (params, thunkAPI) => {
        const response = await axios.get<Deployment[]>(api.progressApps);
        const apps = response.data;
        return apps;
    }
);
