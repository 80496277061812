import React from 'react';
import { parseISO, getTime } from 'date-fns';

export type UptimeIndicatorProps = {
    remoteStartTS: string;
    remoteNowTS: string;
    lastFetchTS: number;
};

const UptimeIndicator = (props: UptimeIndicatorProps) => {
    const [now, setNow] = React.useState(getTime(new Date()));
    const serverNow = React.useMemo(() => getTime(parseISO(props.remoteNowTS)), [props.remoteNowTS]);
    const serverStart = React.useMemo(() => getTime(parseISO(props.remoteStartTS)), [props.remoteStartTS]);

    React.useEffect(() => {
        const handle = setInterval(() => {
            setNow(getTime(new Date()));
        }, 1000);
        return () => clearTimeout(handle);
    }, []);

    const formatTS = (ms: number) => {
        const pad = (number: number) => {
            let result = '' + number;
            if (result.length < 2) {
                result = '0' + result;
            }
            return result;
        };

        const days = Math.floor(ms / (24 * 60 * 60 * 1000));
        const daysms = ms % (24 * 60 * 60 * 1000);
        const hours = Math.floor(daysms / (60 * 60 * 1000));
        const hoursms = ms % (60 * 60 * 1000);
        const minutes = Math.floor(hoursms / (60 * 1000));
        const minutesms = ms % (60 * 1000);
        const sec = Math.floor(minutesms / 1000);
        return days + 'd ' + hours + 'h ' + pad(minutes) + 'm ' + pad(sec) + 's';
    };

    const uptime = serverNow - serverStart + now - props.lastFetchTS;

    return <>{formatTS(uptime)}</>;
};

export default UptimeIndicator;
