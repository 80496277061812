import { ProgressRouter } from '../frame/ProgressRouter';
import ProgressBaseProviderLight, { ProgressBaseProviderProps } from './ProgressBaseProviderLight';
import { VersionChecker } from './VersionChecker';

function ProgressBaseProvider(props: ProgressBaseProviderProps) {
    return (
        <ProgressBaseProviderLight {...props}>
            <ProgressRouter>
                <VersionChecker />
                {props.children}
            </ProgressRouter>
        </ProgressBaseProviderLight>
    );
}

export default ProgressBaseProvider;
